import { gql } from '@apollo/client';

export default gql`
  query Languages(
    $filter: FilterFindManyLanguageInput
    $sort: SortFindManyLanguageInput
  ) {
    languages(filter: $filter, sort: $sort) {
      _id
      name
      code
      nativeName
      flag
    }
  }
`;
