import Rectangle15 from 'images/houses/Rectangle15.png';
import Rectangle16 from 'images/houses/Rectangle16.png';
import Rectangle18 from 'images/houses/Rectangle18.png';
import Rectangle19 from 'images/houses/Rectangle19.png';
import Rectangle54 from 'images/houses/Rectangle54.png';
import {
  TagIcon,
  EyeIcon,
  KeyIcon,
  RocketIcon,
  ShieldLockIcon,
  HomeIcon,
} from '@primer/octicons-react';

export const imgCasas = [
  {
    id: 1,
    src: Rectangle15,
  },
  {
    id: 2,
    src: Rectangle16,
  },
  {
    id: 3,
    src: Rectangle18,
  },
  {
    id: 4,
    src: Rectangle19,
  },
  {
    id: 5,
    src: Rectangle54,
  },
  {
    id: 6,
    src: Rectangle15,
  },
  {
    id: 7,
    src: Rectangle16,
  },
  {
    id: 8,
    src: Rectangle18,
  },
  {
    id: 9,
    src: Rectangle19,
  },
  {
    id: 10,
    src: Rectangle54,
  },
];

export const agentHowItWorks = [
  {
    logo: <TagIcon size={50} />,
    title: 'Precio Objetivo',
    description:
      'Miles de datos al servicio para fijar el precio acorde al mercado. Sin especulación',
  },
  {
    logo: <EyeIcon size={50} />,
    title: 'Transparencia',
    description:
      'Te diremos la probabilidad de éxito de la oferta que harás por la propiedad.',
  },
  {
    logo: <KeyIcon size={50} />,
    title: 'Seguridad',
    description:
      'Seremos garantes del cumplimiento de tu reserva y el pago de arras.',
  },
  {
    logo: <RocketIcon size={50} />,
    title: 'El más alto nivel de servicio',
    description:
      'No solo eso, sabrás siempre el costo y el tiempo esperado de compra',
  },
  {
    logo: <ShieldLockIcon size={50} />,
    title: 'Confidencial',
    description:
      'Tú controlas tus datos. No recibirás futuras e inoportunas llamadas.',
  },
  {
    logo: <HomeIcon size={50} />,
    title: 'Verificado',
    description:
      'Todas las propiedades tienen fichas certificadas de sus características.',
  },
];

// export const imgReseña = [
//   {
//     id: 1,
//     src: Comentario,
//     alt: 'Profesional trabajando con el ordenador',
//   },
// ];

export const agentSteps = [
  {
    id: 1,
    title: 'Sobre ti:',
    description:
      'Queremos conocerte para que nuestro modelo entienda tus necesidades',
  },
  {
    id: 2,
    title: 'Propiedades:',
    description: 'Te mostramos las propiedades que se ajustan a ti.',
  },
  {
    id: 3,
    title: 'Agenda una cita:',
    description: 'En línea o presencial, podrás visitar la propiedad 24/7.',
  },
  {
    id: 4,
    title: 'Haz una oferta:',
    description:
      'Te asesoramos para que sepas la posibilidad de éxito de tu propuesta.',
  },
  {
    id: 5,
    title: 'Documentos y arras digitales:',
    description:
      'Directamente en la plataforma podrás firmar y pagar la reserva.',
  },
  {
    id: 6,
    title: 'Notaría:',
    description:
      'Preparamos y enviamos toda la documentación, para que sólo tengas que firmar.',
  },
];

// export const pasos = [
//   {
//     title: 'Registro en linea',
//     subtitle:
//       'Te pediremos sólo los datos escenciales para saber un poco sobre ti y la disponibilidad que tienes para trabajar. Es un proceso en línea que no tarda más de 5 minutos, pero puedes tomarte tu tiempo para leer en detalle antes de completarlo.',
//     url: 'agent',
//     urlTitle: 'Regístrate ',
//   },
//   {
//     title: 'Requisitos para trabajar',
//     subtitle:
//       'La mayoría de las personas cumplen las exigencias para trabajar con nosotros: mayor de edad, residente en el país donde prestarás los servicios, etc. Pero el requerimiento más importante es el deseo de hacer un buen trabajo.',
//     url: 'guideline',
//     urlTitle: 'Requisitos ',
//   },
//   {
//     title: 'Honorarios por ventas',
//     subtitle:
//       'Nexun ofrece a todas las personas la oportunidad de generar ingresos sea captando, visitando o vendiendo propiedades. Puedes disponer de tu tiempo al completo o de forma parcial, y que esta se transforme en tu fuente de ingresos principal o complementaria.',
//     url: 'fee',
//     urlTitle: 'Saber más ',
//   },
// ];
