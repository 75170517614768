import { Collapse } from '@mui/material';
import { ChevronDownIcon, ChevronUpIcon } from '@primer/octicons-react';
import { HomeFooter, HomeHeader, ShapeDivider } from 'components';
import { useMemo, useState } from 'react';
import { faqItems } from 'utils/home-data';

export default function FaqRoute() {
  return (
    <div className="d-flex flex-column flex-1 color-bg-subtle">
      <div className="container-lg p-responsive width-full">
        <HomeHeader />
      </div>
      <ShapeDivider placement="bottom" />
      <div className="color-bg-default">
        <div className="container-lg p-responsive width-full">
          <div className="blankslate blankslate-large">
            <h2 className="h2 lh-condensed color-fg-subtle">FAQ</h2>
            <h3 className="blankslate-heading h1 mb-0">Preguntas frecuentes</h3>
            <p className="f4 mt-4 color-fg-muted">
              ¿Tienes dudas? Consulta estas respuestas
            </p>
          </div>
        </div>
      </div>
      <ShapeDivider placement="top" />
      <div className="container-lg p-responsive width-full">
        <div className="py-6">
          {faqItems.map((category, index) => (
            <FaqCategory key={category.id} category={category} index={index} />
          ))}
        </div>
      </div>
      <HomeFooter />
    </div>
  );
}

const FaqCategory = ({ category, index }) => {
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  return (
    <div key={category.id}>
      <div className={index !== 0 ? 'Subhead mt-6' : 'Subhead'}>
        <h3 className="Subhead-heading pb-3">{category.title}</h3>
      </div>
      {category.items.map((item) => (
        <FaqQuestion
          key={item.id}
          item={item}
          expandedId={expandedQuestion}
          onExpand={setExpandedQuestion}
        />
      ))}
    </div>
  );
};

const FaqQuestion = ({ item, expandedId, onExpand }) => {
  const expanded = useMemo(() => expandedId === item.id, [expandedId, item.id]);

  return (
    <div
      role="button"
      className="color-bg-default color-shadow-medium rounded-2 my-3 py-3 px-4 cursor-pointer user-select-none"
      onClick={() => onExpand((prev) => (prev === item.id ? null : item.id))}
    >
      <div className="d-flex flex-items-start flex-justify-between">
        <p className="f4 text-bold flex-1 mb-0">{item.question}</p>
        <button type="button" className="btn-octicon">
          {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </button>
      </div>
      <Collapse in={expanded}>
        <p className="f4 color-fg-muted mt-2">{item.answer}</p>
      </Collapse>
    </div>
  );
};
