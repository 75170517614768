import { HomeFooter, HomeHeader, ShapeDivider, NexunLogo } from 'components';
import { useParams } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
import Gallery from 'components/Gallery';
import { PROMOTION_QUERY } from 'apollo/queries';
import { useQuery, useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import ModalNexun from 'components/ModalNexun';
import LanguageSelector from 'components/LanguageSelector';
import { CREATE_PROPERTY_VISIT_MUTATION } from 'apollo/mutations';
import { useDescriptionText } from 'hooks';
import Flatpickr from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import { FileIcon, EyeClosedIcon, EyeIcon } from '@primer/octicons-react';

export default function PromotionViewMainRoute() {
  const params = useParams();
  const promotionId = params.promotionId;
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [view, setView] = useState(false);
  const [photoButton, setStatePhoto] = useState(false);
  const [modal, setModal] = useState(false);
  const [map, setMap] = useState(false);
  const [firstName, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [language, setLanguage] = useState(undefined);
  const [scheduledAt, setScheduleAt] = useState();
  const [propertyId, setPropertyId] = useState();
  const { addToast } = useToasts();

  useEffect(() => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setIsValidEmail(emailRegex.test(email));
  }, [email]);

  function CheckDocument(archives, option) {
    const selected = archives.find((archive) => archives.typology === option);
    if (!selected) {
      return null;
    }
    return selected.url;
  }

  function RetrieveNameOfDocument(option) {
    if (option === 'saleFlyer') return 'Dossier Venta';
    if (option === 'energyCertificate') return 'Certificado Energético';
    if (option === 'qualityDocumentation') return 'Documentación Técnica';
  }

  // Number Currency Format
  const numberFormat = (value) =>
    new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
    }).format(value);

  const { data, fetching } = useQuery(PROMOTION_QUERY, {
    variables: {
      filter: { _id: promotionId },
    },
  });

  const handleModal = () => {
    setModal(!modal);
  };

  const handleMap = () => {
    setMap(!map);
  };

  const handleShowPhoto = () => {
    setStatePhoto(!photoButton);
  };

  const TakeDescription = (description, language) => {
    const descriptionText = useDescriptionText(description);
    return descriptionText;
  };

  const TakeFloorString = (floor) => {
    if (floor !== Number) {
      if (floor === 'bj') {
        return 'Planta Baja';
      }
      if (floor === 'en') {
        return 'Entreplanta';
      }
      if (floor === 'ss') {
        return 'Semi Sótano';
      }
      if (floor === 'st') {
        return 'Sótano';
      }
    }
    return floor;
  };

  const onVisitCreated = useCallback(
    (data) => {
      addToast(
        'Hemos enviado un correo a tu dirección a con los detalles de la cita',
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
      setName('');
      setEmail('');
      setPhone('');
      setLanguage(undefined);
      setLastName('');
      setPassword('');
      setScheduleAt();
    },
    [addToast],
  );

  const onError = useCallback(
    (error) => {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: false,
      });
    },
    [addToast],
  );

  const [createVisit, { loading }] = useMutation(
    CREATE_PROPERTY_VISIT_MUTATION,
    { onCompleted: onVisitCreated, onError },
  );

  function handleFormSubmit(event) {
    event.preventDefault();
    if (!isValidEmail) {
      addToast('El email no es válido', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    if (language === undefined) {
      addToast('El idioma no se ha seleccionado.', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    if (scheduledAt === undefined) {
      addToast('La fecha no se ha seleccionado.', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    createVisit({
      variables: {
        record: {
          property: propertyId,
          date: scheduledAt,
          isConfirmedByBuyer: true,
        },
        buyerRecord: {
          firstName,
          lastName,
          email,
          phone,
          password,
          language: language._id,
          isEmailVerificated: true,
        },
      },
    });
  }

  if (fetching === 'loading')
    return (
      <div className="d-flex flex-column flex-1 color-bg-subtle">
        <div className="container-lg p-responsive width-full">
          <HomeHeader />
        </div>
        <div className="container-lg p-responsive width-full">
          <div className="container-lg">
            <div className="Layout-main col-12">
              <div className="Subhead">
                <h2 className="Subhead-heading"> Buscando...</h2>
              </div>
              <div className="flex items-center justify-center h-screen">
                <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12 mb-4 text-center">
                  <NexunLogo
                    className="d-flex flex-justify-center anim-rotate"
                    onlyIcon={true}
                  />
                  <label className="text-center">Cargando...</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  if (data) {
    return (
      <div className="d-flex flex-column flex-1 color-bg-subtle">
        <div className="container-lg p-responsive width-full">
          <HomeHeader />
        </div>
        <ShapeDivider placement="bottom" />
        <div className="width-full color-bg-default anim-fade-in">
          <div className="container-lg">
            <div className="p-responsive pb-6 color-bg-default ">
              {/* TITLE OF PROPERTIES */}
              <div className="d-flex flex-row pl-1">
                <div className="Subhead col-12">
                  <div className="col-lg-7 col-12 ">
                    <h1 className="Subhead-heading position-relative">
                      {data.promotion.name}
                      <span className="ml-2 color-bg-emphasis color-fg-on-emphasis h4 p-2 rounded-2  ">
                        Promoción
                      </span>
                    </h1>
                    <p className="Subhead-description">
                      {data.promotion.place.formattedAddress}
                    </p>
                  </div>
                  <div className="col-lg-5 col-12 d-flex flex-items-end flex-justify-around flex-lg-justify-end mb-2">
                    <button className="btn mr-3" onClick={handleShowPhoto}>
                      Fotos
                    </button>
                    <button className="btn" onClick={handleMap}>
                      Mapa
                    </button>
                  </div>
                </div>
              </div>
              {/* IMAGES OF PROMOTION */}
              <div className="d-lg-flex flex-row d-inline-block">
                <Gallery
                  galleryImages={data.promotion.images}
                  name={data.promotion.name}
                  address={data.promotion.place.formattedAddress}
                  photoButton={photoButton}
                />
                {modal && (
                  <ModalNexun
                    array={data.promotion.images}
                    index={0}
                    name={data.promotion.title}
                    address={data.promotion.place.formattedAddress}
                    setModal={setModal}
                  />
                )}
              </div>
              {/* PRESENTATION PROPERTY */}
              <div className="d-flex flex-row mt-3 border-top py-4">
                <p className="format px-2">
                  {TakeDescription(data.promotion.descriptions)}
                </p>
              </div>

              {/* ARCHIVOS DE LA PROPIEDAD */}
              <div className="mt-3 border-top py-4">
                <h3 className="text-start f1-mktg text-light pl-2 pb-3 text-center">
                  Descargables
                </h3>
                <div className="d-flex flex-row mt-3 flex-justify-around">
                  {data.promotion.archiveIntern.map((archive) => (
                    <div className="text-center px-2">
                      <FileIcon size={20} className="mr-2" />
                      <a
                        href={CheckDocument(
                          data.promotion.archiveIntern,
                          archive.typology,
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {RetrieveNameOfDocument(archive.typology)}
                      </a>
                    </div>
                  ))}
                </div>
              </div>

              {/* TABLA DE PROPIEDADES */}
              <div className="mt-3 border-top py-4">
                <h3 className="text-start f1-mktg text-light pl-2 pb-3 text-center">
                  Propiedades Disponibles
                </h3>
                <div className="d-flex flex-justify-center">
                  <table className="table text-center p-responsive">
                    <thead>
                      <tr className="color-fg-on-emphasis color-bg-emphasis">
                        <th
                          className="border px-1 px-md-3 px-lg-4 py-2"
                          scope="col"
                        >
                          Vivienda
                        </th>
                        <th
                          className="border px-1 px-md-3 px-lg-4 py-2"
                          scope="col"
                        >
                          Planta
                        </th>
                        <th
                          className="border px-1 px-md-3 px-lg-4 py-2"
                          scope="col"
                        >
                          Dormitorios
                        </th>
                        <th
                          className="border px-1 px-md-3 px-lg-4 py-2"
                          scope="col"
                        >
                          Baños
                        </th>
                        <th
                          className="border px-1 px-md-3 px-lg-4 py-2"
                          scope="col"
                        >
                          Superficie
                        </th>
                        <th
                          className="border px-1 px-md-3 px-lg-4 py-2"
                          scope="col"
                        >
                          Precio
                        </th>
                        <th
                          className="border px-1 px-md-3 px-lg-4 py-2"
                          scope="col"
                        >
                          Visualizar
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.promotion.properties.map((property) => (
                        <tr key={property._id}>
                          <th
                            className="border px-1 px-md-3 px-lg-4 py-2"
                            scope="row"
                          >
                            {property.title}
                          </th>
                          <td className="border px-1 px-md-3 px-lg-4 py-2">
                            {TakeFloorString(property.address.floor)}
                          </td>
                          <td className="border px-1 px-md-3 px-lg-4 py-2">
                            {property.features.bedroomNumber}
                          </td>
                          <td className="border px-1 px-md-3 px-lg-4 py-2">
                            {property.features.bathroomNumber}
                          </td>
                          <td className="border px-1 px-md-3 px-lg-4 py-2">
                            {property.features.areaUsable} m2
                          </td>
                          <td className="border px-1 px-md-3 px-lg-4 py-2">
                            {numberFormat(property.operation.price)}
                          </td>
                          <td className="border px-1 px-md-3 px-lg-4 py-2">
                            <a href={`/properties/${property._id}`}>Ver</a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ShapeDivider placement="top" />
        <div className="container py-4">
          <div className="d-flex flex-row flex-justify-center">
            <div className="col-12 col-lg-7">
              <div className="d-flex flex-column">
                <h3 className="text-start h3-mktg text-light pl-2 text-center pb-2">
                  ¿ Quieres venir a visitarla ?
                </h3>
                <h3 className="blankslate-heading color-fg-muted text-center">
                  Rellena el formulario y nos pondremos en contacto contigo lo
                  antes posible para concertar una visita.
                </h3>
                <div className="Box Box--spacious col-10 mx-auto mt-4 pl-5 pr-5 pb-3">
                  <form onSubmit={handleFormSubmit}>
                    <div className="Box-body border-0">
                      <div className="form-group mb-4">
                        <div className="form-group-body">
                          <div className="form-group">
                            <div className="form-group-body">
                              <input
                                className="form-control width-full"
                                type="text"
                                name="firstName"
                                value={firstName}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Nombre"
                                required
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="form-group-body">
                              <input
                                className="form-control width-full"
                                type="text"
                                name="lastName"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Apellido"
                                required
                              />
                            </div>
                          </div>
                          <div className="form-group text-start">
                            <div className="form-group-body">
                              <input
                                className="form-control width-full"
                                type="number"
                                value={phone}
                                name="phone"
                                placeholder="Teléfono de contacto"
                                required
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form-group text-start">
                            <div className="form-group-body">
                              <input
                                className="form-control width-full"
                                type="email"
                                value={email}
                                name="email"
                                placeholder="Correo de contacto"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                          <div className="form-group text-start">
                            <div className="form-group-body input-group">
                              <input
                                name="password"
                                className="form-control input-block"
                                type={view ? 'text' : 'password'}
                                placeholder="Contraseña"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                required
                              />
                              <span className="input-group-button">
                                <button
                                  className="btn"
                                  type="button"
                                  aria-label="Copy to clipboard"
                                  onClick={() => setView(!view)}
                                >
                                  {view ? (
                                    <EyeIcon></EyeIcon>
                                  ) : (
                                    <EyeClosedIcon></EyeClosedIcon>
                                  )}
                                </button>
                              </span>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="form-group-body">
                              <select
                                className="form-control width-full"
                                name="propertyId"
                                value={propertyId}
                                onChange={(e) => setPropertyId(e.target.value)}
                                required
                              >
                                <option value="">
                                  Selecciona una propiedad
                                </option>
                                {data.promotion.properties.map((property) => (
                                  <option
                                    key={property._id}
                                    value={property._id}
                                  >
                                    {property.title}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="form-group text-start">
                            <div className="form-group-body">
                              <Flatpickr
                                data-enable-time
                                options={{
                                  dateFormat: 'F j,Y H:i',
                                  minDate: 'today',
                                  enableTime: true,
                                  time_24hr: true,
                                  locale: Spanish,
                                  hourIncrement: 1,
                                  minuteIncrement: 15,
                                  defaultMinute: 0,
                                  altFormat: 'F j,Y H:i',
                                }}
                                placeholder="Fecha y hora de la visita"
                                name="scheduledAt"
                                className="form-control width-full"
                                value={scheduledAt}
                                onChange={(date) =>
                                  setScheduleAt(new Date(date))
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group text-start">
                            <div className="form-group-body">
                              <LanguageSelector
                                value={language}
                                onSelect={setLanguage}
                                disabled={loading}
                                className="form-control width-full"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                      >
                        {loading
                          ? 'Enviando petición...'
                          : '¡Quiero visitarla!'}
                      </button>
                      <p className="note pt-2">
                        Al enviar el formulario, aceptas la cración de una
                        cuenta de usuario en nuestra plataforma, con la que
                        podrás acceder a tu perfil y gestionar tus visitas.
                        Nexun te informa que los datos de carácter personal que
                        nos proporciones rellenando el presente formulario serán
                        tratados por NEXUN PLATAFORMA INMOBILIARIA SL. Puedes
                        consultar la información adicional y detallada sobre la
                        Protección de Datos en nuestra{' '}
                        <a
                          href="/policy"
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        >
                          política de privacidad
                        </a>
                        .
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HomeFooter />
      </div>
    );
  }
}
