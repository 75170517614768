import BuyerHeader from 'components/BuyerHeader';
import { NavLink } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { HomeIcon, TasklistIcon } from '@primer/octicons-react';
import { PROPERTIES_QUERY } from 'apollo/queries';
import { useAuth } from 'context/auth.context';
import { VISITS_COUNT_FILTER_QUERY } from 'apollo/queries';
import { useQuery } from '@apollo/client';

export default function BuyerLayout() {
  const { actor } = useAuth();
  const { data } = useQuery(PROPERTIES_QUERY);
  const [myVisits, setMyVisits] = useState(0);
  const count = data?.properties?.length || 0;
  const counter = useQuery(VISITS_COUNT_FILTER_QUERY, {
    variables: {
      filter: {
        buyer: actor._id,
      },
    },
  });
  useEffect(() => {
    setMyVisits(counter?.data?.propertyVisitsCount);
  }, [counter]);

  return (
    <div className="d-flex flex-column flex-1 color-bg-inset">
      <BuyerHeader />
      <main className="d-flex flex-column flex-1">
        <div className="color-bg-default border-bottom">
          <div className="container-lg p-responsive width-full">
            <nav className="UnderlineNav" style={{ marginBottom: -1 }}>
              <div className="UnderlineNav-body flex-1 flex-justify-center py-md-3">
                <NavLink to="/" className="UnderlineNav-item" end>
                  <HomeIcon className="mr-2" /> Home{' '}
                </NavLink>
                {/* <NavLink to="/properties" className="UnderlineNav-item">
                  <OrganizationIcon className="mr-2" /> Propiedades{' '}
                  <span className="Counter">{count}</span>
                </NavLink> */}
                <NavLink to="/visits" className="UnderlineNav-item">
                  <TasklistIcon className="mr-2" /> Visitas{' '}
                  <span className="Counter">{myVisits}</span>
                </NavLink>
              </div>
            </nav>
          </div>
        </div>
        <div className="container-lg p-responsive width-full d-flex flex-column flex-1 py-4 color-bg-subtle">
          <div className="d-flex flex-column flex-1 flex-self-top color-bg-subtle">
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  );
}
