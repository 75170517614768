import { BellIcon, PersonIcon, PlusIcon } from '@primer/octicons-react';
import NexunLogo from 'components/NexunLogo';
import { useAuth } from 'context/auth.context';
import { NavLink } from 'react-router-dom';

export default function AgentHeader() {
  const auth = useAuth();

  return (
    <header className="Header border-bottom">
      <div className="container-lg p-responsive width-full">
        <div className="d-flex flex-justify-between flex-items-center">
          <div className="Header-item">
            <NavLink to="/" className="Header-link text-normal no-underline">
              <NexunLogo>
                <p className="note color-fg-subtle m-0">
                  Plataforma para agentes
                </p>
              </NexunLogo>
            </NavLink>
          </div>
          <div className="Header-item Header-item--full" />
          <div className="Header-item mr-3 mr-md-3 flex-order-1">
            <NavLink
              to="/notifications"
              className="Header-link btn-link tooltipped tooltipped-s"
              aria-label="Notificaciones"
            >
              <BellIcon />
            </NavLink>
          </div>
          <div className="Header-item mr-3 mr-md-3 flex-order-1">
            <NavLink
              to="/invite"
              className="Header-link btn-link tooltipped tooltipped-s"
              aria-label="Invitar a nexun"
            >
              <PlusIcon />
            </NavLink>
          </div>
          <div className="Header-item position-relative mr-0 mr-md-3 flex-order-2">
            <details className="details-reset details-overlay">
              <summary className="Header-link">
                <PersonIcon />
                <span className="dropdown-caret ml-1" />
              </summary>
              <div className="dropdown-menu dropdown-menu-sw" role="menu">
                {/* <NavLink
                  to="/settings"
                  className="dropdown-item"
                  role="menuitem"
                >
                  Mi perfil
                </NavLink> */}
                {/* <NavLink
                  to="/settings/available"
                  className="dropdown-item"
                  role="menuitem"
                >
                  Mi disponibilidad
                </NavLink> */}
                <NavLink
                  to="/settings"
                  className="dropdown-item"
                  role="menuitem"
                >
                  Configuración
                </NavLink>
                <div role="none" className="dropdown-divider" />
                <button
                  type="button"
                  className="btn-link dropdown-item"
                  role="menuitem"
                  onClick={auth.signout}
                >
                  Cerrar sesión
                </button>
              </div>
            </details>
          </div>
        </div>
      </div>
    </header>
  );
}
