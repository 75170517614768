import { useParams } from 'react-router-dom';
import { MapPlatform, UserAvatar, PropertyCard, Loading } from 'components';
import { useEffect, useState, useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';
import BoostrapModal from 'components/BoostrapModal';
import {
  CheckIcon,
  AlertIcon,
  CalendarIcon,
  PersonIcon,
  FileSubmoduleIcon,
  LocationIcon,
} from '@primer/octicons-react';

import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { useMutation, useQuery } from '@apollo/client';
import { VISIT_QUERY } from 'apollo/queries';
import { UPDATE_PROPERTY_VISIT_MUTATION } from 'apollo/mutations';
import NotAccessRoute from 'routes/notAccess.route';
import NotFoundRoute from 'routes/notFound.route';
import { useAuth } from 'context/auth.context';

export default function VisitAgentView() {
  const params = useParams();
  const { actor } = useAuth();
  const [notAccess, setNotAccess] = useState(true);
  const [visit, setVisit] = useState({});
  const [property, setProperty] = useState({});
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [client, setClient] = useState({});
  const [date, setDate] = useState(new Date());
  const { addToast } = useToasts();

  // Retriving information of view
  const { data, loading } = useQuery(VISIT_QUERY, {
    variables: {
      filter: {
        _id: params.visitId,
      },
    },
    onCompleted: (data) => {
      setVisit(data.propertyVisit);
      setProperty(data.propertyVisit.property);
      setLat(Number(data.propertyVisit.property.place.geometry.location.lat));
      setLng(Number(data.propertyVisit.property.place.geometry.location.lng));
      setClient(data.propertyVisit.buyer);
      setDate(new Date(data.propertyVisit.date));

      // Control if the agent is the owner of the visit
      if (data.propertyVisit.agent._id === actor._id) {
        setNotAccess(false);
      } else {
        setNotAccess(true);
      }
    },
  });

  // MODAL STATES
  const [modalClient, setModalClient] = useState(false);
  const handleModalClient = () => {
    setModalClient(!modalClient);
  };

  // Retrieving information of client modal
  const cuerpoCliente = (
    <>
      <div className="text-center position-relative">
        <div className="d-flex flex-justify-center py-3">
          <UserAvatar
            url={client?.avatarURL}
            username={client?.firstName}
            size="ultra"
            hideOnlineIndicator
          />
        </div>
        <h2 className="f4">{client?.firstName + ' ' + client?.lastName}</h2>
        <h2 className="f5">{client?.email}</h2>
        <div className="">
          <div className="border-top mt-3 pt-1 text-left px-2">
            <label>Teléfono: </label> {client?.phone}
          </div>
          <div className="text-left px-2">
            <label>Dirección: </label>{' '}
            {client?.address ? client?.address.name : '-'}
          </div>
          <div className="text-left px-2">
            <label>Nacimiento: </label>{' '}
            {client?.birthDate
              ? new Date(client?.birthDate).toISOString().split('T')[0]
              : '-'}
          </div>
          <div className="text-left px-2">
            <label>NIF: </label> {client?.nif ? client?.nif : '-'}
          </div>
          <div className="text-left border-bottom pb-1 px-2">
            <label>Ciudad: </label>{' '}
            {client?.country
              ? client?.country.flag + ' ' + client?.country.name
              : '-'}
          </div>
        </div>
        <div>
          {visit?.isConfirmedByBuyer && (
            <div className="flash flash-full rounded-2  flash-success">
              <CheckIcon size={20} />
              Visita aceptada
            </div>
          )}
          {!visit?.isConfirmedByBuyer && (
            <div className="flash flash-full rounded-2 flash-warn">
              <AlertIcon size={20} />
              Visita pendiente de aceptar
            </div>
          )}
        </div>
      </div>
    </>
  );

  // Controlamos el estado de la visita para mostrar el botones o no.
  const [status, setStatus] = useState({
    isRefusedByAgent: visit?.isRefusedByAgent,
    isConfirmedByAgent: visit?.isConfirmedByAgent,
    isConfirmedByBuyer: visit?.isConfirmedByBuyer,
    isRefusedByBuyer: visit?.isRefusedByBuyer,
  });

  // Control changes on visit information
  useEffect(() => {
    setStatus({
      isRefusedByAgent: visit?.isRefusedByAgent,
      isConfirmedByAgent: visit?.isConfirmedByAgent,
      isConfirmedByBuyer: visit?.isConfirmedByBuyer,
      isRefusedByBuyer: visit?.isRefusedByBuyer,
    });
  }, [visit]);

  // Control status buttons of visit view
  const visitStatusButton = (visit) => {
    if (
      status?.isRefusedByAgent === true ||
      status?.isRefusedByBuyer === true
    ) {
      return <span className="color-fg-danger">Rechazada</span>;
    }

    if (
      status?.isConfirmedByAgent === true &&
      status?.isConfirmedByBuyer === false &&
      status?.isRefusedByBuyer !== true
    ) {
      return (
        <span className="color-fg-attention">
          <AlertIcon size={16} />
          Pendiente de aceptar por cliente
        </span>
      );
    }

    if (
      status?.isConfirmedByAgent === true &&
      status?.isConfirmedByBuyer === true
    ) {
      return (
        <span className="color-fg-open">
          <CheckIcon size={16} />
          Aceptada
        </span>
      );
    }

    if (
      status?.isConfirmedByAgent === false &&
      status?.isRefusedByAgent === false
    ) {
      return (
        <span className="color-fg-danger">
          <AlertIcon size={16} /> Pendiente de tu aprobación
        </span>
      );
    }
  };

  // when a visit request is error
  const onUpdateRequestVisitError = useCallback(
    (data) => {
      addToast('Ha ocurrido un error al guardar los cambios.', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
    [addToast],
  );

  // Submit visit update visit
  const [submitVisit, { fetching }] = useMutation(
    UPDATE_PROPERTY_VISIT_MUTATION,
    {
      refetchQueries: ['PropertyVisit'],
      onError: onUpdateRequestVisitError,
    },
  );

  // Handle when agent click a accept button
  const handleAcceptVisitByAgent = () => {
    submitVisit({
      variables: {
        id: visit._id,
        record: {
          isRefusedByAgent: false,
          isConfirmedByAgent: true,
          confirmedByBuyerAt: new Date(),
        },
      },
    }).then(() => {
      addToast(
        'Visita aceptada. Recibirá un correo con más información de la visita.',
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
    });
  };

  // Handle when agent refuse a visit request with button
  const handleRefuseVisitByAgent = () => {
    submitVisit({
      variables: {
        id: visit._id,
        record: {
          isRefusedByAgent: true,
          isConfirmedByAgent: false,
          refusedByAgentAt: new Date(),
        },
      },
    }).then(() => {
      addToast('Visita rechazada correctamente.', {
        appearance: 'success',
        autoDismiss: true,
      });
    });
  };

  // Giving information of visit to Google calendar
  const retrieveVisitInformation = () => {
    return (
      'NEXUN - Detalles de tu próxima visita [br] [br]' +
      'Estos son los detalles de tu próxima cita: [br] - LUGAR: ' +
      visit?.property?.place?.formattedAddress +
      ' [br] - FECHA Y HORA: ' +
      new Date(visit?.date)
        .toISOString('es-ES')
        .replace(/T/, ' ')
        .replace(/\..+/, '') +
      ' [br] - CLIENTE: ' +
      visit?.buyer?.firstName +
      ' ' +
      visit?.buyer?.lastName +
      ' [br] - TELÉFONO: ' +
      visit?.buyer?.phone +
      ' [br] - CORREO: ' +
      visit?.buyer?.email +
      ' [br] [br] Si tienes alguna duda, puedes obtener más datos en el correo que te hemos enviado. [br] [br] ¡Gracias por confiar en Nexun!'
    );
  };

  if (loading) {
    return <Loading />;
  }

  if (notAccess) {
    return <NotAccessRoute />;
  }

  if (data?.propertyVisit === null || data?.propertyVisit === undefined) {
    return <NotFoundRoute />;
  }

  return (
    <div className="">
      <div className="Box">
        <div className="Box-body">
          <div className="gutter-condensed d-lg-flex">
            <div className="col-12 col-lg-8">
              <div className="Subhead">
                <h2 className="Subhead-heading">Detalles de la visita</h2>
                <div className="Subhead-description">
                  Podrás ver todos los detalles de la visita aquí
                </div>
              </div>
              <div className="pb-2">
                <div className="pb-2">
                  <strong>Estado: </strong>
                  {visitStatusButton(visit)}
                </div>
                <div className="pb-2">
                  <strong>Fecha y hora:</strong>
                  <CalendarIcon size={16} className="ml-2" />{' '}
                  {visit?.date
                    ? `${date.toLocaleString('es-ES', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })} a las ${date.toLocaleString('es-ES', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false,
                      })}`
                    : 'No disponible'}
                </div>
                <div className="d-flex">
                  <strong>Cliente:</strong>
                  <div className="d-flex flex-justify-center">
                    <PersonIcon size={16} className="mt-1 ml-2" />{' '}
                    <p className="Link ml-1" onClick={handleModalClient}>
                      {visit?.buyer?.firstName ? visit.buyer.firstName : ''}{' '}
                      {visit?.buyer?.lastName ? visit.buyer.lastName : ''}
                    </p>
                  </div>
                </div>
                <div>
                  <strong>Lugar:</strong>{' '}
                  <LocationIcon size={16} className="ml-2" />{' '}
                  {visit?.property?.place?.formattedAddress
                    ? visit?.property?.place?.formattedAddress
                    : 'No disponible'}
                </div>
                <div className="py-2 pr-3">
                  <MapPlatform
                    location={{ lat, lng }}
                    disableInput
                    width="100%"
                    height="300px"
                  />
                </div>
              </div>
              {status.isConfirmedByAgent === false &&
                status.isRefusedByAgent !== true && (
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-justify-around">
                      <button
                        className="btn btn-primary mr-2 my-2"
                        onClick={() => handleAcceptVisitByAgent()}
                        disabled={fetching}
                      >
                        {fetching ? 'Actualizando...' : 'Aceptar cita'}
                      </button>
                      <button
                        className="btn color-bg-danger-emphasis color-fg-on-emphasis mr-2 my-2"
                        onClick={() => handleRefuseVisitByAgent()}
                        disabled={fetching}
                      >
                        {fetching ? 'Actualizando...' : 'Rechazar cita'}
                      </button>
                    </div>
                    <blockquote className="note">
                      Al aceptar la visita, te comprometes a asistir a la misma.
                      Si no puedes asistir, por favor, rechaza la visita. Si
                      rechazas la visita, el cliente podrá solicitar una nueva
                      cita.
                    </blockquote>
                  </div>
                )}
            </div>
            <div className="col-12 col-lg-4">
              <PropertyCard
                className="Box d-inline-block col-12 color-fg-muted no-underline"
                element={property ? property : null}
                individual
              />
              {status.isConfirmedByAgent === true && (
                <div className="d-flex flex-justify-center pt-2">
                  <AddToCalendarButton
                    label="Añadir a mi calendario"
                    hideCheckmark={true}
                    size="2"
                    startDate={new Date(visit.date).toISOString().split('T')[0]}
                    startTime={
                      new Date(new Date(visit.date).getTime() - 3600000)
                        .toTimeString()
                        .split(' ')[0]
                    }
                    endTime={
                      new Date(new Date(visit.date).getTime())
                        .toTimeString()
                        .split(' ')[0]
                    }
                    location={visit.property.place.formattedAddress}
                    timeZone="Europe/Madrid"
                    name="Visita Propiedad - NEXUN"
                    description={retrieveVisitInformation()}
                    options="Google"
                    lightMode="bodyScheme"
                    buttonStyle="date"
                  ></AddToCalendarButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-12 pt-3">
        {date < new Date() && (
          <div className="p-responsive col-12 py-3 color-bg-default rounded-2 border d-lg-flex">
            <div className="col-12 col-lg-8 border-bottom">
              <div className="Subhead">
                <h2 className="Subhead-heading">¿Has realizado la visita?</h2>
                <div className="Subhead-description">
                  Si has realizado la visita, puedes subir los documentos aquí
                </div>
              </div>
              <div className="py-2">
                <div className="d-flex flex-justify-between">
                  <div className="d-flex flex-justify-center">
                    <FileSubmoduleIcon size={16} className="mt-1 ml-2" />{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {modalClient && (
        <BoostrapModal
          setModal={setModalClient}
          title="Datos sobre el cliente"
          body={cuerpoCliente}
        />
      )}
    </div>
  );
}
