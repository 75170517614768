import { XIcon } from '@primer/octicons-react';
import { days, hours } from 'utils/schedule';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';

export default function ScheduleTimeZone({
  values,
  onSelect,
  onRemove,
  disabled,
}) {
  const [day, setDay] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const { addToast } = useToasts();
  const valideEnd = (starter, ended) => {
    if (starter >= ended) {
      return false;
    }
    return true;
  };

  const reset = () => {
    setDay('');
    setStart('');
    setEnd('');
  };

  const getNameOfDay = (code) => {
    const day = days.find((d) => d.code === code);
    return day.name;
  };

  const isRangeValid = (day, start, end) => {
    const daySelected = values.find(
      (d) =>
        d.dayOfWeek === Number(day) &&
        d.hourRange.start <= Number(start) &&
        d.hourRange.end >= Number(end),
    );

    const daySelected2 = values.find(
      (d) =>
        d.dayOfWeek === Number(day) &&
        d.hourRange.start >= Number(start) &&
        d.hourRange.end <= Number(end),
    );

    const daySelected3 = values.find(
      (d) => d.dayOfWeek === Number(day) && d.hourRange.end > Number(start),
    );

    if (daySelected || daySelected2 || daySelected3) {
      return false;
    }
    return true;
  };

  return (
    <div className="Box">
      <div className="Box-body">
        {values.length === 0 && 'No hay franjas horarias seleccionadas.'}
        {values.map((schedule) => (
          <div
            key={
              getNameOfDay(schedule.dayOfWeek) +
              schedule.hourRange.start +
              schedule.hourRange.end
            }
            className="Label d-inline-flex flex-items-center m-1"
          >
            <span className="px-2">
              {getNameOfDay(schedule.dayOfWeek)}: {schedule.hourRange.start}:00
              - {schedule.hourRange.end}:00
            </span>
            <button
              type="button"
              className="btn-octicon btn-octicon-danger"
              onClick={() => onRemove(schedule)}
              disabled={disabled}
            >
              <XIcon />
            </button>
          </div>
        ))}
      </div>
      <div className="Box-footer">
        <div className="input-group">
          <div className="position-relative">
            <div className="autocomplete-body">
              <div className="clearfix d-flex">
                <select
                  className="form-select mx-1 float-left"
                  aria-label="Dia de la semana"
                  onChange={(event) => setDay(event.target.value)}
                  value={day}
                  disabled={disabled}
                >
                  <option className="Truncate-text">Día</option>
                  {days.map((day) => (
                    <option
                      key={day.code}
                      value={day.code}
                      className="Truncate-text"
                    >
                      {day.name}
                    </option>
                  ))}
                </select>
                <select
                  className="form-select mx-1 float-left Truncate"
                  aria-label="Dia de la semana"
                  onChange={(event) => setStart(event.target.value)}
                  value={start}
                  disabled={disabled}
                >
                  <option className="Truncate-text">Comienzo</option>
                  {hours.map((hourStart) => (
                    <option
                      key={hourStart.code}
                      value={hourStart.code}
                      className="Truncate-text"
                    >
                      {hourStart.name}
                    </option>
                  ))}
                </select>
                <select
                  className="form-select mx-1 float-left Truncate"
                  aria-label="Dia de la semana"
                  onChange={(event) => setEnd(event.target.value)}
                  value={end}
                  disabled={disabled}
                >
                  <option>Final</option>
                  {hours.map((hourEnd) => (
                    <option key={hourEnd.code} value={hourEnd.code}>
                      {hourEnd.name}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  className="btn mx-1 float-left "
                  disabled={
                    day === '' || start === '' || end === '' || disabled
                  }
                  onClick={() => {
                    if (valideEnd(Number(start), Number(end))) {
                      let objectDayRange = {
                        dayOfWeek: Number(day),
                        hourRange: {
                          start: Number(start),
                          end: Number(end),
                        },
                      };
                      if (isRangeValid(day, start, end)) {
                        onSelect(objectDayRange);
                        reset();
                      } else {
                        addToast('Ya existe esa franja horaria', {
                          appearance: 'error',
                          autoDismiss: true,
                        });
                      }
                    } else {
                      addToast(
                        'La hora de entrada no puede ser igual o mayor a la de salida.',
                        {
                          appearance: 'warning',
                          autoDismiss: true,
                        },
                      );
                    }
                  }}
                >
                  Añadir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ScheduleTimeZone.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      dayOfWeek: PropTypes.number.isRequired,
      hourRange: PropTypes.shape({
        start: PropTypes.number.isRequired,
        end: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
