const { gql } = require('@apollo/client');

export default gql`
  query Promotions {
    promotions {
      name
      descriptions {
        language
        text
      }
      owner {
        avatarURL
        email
        isEmailVerificated
        firstName
        lastName
        _id
        createdAt
        updatedAt
        resourcePath
        isViewer
      }
      streetName
      postalCode
      town
      country
      place {
        placeId
        formattedAddress
        geometry {
          location {
            lat
            lng
          }
          viewport {
            northeast {
              lat
              lng
            }
            southwest {
              lat
              lng
            }
          }
        }
        name
      }
      contact {
        name
        email
        primaryPhonePrefix
        primaryPhoneNumber
        secondaryPhonePrefix
        secondaryPhoneNumber
      }
      properties {
        title
        commission
        features {
          bedroomNumber
          bathroomNumber
          areaUsable
        }
        operation {
          price
        }
        _id
        createdAt
        address {
          floor
        }
        images {
          url
          isMain
        }
      }
      videos {
        url
        order
      }
      images {
        url
        isMain
        label
        order
      }
      archiveIntern {
        url
        typology
        _id
      }
      _id
      createdAt
      updatedAt
    }
  }
`;
