import { gql } from '@apollo/client';

export default gql`
  query OwnerById($id: MongoID!) {
    ownerById(_id: $id) {
      avatarURL
      email
      isEmailVerificated
      firstName
      lastName
      _id
      createdAt
      updatedAt
      resourcePath
      isViewer
    }
  }
`;
