import { gql } from '@apollo/client';

export default gql`
  mutation Login($email: String!, $password: String!, $actorType: String!) {
    login(email: $email, password: $password, actorType: $actorType) {
      actor {
        _id
        avatarURL
        email
        resourcePath
        isEmailVerificated
        __typename
        ... on Agent {
          firstName
          lastName
          fullName
          languages {
            _id
            name
            code
            nativeName
            flag
          }
          availableDateRanges {
            dayOfWeek
            hourRange {
              start
              end
            }
          }
          activityLocation {
            place {
              placeId
              formattedAddress
              geometry {
                location {
                  lat
                  lng
                }
                viewport {
                  northeast {
                    lat
                    lng
                  }
                  southwest {
                    lat
                    lng
                  }
                }
              }
              name
            }
            kmRange
          }
          experienceYears
          nif
          birthDate
          country {
            _id
            name
            code
            numberCode
            flag
          }
          address {
            placeId
            formattedAddress
            geometry {
              location {
                lat
                lng
              }
              viewport {
                northeast {
                  lng
                  lat
                }
                southwest {
                  lat
                  lng
                }
              }
            }
            name
          }
          activity
          companyNif
          phone
          hasAcceptedContract
          contractSignedAt
          isProfileFulfilled
          isAvailableFulfilled
          isDocumentFulfilled
          nifFiles
          criminalRecordFiles
          bankOwnershipFiles
          iban
          createdAt
        }
      }
      tokens {
        access {
          token
          expires
        }
        refresh {
          expires
          token
        }
      }
    }
  }
`;
