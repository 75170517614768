import { useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { RequireAuth } from 'components';

import HomeRoute from 'routes/home.route';
import LoginRoute from 'routes/login.route';
import FAQRoute from 'routes/faq.route';
import NotFoundRoute from 'routes/notFound.route';
import AgentRoute from 'routes/agent.route';
import OwnerRoute from 'routes/owner.route';
import ClientRoute from 'routes/client.route';
import PropertiesRoute from 'routes/properties.route';
import BenefitsRoute from 'routes/benefits.route';
import GuidelineRoute from 'routes/guideline.route';
import PolicyRoute from 'routes/policy.route';
import ContactRoute from 'routes/contact.route';
// import PasswordResetRoute from 'routes/agent/auth/password-reset.route';
import PropertyViewMainRoute from 'routes/property.route';
import RecoverPasswordRoute from 'routes/recover-password';
import PasswordResetRoute from 'routes/password-reset';
import PromotionViewMainRoute from 'routes/promotion.route';
import FeeRoute from 'routes/fee.route';
import AboutUsRoute from 'routes/about-us.route';
import NewPropertyRoute from 'routes/newproperty.route';
import Technology from 'routes/technology.route';

import AgentRoutes from 'routes/agent';
import OwnerRoutes from 'routes/owner';
import BuyerRoutes from 'routes/buyer';
import AdminRoutes from 'routes/admin';

export default function ApplicationRoutes() {
  const subdomain = useRef(window.location.hostname.split('.')[0]);

  switch (subdomain.current.toLowerCase()) {
    case 'admin':
      return <AdminRoutes />;
    case 'agent':
      return <AgentRoutes />;
    case 'owner':
      return <OwnerRoutes />;
    case 'buy':
      return <BuyerRoutes />;
    default:
      return (
        <Routes>
          <Route path="*" element={<NotFoundRoute />} />
          <Route path="/" element={<HomeRoute />} />
          <Route path="/agent" element={<AgentRoute />} />
          <Route path="/agente" element={<AgentRoute />} />
          <Route path="/sell" element={<OwnerRoute />} />
          <Route path="/buy" element={<ClientRoute />} />
          <Route path="/fee" element={<FeeRoute />} />
          <Route path="/properties" element={<PropertiesRoute />} />
          <Route path="/about-us" element={<AboutUsRoute />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/premium/:keyAccess" element={<NewPropertyRoute />} />
          <Route
            path="/properties/:propertyId"
            element={<PropertyViewMainRoute />}
          />
          <Route
            path="/promotions/:promotionId"
            element={<PromotionViewMainRoute />}
          />
          <Route path="/benefits" element={<BenefitsRoute />} />
          <Route path="/guideline" element={<GuidelineRoute />} />
          <Route path="/policy" element={<PolicyRoute />} />
          <Route path="/contact" element={<ContactRoute />} />
          <Route
            path="login"
            element={
              <RequireAuth isRequired={false}>
                <LoginRoute />
              </RequireAuth>
            }
          />
          <Route
            path="/recover-password"
            element={
              <RequireAuth isRequired={false}>
                <RecoverPasswordRoute />
              </RequireAuth>
            }
          />
          <Route
            path="/password-reset"
            element={
              <RequireAuth isRequired={false}>
                <PasswordResetRoute />
              </RequireAuth>
            }
          />
          <Route path="/faq" element={<FAQRoute />} />
        </Routes>
      );
  }
}
