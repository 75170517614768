import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { LOGIN_MUTATION } from 'apollo/mutations';
import { ACTOR_QUERY } from 'apollo/queries';
import { ApplicationLoader } from 'components';
import { useToasts } from 'react-toast-notifications';
import { useLocation, useNavigate } from 'react-router-dom';

export const initialState = {
  actor: undefined,
  signin: (email, password, actorType = 'AGENT') => {},
  signout: () => {},
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SIGNIN':
      return { ...state, actor: action.payload };
    case 'SIGNOUT':
      return { ...state, actor: undefined };
    default:
      return state;
  }
};

export const AuthContext = createContext(initialState);
export const AuthDispatchContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
  const client = useApolloClient();
  const { addToast } = useToasts();
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);

  const onLoadActor = useCallback(
    (data) => {
      dispatch({ type: 'SIGNIN', payload: data.actor });
    },
    [dispatch],
  );

  const onFailLoadActor = useCallback(
    (error) => {
      addToast('Su sesión ha expirado', {
        appearance: 'info',
        autoDismiss: true,
      });
      localStorage.clear();
      client.clearStore();
      dispatch({ type: 'SIGNOUT' });
      navigate('/login', { replace: true });
    },
    [client, dispatch, addToast, navigate],
  );

  const { loading } = useQuery(ACTOR_QUERY, {
    onCompleted: onLoadActor,
    onError: onFailLoadActor,
  });

  const onSignin = useCallback(
    (data) => {
      const {
        login: { actor, tokens },
      } = data;
      dispatch({ type: 'SIGNIN', payload: actor });
      localStorage.setItem('token', tokens.access.token);
      localStorage.setItem('tokenExpires', tokens.access.expires);
      localStorage.setItem('refreshToken', tokens.refresh.token);
      localStorage.setItem('refreshTokenExpires', tokens.refresh.expires);
      navigate(location.state ? location.state.from.pathname : '/', {
        replace: true,
      });
    },
    [dispatch, navigate, location.state],
  );

  const onLoginError = useCallback(
    (error) => {
      addToast(error.message, { appearance: 'error', autoDismiss: true });
    },
    [addToast],
  );

  const [login, { loading: loginLoading }] = useMutation(LOGIN_MUTATION, {
    onCompleted: onSignin,
    onError: onLoginError,
  });

  /**
   * Handle signin
   * @param {String} email - The email of the user
   * @param {String} password - The password of the user
   * @param {VoidFunction} callback - The callback function
   */
  const signin = useCallback(
    (email, password, actorType = 'AGENT') => {
      login({
        variables: { email, password, actorType },
      });
    },
    [login],
  );

  const signout = useCallback(() => {
    client.clearStore();
    localStorage.clear();
    dispatch({ type: 'SIGNOUT' });
    navigate('/login', { replace: true });
  }, [client, dispatch, navigate]);

  const value = useMemo(
    () => ({ ...state, loading: loginLoading, signin, signout }),
    [state, loginLoading, signin, signout],
  );

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthContext.Provider value={value}>
        {loading ? <ApplicationLoader /> : children}
      </AuthContext.Provider>
    </AuthDispatchContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return context;
};
