import { useState } from 'react';
import { HomeFooter, NexunLogo } from 'components';
import {
  FormControl,
  TextInput,
  Box,
  Button,
  ThemeProvider,
} from '@primer/react';
import { Link, NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD_MUTATION } from 'apollo/mutations';
import { useToasts } from 'react-toast-notifications';

export default function PasswordResetRoute() {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [searchParams] = useSearchParams();

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD_MUTATION, {
    onCompleted: () => {
      addToast('Se ha restablecido su contraseña', {
        appearance: 'success',
        autoDismiss: true,
      });
      navigate('/login', { replace: true });
    },
    onError: (error) => {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    },
  });

  const handleResetPassword = (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      addToast('Las contraseñas no coinciden', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    resetPassword({
      variables: {
        password,
        token: searchParams.get('token'),
      },
    });
  };

  return (
    <ThemeProvider>
      <div className="d-flex flex-column flex-1 color-bg-subtle">
        <div className="container-sm p-responsive width-full d-flex flex-column flex-items-center py-8">
          <div className="d-flex flex-column flex-items-center flex-justify-center text-center">
            <NavLink to="/" className="color-fg-default no-underline">
              <NexunLogo size="large" onlyIcon />
            </NavLink>
            <h1 className="h3 text-center mt-3">Restablecer contraseña</h1>
          </div>
          <div className="color-bg-default rounded-2 color-shadow-medium mt-4 width-full">
            <div className="p-4">
              <form onSubmit={handleResetPassword}>
                <Box display="grid" gridGap={3}>
                  <FormControl required>
                    <FormControl.Label>Nueva contraseña</FormControl.Label>
                    <TextInput
                      type="password"
                      name="new-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      size="large"
                      autoFocus
                      contrast
                      block
                    />
                  </FormControl>
                  <FormControl required>
                    <FormControl.Label>
                      Confirmar nueva contraseña
                    </FormControl.Label>
                    <TextInput
                      type="password"
                      name="new-password-confirm"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      size="large"
                      contrast
                      block
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    variant="primary"
                    size="large"
                    fontSize={6}
                    block
                    disabled={
                      password.trim().length === 0 ||
                      confirmPassword.trim().length === 0 ||
                      loading
                    }
                  >
                    {loading
                      ? 'Restableciendo contraseña…'
                      : 'Restablecer contraseña'}
                  </Button>
                </Box>
              </form>
              <p className="text-center mt-4">
                ¿No es lo que buscaba?{' '}
                <Link to="/login" className="Link">
                  Volver al inicio de sesión
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
        <HomeFooter />
      </div>
    </ThemeProvider>
  );
}
