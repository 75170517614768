export const guidelineData = [
  {
    id: 1,
    title: 'Requisitos',
    items: [
      {
        id: 1,
        question: 'DNI, NIE o CIF',
        answer:
          'Necesitamos tu documento de identidad para conocerte. Además, para poder trabajar en Nexun deberás ser nacional o residir en el país en el que vas a prestar tus servicios y tener más de 18 años. En caso de ser sociedad deberás tener un CIF o número fiscal europeo.',
      },
      {
        id: 2,
        question: 'Autónomo o sociedad',
        answer:
          'Es imprescindible que seas autónomo o tengas una sociedad. En Nexun te pediremos que nos emitas facturas para poder pagarte las honorarios que generes cuando vendas los inmuebles.',
      },
    ],
  },
  {
    id: 2,
    title: 'Creación de cuenta',
    items: [
      {
        id: 1,
        question: 'Correo electrónico o teléfono',
        answer:
          'Registrarse es fácil, y el primer paso es que indiques un email y una contraseña, que además de convertirse en el canal habitual de notificaciones, será tus claves de acceso a tu sesión privada. El teléfono que te pediremos será el canal secundario de comunicación.',
      },
      {
        id: 2,
        question: 'Datos de contacto',
        answer:
          'Te pediremos algunos datos básicos para conocerte, como tu nombre, apellidos, fecha de nacimiento, dirección, Código Postal y el número del DNI o NIE. En Nexun todos los agentes, colaboradores y clientes deben estar perfectamente identificados. Todos los datos que entregues estarán resguardados según el Reglamento General de Protección de Datos establecidos por la Comunidad Europea. Puede ver más sobre nuestra Política de Privacidad y Protección de Datos en el siguiente enlace.',
      },
      {
        id: 3,
        question: 'Zona de trabajo y disponibilidad',
        answer:
          'Acaba de configurar tu perfil: Necesitamos saber tu disponibilidad horaria y tu radio de actuación, dinos cuándo y en qué zona quieres vender. Trabaja con toda la flexibilidad que necesitas, aunque cuanto más tiempo le dediques, más posibilidades tendrán de aumentar tus honorarios.',
      },
    ],
  },
  {
    id: 3,
    title: 'Contrato de afiliación',
    items: [
      {
        id: 1,
        question: 'Copia de documento de identificación',
        answer:
          'Dentro de tu zona privada, te pediremos que subas una imagen de tu DNI por las dos caras y a color en formato pdf o jpg, con suficiente calidad como para poder validar tu identidad antes de la firma del contrato de afiliación.',
      },
      {
        id: 2,
        question: 'Certificiado de antecedentes penales',
        answer:
          'En Nexun lo pedimos para garantizar la seguridad de todos los agentes, propietarios y compradores con los que trabajamos. El certificado de antecedentes penales en España se puede solicitar a través del Ministerio de Justicia. La solicitud se puede realizar online o en una oficina postal. Más información sobre este procedimiento en este enlace.',
        enlace:
          'https://sede.mjusticia.gob.es/es/tramites/certificado-antecedentes',
      },
      {
        id: 3,
        question: 'Revisa y firma el contrato y honorarios',
        answer:
          'Ponemos a tu disposición el borrador del contrato para que puedas leerlo tranquilamente y, si lo necesitas, consultarnos cualquier duda antes de la firma. Lo firmamos digitalmente. Es muy fácil. Te ayudamos con los pasos. En tu zona privada encontrarás todo lo referente a las honorarios, podrás ver cuánto vas a ganar por cada operación en la que participes. El porcentaje de ganancia varía en función de tu participación en la compraventa: si captas la vivienda, traes un comprador o únicamente enseñas el inmueble y otros factores que ahí se explican.',
      },
    ],
  },
  {
    id: 4,
    title: 'Formación',
    items: [
      {
        id: 1,
        question: 'Mejores prácticas para vender',
        answer:
          'Te ofrecemos recomendaciones, post y cursos gratuitos sobre asesoramiento inmobiliario: venta consultiva, financiación, asesoramiento fiscal y jurídico y todo lo que te puede ayudar a asesorar mejor a los clientes y, en consecuencia, a vender más viviendas en menos tiempo.',
      },
      {
        id: 2,
        question: 'Protocolo Nexun',
        answer:
          'Se trata de cursos específicos como agente de Nexun que además garantizan a nuestros clientes que todos nuestros colaboradores usan protocolos homogéneos. Te pedimos que los hagas tanto si tienes experiencia como si estás empezando tu carrera profesional. No te quitarán mucho tiempo. Son pequeños textos de lectura fácil. En algunos casos, al final de la lectura te haremos un sencillo test para ver que los has asimilado.',
      },
    ],
  },
  {
    id: 5,
    title: 'Comienza a vender',
    items: [
      {
        id: 1,
        question: 'Actuar como Captador, Prescriptor o Vendedor',
        answer:
          'Ya puedes empezar a trabajar y a generar ingresos. Por un lado, recibirás asignaciones de viviendas y citas para enseñárselas a posibles compradores, por otro, podrás subir leads interesados en comprar como agente prescriptor o añadir en la plataforma inmuebles que vayas captando. Te proporcionamos todas las opciones para que multipliques tus probabilidades de éxito.',
      },
      {
        id: 2,
        question: 'Recibe tus pagos',
        answer:
          'Una vez que Nexun certifique el cierre y pago del servicio de la operación de compra venta, y hayamos recibido tu factura de prestación de servicio, procederemos antes de 24 hrs a realizar una transferencia para el pago de las honorarios generadas.',
      },
    ],
  },
];
