import { useState } from 'react';
import NexunLogo from 'components/NexunLogo';
import { useEffect } from 'react';

import './../Gallery/gallery.css';
import {
  XIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@primer/octicons-react';
import MapPlatform from 'components/MapPlatform';

export default function ModalNexun({
  array = [],
  index = 0,
  name,
  address,
  setModal,
  setMap,
  lat = 0,
  lng = 0,
  range = 0,
}) {
  const [slideNumber, setSlideNumber] = useState(index);
  const validate = array.length === 1 || array.length === 0 ? false : true;
  const arrayPlanes = array.map((image) => {
    if (image.label === 'plan') {
      return image.url;
    } else {
      return null;
    }
  });
  const arrayPlanos = arrayPlanes.filter((item) => item != null);

  useEffect(() => {
    if (setModal || setMap) {
      const handleKeyDown = (event) => {
        switch (event.key) {
          case 'ArrowLeft' && validate:
            setSlideNumber((slideNumber) => {
              slideNumber === 0
                ? setSlideNumber(arrayPlanos.length - 1)
                : setSlideNumber(slideNumber - 1);
            });
            break;
          case 'ArrowRight' && validate:
            setSlideNumber((slideNumber) => {
              slideNumber + 1 === arrayPlanos.length
                ? setSlideNumber(0)
                : setSlideNumber(slideNumber + 1);
            });
            break;
          case 'Escape':
            if (setModal) {
              setModal(false);
            } else {
              setMap(false);
            }
            break;
          default:
            break;
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [arrayPlanos, setModal, validate, setMap]);

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(arrayPlanos.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  // Next Image
  const nextSlide = () => {
    slideNumber + 1 === arrayPlanos.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  if (setMap)
    return (
      <div className="sliderWrap">
        <div className="wrapperTop">
          <button
            onClick={() => setMap(false)}
            style={{
              border: 'none',
              background: 'transparent',
            }}
          >
            <h3 className="tittle f2-light">{name} | Localización</h3>
            <h3 className="subtittle f3-light">{address}</h3>
            <XIcon size={30} className="btnClose" />
          </button>
        </div>

        <div className="wraperBanner">
          <NexunLogo className="text-center" />
        </div>

        <div className="fullScreenImage">
          <MapPlatform
            location={{ lat, lng }}
            disableInput
            width="100%"
            range={range}
          />
        </div>
      </div>
    );

  return (
    <div className="sliderWrap">
      <div className="wrapperTop">
        <button
          onClick={() => setModal(false)}
          style={{
            border: 'none',
            background: 'transparent',
          }}
        >
          <h3 className="tittle f2-light">{name} | Planos</h3>
          <h3 className="subtittle f3-light">{address}</h3>
          <XIcon size={30} className="btnClose" />
        </button>
      </div>
      {validate && (
        <>
          <button
            onClick={prevSlide}
            style={{
              border: 'none',
              background: 'transparent',
            }}
          >
            <ChevronLeftIcon size={40} className="btnPrev" />
          </button>
          <button
            onClick={nextSlide}
            style={{
              border: 'none',
              background: 'transparent',
            }}
          >
            <ChevronRightIcon size={40} className="btnNext" />
          </button>
        </>
      )}
      <div className="wraperBanner">
        <NexunLogo className="logoNexun" />
        <h3 className="photoNumber f3-light">
          {slideNumber + 1} / {arrayPlanos.length}{' '}
        </h3>
      </div>

      <div className="fullScreenImage">
        <img src={arrayPlanos[slideNumber]} alt="" />
      </div>
    </div>
  );
}
