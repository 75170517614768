import { NavLink, useParams } from 'react-router-dom';
import Comissions from 'components/Comissions/comissions';
import Gallery from 'components/Gallery';
import { useState } from 'react';
import ModalNexun from 'components/ModalNexun';

import Baños from 'images/logos/Banos.png';
import Dormitorios from 'images/logos/Dormitorios.png';
import Garaje from 'images/logos/Garaje.png';
import Inmueble from 'images/logos/Inmueble.png';
import Metros from 'images/logos/Metros.png';
import Planta from 'images/logos/Planta.png';
import Portero from 'images/logos/Portero.png';
import Terraza from 'images/logos/Terraza.png';
import Ubicacion from 'images/logos/Ubicacion.png';
import { useQuery } from '@apollo/client';
import { PROPERTY_QUERY } from 'apollo/queries';
import { useDescriptionText } from 'hooks';

import { FileIcon } from '@primer/octicons-react';

export default function PropertyViewRoute() {
  const [photoButton, setStatePhoto] = useState(false);
  const [modal, setModal] = useState(false);
  const [map, setMap] = useState(false);

  const handleModal = () => {
    setModal(!modal);
  };

  const handleMap = () => {
    setMap(!map);
  };

  const handleShowPhoto = () => {
    setStatePhoto(!photoButton);
  };

  const TakeDescription = (description, language) => {
    const descriptionText = useDescriptionText(description);
    return descriptionText;
  };

  function CheckDocument(archives, option) {
    const selected = archives.find((archive) => archives.typology === option);
    if (!selected) {
      return null;
    }
    return selected.url;
  }

  function RetrieveNameOfDocument(option) {
    if (option === 'saleFlyer') return 'Dossier Venta';
    if (option === 'energyCertificate') return 'Certificado Energético';
    if (option === 'qualityDocumentation') return 'Documentación Técnica';
  }

  // Number Currency Format
  const numberFormat = (value) =>
    new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
    }).format(value);

  const params = useParams();
  const { data, loading } = useQuery(PROPERTY_QUERY, {
    variables: {
      filter: { _id: params.propertyId },
    },
  });

  // Calcule the commission of the property
  const calculateBenefits = (value, commision) => {
    return value * (commision / 100);
  };

  if (loading) {
    <div className="width-full color-bg-subtle anim-fade-in">
      <div className="container-lg">
        <div className="p-responsive pb-6 color-bg-default rounded-2 border">
          <div className="d-flex flex-row flex-justify-between px-1 ">
            CARGANDO...
          </div>
        </div>
      </div>
    </div>;
  }

  if (data)
    return (
      <div className="width-full color-bg-subtle anim-fade-in">
        <div className="container-lg">
          <div className="p-responsive pb-6 color-bg-default rounded-2 border">
            <div className="d-flex flex-row flex-justify-between px-1 ">
              <div className="pt-3 mb-4 ">
                {/* <NavLink
                to="/properties"
                className="link-mktg arrow-target-mktg text-semibold f5 color-fg-subtle text-light"
              >
                <ChevronLeftIcon />
                Atrás
              </NavLink> */}
              </div>
              {/* TODO: BOTÓN PARA AÑADIR A PROPIEDADES QUE TRABAJA EL AGENTE */}
              {/* <div className="pt-3 mb-4">
              <button className="btn-octicon" type="button">
                <FileDirectoryIcon />
              </button>
            </div> */}
            </div>

            {/* TITLE OF PROPERTIES */}
            <div className="d-flex flex-row pl-1">
              <div className="Subhead col-12">
                <div className="col-lg-7 col-12 ">
                  <h1 className="Subhead-heading">{data.property.title}</h1>
                  <p className="Subhead-description">
                    {data.property.place.formattedAddress}
                  </p>
                </div>
                <div className="col-lg-5 col-12 d-flex flex-items-end flex-justify-around mb-2">
                  <button className="btn" onClick={handleShowPhoto}>
                    Fotos
                  </button>
                  <button className="btn" onClick={handleMap}>
                    Mapa
                  </button>
                  <button className="btn" onClick={handleModal}>
                    Planta
                  </button>
                </div>
              </div>
            </div>
            {/* IMAGES OF PROPERTIES */}
            <div className="d-lg-flex flex-row d-inline-block">
              <Gallery
                galleryImages={data.property.images}
                name={data.property.title}
                address={data.property.place.formattedAddress}
                photoButton={photoButton}
              />
              {modal && (
                <ModalNexun
                  array={data.property.images}
                  index={0}
                  name={data.property.title}
                  address={data.property.place.formattedAddress}
                  setModal={setModal}
                />
              )}
              {map && (
                <ModalNexun
                  lat={data.property.place.geometry.location.lat}
                  lng={data.property.place.geometry.location.lng}
                  name={data.property.title}
                  address={data.property.place.formattedAddress}
                  setMap={setMap}
                />
              )}
            </div>
            {/* PRICE OF PROPERTY */}
            <div className="d-flex flex-justify-center flex-row mt-3 border-top color-border-muted">
              <p className="f1 p-2">
                {numberFormat(data.property.operation.price)}
              </p>
            </div>
            {/* DATA ICONS OF PROPERTIES */}
            <div className="d-flex flex-row border-bottom border-top py-4 color-border-muted">
              <ul className="list-style-none col-12 text-center">
                <li className="col-4 p-2 d-inline-block">
                  <p className="d-flex flex-row flex-justify-center flex-self-center">
                    <img src={Metros} alt="fallo" width={32} />{' '}
                    {data.property.features.areaUsable} m2
                  </p>
                </li>
                <li className="d-inline-block col-4 p-2">
                  <p className="d-flex flex-row flex-justify-center flex-self-center">
                    <img src={Terraza} alt="fallo" width={32}></img>{' '}
                    {data.property.features.terrace ? 'Con' : 'Sin'} terraza
                  </p>
                </li>
                <li className="d-inline-block col-4 p-2">
                  <p className="d-flex flex-row flex-justify-center flex-self-center">
                    <img src={Planta} alt="fallo" width={32}></img>{' '}
                    {data.property.address.floor === '0'
                      ? 'Planta Baja'
                      : data.property.address.floor + 'ª planta'}
                  </p>
                </li>
                <li className="d-inline-block col-4 p-2">
                  <p className="d-flex flex-row flex-justify-center flex-self-center">
                    <img src={Dormitorios} alt="fallo" width={32}></img>{' '}
                    {data.property.features.bathroomNumber} dormitorios
                  </p>
                </li>
                <li className="d-inline-block col-4 p-2">
                  <p className="d-flex flex-row flex-justify-center flex-self-center">
                    <img src={Garaje} alt="fallo" width={32}></img>{' '}
                    {data.property.features.parkingAvailable ? 'Con' : 'Sin'}{' '}
                    garaje
                  </p>
                </li>
                <li className="d-inline-block col-4 p-2">
                  <p className="d-flex flex-row flex-justify-center flex-self-center">
                    <img src={Portero} alt="fallo" width={32}></img>{' '}
                    {data.property.features.doorman ? 'Con' : 'Sin'} portero
                  </p>
                </li>
                <li className="d-inline-block col-4 p-2 ">
                  <div className="d-flex flex-justify-center flex-content-center">
                    <p className="d-flex flex-row flex-justify-center flex-self-center">
                      <img src={Baños} alt="fallo" width={32}></img>{' '}
                      {data.property.features.bathroomNumber} baños
                    </p>
                  </div>
                </li>
                <li className="d-inline-block col-4 p-2">
                  <p className="d-flex flex-row flex-justify-center flex-self-center">
                    <img src={Ubicacion} alt="fallo" width={32}></img>
                    {data.property.features.windowsLocation === 'external'
                      ? 'Exterior'
                      : 'Interior'}{' '}
                  </p>
                </li>
                <li className="d-inline-block col-4 p-2">
                  <p className="d-flex flex-row flex-justify-center flex-self-center">
                    <img src={Inmueble} alt="fallo" width={32}></img>
                    {data.property.features.conservation ===
                    'new_development_finished'
                      ? 'Obra nueva finalizada'
                      : 'Buen estado'}{' '}
                  </p>
                </li>
              </ul>
            </div>
            {/* PRESENTATION PROPERTY */}
            <div className="d-flex flex-row mt-3">
              <p className="format px-2">
                {TakeDescription(data.property.descriptions, 'spanish')}
              </p>
            </div>
            {/* ARCHIVOS DE LA PROPIEDAD */}
            <div className="mt-3 border-top py-4">
              <h3 className="text-start f1-mktg text-light pl-2 pb-3">
                Descargables
              </h3>
              <div className="d-flex flex-row mt-3 flex-justify-around">
                {data.property.archiveIntern.map((archive) => (
                  <div className="text-center px-2">
                    <FileIcon size={20} className="mr-2" />
                    <a
                      href={CheckDocument(
                        data.property.archiveIntern,
                        archive.typology,
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {RetrieveNameOfDocument(archive.typology)}
                    </a>
                  </div>
                ))}
              </div>
            </div>
            {/* COMPONENTE COMISIONES */}
            <div className="d-flex flex-row">
              <Comissions
                benefits={calculateBenefits(
                  data.property.operation.price,
                  data.property.commission,
                )}
              />
            </div>
            {/* ENLACE COMISIONES */}
            <div className="d-flex flex-row mt-3">
              <NavLink to="/commissions"> Saber más de las honorarios</NavLink>
            </div>
          </div>
        </div>
      </div>
    );
}
