import quote1 from 'images/quotes/Testimonio1.jpg';
import quote2 from 'images/quotes/Testimonio2.jpg';
import quote3 from 'images/quotes/Testimonio3.jpg';
import people1 from 'images/people/1.jpg';
import people2 from 'images/people/2.jpg';
import people3 from 'images/people/3.jpg';
import people4 from 'images/people/4.jpg';
import people5 from 'images/people/5.jpg';
import people6 from 'images/people/6.jpeg';
import people7 from 'images/people/7.jpg';
import people8 from 'images/people/8.jpg';

import img1 from 'images/feria/1-min.jpg';
import img2 from 'images/feria/2.jpg';
import img3 from 'images/feria/3.webp';
import img4 from 'images/feria/4.jpg';
import img5 from 'images/feria/5.jpg';
import img6 from 'images/feria/6.webp';
import img7 from 'images/feria/7.png';
import img8 from 'images/feria/8.webp';
import img9 from 'images/feria/9.webp';
import img10 from 'images/feria/10.webp';
import BannerFinal from 'images/feria/BannerFinal.webp';
import Logo2 from 'images/feria/Logo2.png';

import reload from 'images/icons/reload.svg';
import page from 'images/icons/page.svg';
import arrow from 'images/icons/arrow.svg';

import palma from 'images/home/palma.png';
import bcn from 'images/home/bcn.png';
import madrid from 'images/home/madrid.png';
import valladolid from 'images/home/valladolid.png';

export const imgIcons = [
  {
    id: 1,
    icon: reload,
  },
  {
    id: 2,
    icon: page,
  },
  {
    id: 3,
    icon: arrow,
  },
];

export const imagesFeria = [
  {
    id: 1,
    img: img1,
  },
  {
    id: 2,
    img: img2,
  },
  {
    id: 3,
    img: img3,
  },
  {
    id: 4,
    img: img4,
  },
  {
    id: 5,
    img: img5,
  },
  {
    id: 6,
    img: img6,
  },
  {
    id: 7,
    img: img7,
  },
  {
    id: 8,
    img: img8,
  },
  {
    id: 9,
    img: img9,
  },
  {
    id: 10,
    img: img10,
  },
  {
    id: 11,
    img: BannerFinal,
  },
  {
    id: 12,
    img: Logo2,
  },
];

export const testimonies = [
  {
    id: 1,
    title: 'Felices con la rapidez de Nexun',
    quote:
      'Llevamos años tratando de vender nuestra casa al precio que nos parecía correcto. Fue increíble que en solo semanas Nexun fue capaz de conseguirlo.',
    name: 'Antonio López',
    location: 'Alcobendas, Madrid',
    avatarURL: quote1,
  },
  {
    id: 2,
    title: 'Nexun me entregó transparencia',
    quote:
      'Al principio tuve dudas sobre depositar en Nexun la confianza de vender mi piso. Pero al ver la transparencia con que operaba todo en un entorno digital decidí apostar por ellos.',
    name: 'Javier Romero',
    location: 'Nueva España, Madrid',
    avatarURL: quote2,
  },
  {
    id: 3,
    title: 'Nexun envió interesados seleccionados',
    quote:
      'Después de decenas de visitas que nos enviaba la agencia anterior, nos cambiamos a Nexun. Bastó que fuera solo una visita para vender nuestro piso. Encontraron al interesado correcto.',
    name: 'Carmela Santos',
    location: 'Santa Clara, Sevilla',
    avatarURL: quote3,
  },
];

export const promoAgents = [
  {
    id: 1,
    avatarURL: people1,
    name: 'Sebastián Betanzo',
    location: 'Madrid, España',
  },
  {
    id: 2,
    avatarURL: people2,
    name: 'Isabel Coca',
    location: 'Madrid, España',
  },
  {
    id: 3,
    avatarURL: people3,
    name: 'Eduardo Clavijo',
    location: 'Madrid, España',
  },
  {
    id: 4,
    avatarURL: people4,
    name: 'Inés Fernández',
    location: 'Madrid, España',
  },
  {
    id: 5,
    avatarURL: people5,
    name: 'Mariano Maneti',
    location: 'Sevilla, España',
  },
  {
    id: 6,
    avatarURL: people6,
    name: 'Tiago Setas',
    location: 'CDMX, México',
  },
  {
    id: 7,
    avatarURL: people7,
    name: 'Miguel Ángel Martínez',
    location: 'Madrid, España',
  },
  {
    id: 8,
    avatarURL: people8,
    name: 'Ignacio García',
    location: 'Santiago, Chile',
  },
];

export const faqItems = [
  {
    id: 1,
    title: 'Cuestiones generales',
    items: [
      {
        id: 1,
        question: '¿Qué es Nexun?',
        answer:
          'Nexun es la primera plataforma inmobiliaria que optimiza tanto la oferta como la demanda de propiedades. A través de inteligencia artificial permite encontrar al agente inmobiliario idóneo para cada propiedad y cada cliente. Este modelo de operaciones permite recortar los tiempos de la transacción, reducir los costos y aumentar las garantías para todos.',
      },
      {
        id: 2,
        question: '¿Porqué Nexun utiliza Blockchain?',
        answer:
          'Esta nueva tecnología se resume como una cadena indivisible de información que es respaldada en miles de ordenadores a la vez, siendo prácticamente imposible la adulteración del contenido. Así el uso de blockchain permitirá que cada contrato quede certificado bajo el más alto estándard de garantías.',
      },
      {
        id: 3,
        question: '¿Qué hace a Nexun diferente?',
        answer:
          'Al tratarse de una plataforma digital y colaborativa, puede acortar los tiempos de sus transacciones de forma significativa y ofrecer tarifas y honorarios más competitivas.',
      },
      {
        id: 4,
        question: '¿Cómo puedo convertirme en un agente de Nexun?',
        answer:
          'Los agentes de Nexun pueden desempeñar uno o varios de estos roles: Captación de propiedades a vender, búsqueda de compradores o exhibición de las propiedades. El soporte centralizado que te brinda Nexun te ayudará a ejecutar con éxito cualquiera de estas tres funciones. Sólo necesitarás estar dado de alta como autónomo y comenzar a trabajar.',
      },
      {
        id: 5,
        question: '¿Cómo puedo vender mi propiedad a través de Nexun?',
        answer:
          'Basta con que crees una cuenta y subas las información que te pedimos de tu propiedad. Esta información no sólo permite orientar a agentes y clientes sobre las condiciones de la misma, sino también permitir a Nexun optimizar su posicionamiento para que se venda más rápido y al mejor precio.',
      },
      {
        id: 6,
        question: '¿Cómo puedo comprar una propiedad a través de Nexun?',
        answer:
          'Una vez identifiques la propiedad que te interesa sólo tienes que agendar cuándo quieres ir a visitarla. Si ésta resulta de tu interés, podrás, de manera on-line, colocar una oferta al propietario. Si éste acepta se generará un contrato digital para formalizar y pagar la reserva. En ese momento recibirás la confirmación de la fecha y notaría donde formalizar la escritura.',
      },
    ],
  },
  {
    id: 2,
    title: 'Precios y honorarios',
    items: [
      {
        id: 1,
        question: '¿Cómo gano dinero siendo un agente en Nexun?',
        answer:
          'Por cada venta de propiedades en la que participes, ya sea en la captación de ésta, la exhibición o habiendo traído al cliente, tendrás honorarios proporcional al precio de compra, que se te abonará de contado a la cuenta bancaria que tú nos indiques, en cuanto se firme la operación. En el contrato de agente verás la composición de esta honorarios.',
      },
      {
        id: 2,
        question: '¿Cómo ahorro en la venta de mi propiedad utilizando Nexun?',
        answer:
          'Nexun incorpora tecnología que permite no sólo brindar más seguridad y transparencia en las transacciones, sino que abarata también los costos de las operaciones. Ese ahorro se traslada directamente a los propietarios, para que estos paguen las honorarios más bajas del mercado',
      },
      {
        id: 3,
        question: '¿Porqué Nexun me da más garantías como cliente?',
        answer:
          'Las propiedades que se comercializan a través de Nexun han sido previamente validadas en el Registro Publico, asegurando la correcta inscripción catastral, titularidad, certificado energético, asi como sus especificaciones. Adicionalmente Nexun actúa como garante de los contratos de reserva.',
      },
    ],
  },
  {
    id: 3,
    title: 'Sobre el equipo de Nexun',
    items: [
      {
        id: 1,
        question: '¿Cual es el origen de esta compañía?',
        answer:
          'Nexun nace con el objetivo de mejorar el proceso y la experiencia en la búsqueda y transacción de propiedades. El equipo fundador ha sido capaz de aunar años de experiencia en el sector inmobiliario con el conocimiento tecnológico para entregar valor a agentes, propietarios y clientes.',
      },
      {
        id: 2,
        question: '¿Donde están las oficinas de Nexun?',
        answer:
          'Somos una startup española y nuestra residencia fiscal está en Madrid. Pero nuestro equipo está distribuido entre la India, España y México. Por ahora no tenemos oficina física, pero en cuanto contemos con ella será un lugar de trabajo y principalmente de encuentro para conocernos y saber cómo podemos mejorar aun más tu experiencia.',
      },
      {
        id: 3,
        question: '¿Qué tipo de tecnología utilizan?',
        answer:
          'A nivel de hardware, contamos con candados digitales que nos permiten resguardar la integridad de una propiedad a la vez que permite a nuestros agentes acceder a ella para mostrarla. La inteligencia artificial permite encontrar la mejor combinación entre agentes, propietarios y clientes, donde se maximice la utilidad de todos. Blockchain permite que nuestros procesos sean encriptados e inalterables dando unas garantías únicas a todos nuestros usuarios.',
      },
    ],
  },
];

export const steps = [
  {
    id: 1,
    title: '1',
    image: img2,
    description:
      'Reserva una visita en el día y el horario que tu quieras. ¡Si! También un domingo o martes por la noche.',
  },
  {
    id: 2,
    title: '2',
    image: img3,
    description:
      'Toda la información de la propiedad disponible para ti, desde el primer momento.',
  },
  {
    id: 3,
    title: '3',
    image: img4,
    description:
      'Haz tu oferta y reserva de forma on-line en nuestra plataforma. ¡Como debe ser!',
  },
  {
    id: 4,
    title: '4',
    image: img5,
    description:
      'Todos los documentos y contratos digitales certificados con tecnología blockchain.',
  },
];

export const cards = [
  {
    id: 1,
    title: 'Visita sin presiones y cuando te venga bien',
    subtitle:
      'Agenda para hacer una visita en persona o mediante una video llamada cuando mejor te convenga, incluso el mismo día.<br><br>Elegiremos al agente que mejor se adecue a tus necesidades y que más conocimiento tenga de esa propiedad y barrio.',
    img: img6,
  },
  {
    id: 2,
    title: 'On-line, pero nunca solo y más trasparente que nunca',
    subtitle:
      'Gracias a la tecnología, tendrás información certificada y actualizada de la propiedad. Además te ayudaremos a evaluar antes de tomar la gran decisión.',
    img: img7,
  },
  {
    id: 3,
    title: 'Cuando estés preparado, apostamos junto a ti',
    subtitle:
      'Si ya sabes que esa será tu próxima vivienda, podrás seguir adelante con tu oferta y luego la reserva de esta.<br><br>La tecnología nos permitirá ofrecerte un proceso transparente e incorrompible.',
    img: img8,
  },
  {
    id: 4,
    title: 'Digital y certificado. <br>Y además ante notario',
    subtitle:
      'Coordinamos contigo la firma de la compra venta ante notario.<br>Pero antes de ello, tendrás disponible toda la documentación y contratos en su versión digital y con la certificación blockchain.',
    img: img9,
  },
];

export const textBoxes = [
  {
    id: 1,
    src: reload,
    title: 'Disponible 24 horas, todos los días',
    description:
      'Tú decides cuando visitar la propiedad, ya sea en video o presencial',
  },
  {
    id: 2,
    src: page,
    title: 'On-line, pero nunca solo.',
    description: 'Acompañamos de principio a fin del proceso de compra venta',
  },
  {
    id: 3,
    src: reload,
    title: 'Una experiencia inigualable',
    description:
      'Un proceso ajustado a tus necesidades de tiempo y presupuesto. Sin sorpresas',
  },
  {
    id: 4,
    src: page,
    title: 'Transparencia y seguridad',
    description:
      'Nuestros pilares para ser la comercializadora líder del mercado',
  },
];

export const ourGoals = [
  {
    id: 1,
    title: '525.000 viviendas',
    description:
      'Guiarte en un mar de viviendas, para que en cuentres la vivienda que se adecua a ti',
  },
  {
    id: 2,
    title: 'De 12 a 4 semanas',
    description: 'Eficiencia en procesos para reducir el tiempo',
  },
  {
    id: 3,
    title: '70 fuentes de datos',
    description:
      'Lo que permitirá a Nexun AI® predecir con máxima precisión los precios de las operaciones El precio de venta de tu vecino es un dato, no "el" dato.',
  },
];

export const techGuide = [
  {
    id: 1,
    title: 'Transparencia',
    description:
      'Nos hemos preocupado de crear un entorno donde prime la transparencia y claridad del proceso. Junto con prevenir la corrupción y fraude, el entregar información precisa y actualizada permitirá a todos tomar las decisiones correctas, al tiempo de promover la eficiencia y la equidad en el mercado inmobiliario.',
  },
  {
    id: 2,
    title: 'Optimización',
    description:
      'Nuestro afán se centra en mejorar la calidad del servicio a todos los actores. Esta búsqueda de la excelencia la conseguimos a base de implementar sólidas rutinas que permite reducir costos, actuar dentro de las normativas y regulaciones del mercado. Los beneficios de nuestro foco en la optimización se irán expandiendo a largo plazo.',
  },
  {
    id: 3,
    title: 'Seguridad',
    description:
      'Generamos un entorno de garantías donde los clientes puedan depositar en nosotros sus propiedades. Nos tomamos muy en serio la protección de datos y trabajamos diligentemente para garantizar la privacidad de nuestros clientes.',
  },
];

export const propertyPlaces = [
  {
    id: 1,
    title: 'Madrid',
    img: madrid,
  },
  {
    id: 2,
    title: 'Valladolid',
    img: valladolid,
  },
  {
    id: 3,
    title: 'Barcelona',
    img: bcn,
  },
  {
    id: 4,
    title: 'Palma',
    img: palma,
  },
];
