import NexunLogo from 'components/NexunLogo';
import ShapeDivider from 'components/ShapeDivider';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Sitelicon from 'images/logos/Sitelicon.png';
import Alastria from 'images/logos/Alastria.png';
import AWS from 'images/logos/AWS.png';

export default function HomeFooter({
  hideDivider = false,
  paddingTop = false,
}) {
  return (
    <>
      {!hideDivider && <ShapeDivider placement="bottom" />}
      <div
        className={
          !paddingTop
            ? 'color-bg-default home-footer'
            : 'color-bg-default pt-8 home-footer'
        }
      >
        <div className="home-divider" />
        <div className="container-lg p-responsive text-center">
          <div className="d-flex flex-items-center flex-justify-center flex-items-stretch flex-wrap gutter clearfix">
            <div className="col-12 col-lg-6 border-lg-right">
              <div className="d-flex flex-column flex-lg-row flex-items-center flex-lg-items-start flex-justify-center flex-lg-justify-end height-full pt-6 pb-3 pb-lg-6 px-4">
                <div>
                  <div className="d-flex flex-column flex-lg-row">
                    <div className="d-none d-lg-block">
                      <NexunLogo />
                    </div>
                    <div className="d-flex flex-column flex-items-center flex-justify-center d-lg-none">
                      <NexunLogo onlyIcon />
                      <h1 className="h3">Nexun</h1>
                    </div>
                    <p className="ml-4 text-center text-lg-left note mt-3 mt-lg-0">
                      La plataforma inmobiliaria que facilita el trabajo a los
                      agentes y permite a los propietarios vender más rápido y
                      mejor
                    </p>
                  </div>
                  <div className="pt-4 d-flex flex-justify-center flex-lg-justify-start">
                    <a
                      href="https://aws.amazon.com/es/"
                      target={'_blank'}
                      rel="nofollow noopener noreferrer"
                    >
                      <img
                        src={AWS}
                        alt="Logo Amazon Web Services"
                        className="mr-3 pr-3 border-right"
                      />
                    </a>
                    <a
                      href="https://alastria.io/"
                      target={'_blank'}
                      rel="nofollow noopener noreferrer"
                    >
                      <img
                        src={Alastria}
                        alt="Logo Alastria"
                        className="mr-3 pr-3 border-right"
                      />
                    </a>

                    <a
                      href="https://www.sitelicon.com/main/"
                      target={'_blank'}
                      rel="nofollow noopener noreferrer"
                    >
                      <img
                        src={Sitelicon}
                        alt="Logo Sitelicon Ecommerce Services"
                        className="mr-3"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-6 border-lg-left"
              style={{ borderColor: '#dcdcdc !important' }}
            >
              <div className="d-flex flex-justify-around flex-items-start height-full py-6 px-4">
                <div className="text-left">
                  <h2 className="h4">Plataforma</h2>
                  <ul className="list-style-none">
                    <li className="py-1">
                      <NavLink to="/" className="Link--secondary">
                        Inicio
                      </NavLink>
                    </li>
                    <li className="py-1">
                      <NavLink to="/agent" className="Link--secondary">
                        Agentes
                      </NavLink>
                    </li>
                    <li className="py-1">
                      <NavLink to="/sell" className="Link--secondary">
                        Propietarios
                      </NavLink>
                    </li>
                    <li className="py-1">
                      <NavLink to="/buy" className="Link--secondary">
                        Compradores
                      </NavLink>
                    </li>
                    <li className="py-1">
                      <NavLink to="/properties" className="Link--secondary">
                        Propiedades
                      </NavLink>
                    </li>
                    <li className="py-1">
                      <NavLink to="/faq" className="Link--secondary">
                        FAQs
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="text-left ml-4">
                  <h2 className="h4">Información</h2>
                  <ul className="list-style-none">
                    <li className="py-1">
                      {/* TODO: LINK TO PRESS ZONE */}
                      <NavLink to="/" className="Link--secondary">
                        Nota de prensa
                      </NavLink>
                    </li>
                    <li className="py-1">
                      <NavLink to="/contact" className="Link--secondary">
                        Contacto
                      </NavLink>
                    </li>
                    <li className="py-1">
                      <NavLink to="/policy" className="Link--secondary">
                        Privacidad
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

HomeFooter.propTypes = {
  hideDivider: PropTypes.bool,
};
