import { FileUploader } from 'react-drag-drop-files';
import { useState } from 'react';
import { FileMediaIcon, TrashIcon } from '@primer/octicons-react';
import {
  CREATE_PRIVATE_FILE_MUTATION,
  UPDATE_AGENT_MUTATION,
} from 'apollo/mutations';
import { useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { useCallback } from 'react';
import { useAuth } from 'context/auth.context';

const ALLOWED_FILE_TYPES = ['JPEG', 'PNG', 'GIF', 'jpg', 'png', 'gif'];

export default function DocumentationRoute() {
  const { actor } = useAuth();
  const { addToast } = useToasts();
  const [iban, setIban] = useState(actor.iban || '');
  const [uploadingFile, setUploadingFile] = useState(false);

  const onError = useCallback(
    (error) => {
      addToast(error.message, { appearance: 'error', autoDismiss: true });
    },
    [addToast],
  );

  const [generateUploadUrl] = useMutation(CREATE_PRIVATE_FILE_MUTATION, {
    onError,
  });

  const [updateAgent, { loading }] = useMutation(UPDATE_AGENT_MUTATION, {
    refetchQueries: ['Actor'],
    onError,
    onCompleted: () => {
      addToast('Perfil actualizado', {
        appearance: 'success',
        autoDismiss: true,
      });
    },
  });

  const handleDNIUpload = async (files) => {
    setUploadingFile(true);
    for (const file of files) {
      const signedPost = await generateUploadUrl({
        variables: { contentType: file.type },
      });
      const { url, fields: jsonFiles } = signedPost.data.createPrivateFile;
      const fields = JSON.parse(jsonFiles);
      const formData = new FormData();
      formData.append('Content-Type', file.type);
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append('file', file);
      try {
        const res = await fetch(url, {
          method: 'POST',
          body: formData,
        });
        const location = res.headers.get('Location');
        updateAgent({
          variables: {
            filter: { _id: actor._id },
            record: {
              nifFiles: [...actor.nifFiles, decodeURIComponent(location)],
            },
          },
        });
      } catch (err) {
        addToast('Error al subir el archivo', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
    setUploadingFile(false);
  };

  const handlePenalUpload = async (files) => {
    setUploadingFile(true);
    for (const file of files) {
      const signedPost = await generateUploadUrl({
        variables: { contentType: file.type },
      });
      const { url, fields: jsonFiles } = signedPost.data.createPrivateFile;
      const fields = JSON.parse(jsonFiles);
      const formData = new FormData();
      formData.append('Content-Type', file.type);
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append('file', file);
      try {
        const res = await fetch(url, {
          method: 'POST',
          body: formData,
        });
        const location = res.headers.get('Location');
        updateAgent({
          variables: {
            filter: { _id: actor._id },
            record: {
              criminalRecordFiles: [
                ...actor.criminalRecordFiles,
                decodeURIComponent(location),
              ],
            },
          },
        });
      } catch (err) {
        addToast('Error al subir el archivo', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
    setUploadingFile(false);
  };

  const handleBankFileUpload = async (files) => {
    setUploadingFile(true);
    for (const file of files) {
      const signedPost = await generateUploadUrl({
        variables: { contentType: file.type },
      });
      const { url, fields: jsonFiles } = signedPost.data.createPrivateFile;
      const fields = JSON.parse(jsonFiles);
      const formData = new FormData();
      formData.append('Content-Type', file.type);
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append('file', file);
      try {
        const res = await fetch(url, {
          method: 'POST',
          body: formData,
        });
        const location = res.headers.get('Location');
        updateAgent({
          variables: {
            filter: { _id: actor._id },
            record: {
              bankOwnershipFiles: [
                ...actor.bankOwnershipFiles,
                decodeURIComponent(location),
              ],
            },
          },
        });
      } catch (err) {
        addToast('Error al subir el archivo', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
    setUploadingFile(false);
  };

  const handleUpdateIban = (event) => {
    event.preventDefault();
    updateAgent({
      variables: {
        filter: { _id: actor._id },
        record: {
          iban,
        },
      },
    });
  };

  return (
    <div className="container-md width-full">
      <div className="p-responsive py-3 color-bg-default rounded-2 border">
        <div className="Subhead mb-0">
          <h2 className="Subhead-heading">Documentación</h2>
        </div>
        <div className="col-12 col-md-8">
          <div className="form-group">
            <div className="form-group-header">
              <label htmlFor="avatar">DNI / NIE </label>
            </div>
            {actor.nifFiles.map((fileURL) => (
              <div
                key={fileURL}
                className="d-flex flex-items-center rounded-3 border color-bg-subtle mb-3 px-3 py-2"
              >
                <a href={fileURL} target="_blank" rel="noreferrer">
                  {fileURL}
                </a>
                <button
                  type="button"
                  className="btn-octicon btn-octicon-danger"
                  onClick={() => {
                    updateAgent({
                      variables: {
                        filter: { _id: actor._id },
                        record: {
                          nifFiles: actor.nifFiles.filter(
                            (file) => file !== fileURL,
                          ),
                        },
                      },
                    });
                  }}
                  disabled={loading || uploadingFile}
                >
                  <TrashIcon />
                </button>
              </div>
            ))}
            {actor.nifFiles.length < 2 && (
              <div className="form-group-body pb-2">
                <FileUploader
                  multiple
                  handleChange={handleDNIUpload}
                  name="file"
                  types={ALLOWED_FILE_TYPES}
                  label="Seleccionar o arrastrar aquí"
                  hoverTitle="Soltar aquí"
                  children={
                    <div
                      className="Box p-6 width-full text-center border-gray-light rounded-1 d-flex flex-column flex-justify-center color-shadow-medium"
                      style={{
                        minWidth: 340,
                        minHeight: 100,
                        borderStyle: 'dashed',
                        borderColor: 'black',
                      }}
                    >
                      <label className="d-flex flex-align-center flex-justify-center">
                        <FileMediaIcon size={24} className="mr-2" />
                        <span className=""> Seleccionar o arrastrar aquí</span>.
                      </label>
                    </div>
                  }
                  disabled={uploadingFile}
                />
              </div>
            )}
            <div className="note">
              Deberás subir una foto de ambos lados del documento. Si no tienes
              DNI/NIE, deberás subir una foto de tu pasaporte.
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8">
          <div className="form-group">
            <div className="form-group-header">
              <label htmlFor="avatar">Antecedentes penales</label>
            </div>
            {actor.criminalRecordFiles.map((fileURL) => (
              <div
                key={fileURL}
                className="d-flex flex-items-center rounded-3 border color-bg-subtle mb-3 px-3 py-2"
              >
                <a href={fileURL} target="_blank" rel="noreferrer">
                  {fileURL}
                </a>
                <button
                  type="button"
                  className="btn-octicon btn-octicon-danger"
                  onClick={() => {
                    updateAgent({
                      variables: {
                        filter: { _id: actor._id },
                        record: {
                          criminalRecordFiles: actor.criminalRecordFiles.filter(
                            (file) => file !== fileURL,
                          ),
                        },
                      },
                    });
                  }}
                  disabled={loading || uploadingFile}
                >
                  <TrashIcon />
                </button>
              </div>
            ))}
            {actor.criminalRecordFiles.length < 2 && (
              <div className="form-group-body pb-2">
                <FileUploader
                  multiple
                  handleChange={handlePenalUpload}
                  name="file"
                  types={ALLOWED_FILE_TYPES}
                  label="Seleccionar o arrastrar aquí"
                  hoverTitle="Soltar aquí"
                  children={
                    <div
                      className="Box p-6 width-full text-center border-gray-light rounded-1 d-flex flex-column flex-justify-center color-shadow-medium"
                      style={{
                        minWidth: 340,
                        minHeight: 100,
                        borderStyle: 'dashed',
                        borderColor: 'black',
                      }}
                    >
                      <label className="d-flex flex-align-center flex-justify-center">
                        <FileMediaIcon size={24} className="mr-2" />
                        <span className=""> Seleccionar o arrastrar aquí</span>.
                      </label>
                    </div>
                  }
                  disabled={uploadingFile}
                />
              </div>
            )}
            <div className="note">
              Los antecedentes penales son un documento que acredita que no
              tienes antecedentes penales. Deberás subir una foto de ambos lados
              del documento. Podrás obtenerlo por medio de{' '}
              <a
                href="https://sede.mjusticia.gob.es/es/tramites/certificado-antecedentes"
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                este enlace
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8">
          <div className="form-group">
            <div className="form-group-header">
              <label htmlFor="avatar">
                Certificado de titularidad bancaria{' '}
              </label>
            </div>
            {actor.bankOwnershipFiles.map((fileURL) => (
              <div
                key={fileURL}
                className="d-flex flex-items-center rounded-3 border color-bg-subtle mb-3 px-3 py-2"
              >
                <a href={fileURL} target="_blank" rel="noreferrer">
                  {fileURL}
                </a>
                <button
                  type="button"
                  className="btn-octicon btn-octicon-danger"
                  onClick={() => {
                    updateAgent({
                      variables: {
                        filter: { _id: actor._id },
                        record: {
                          bankOwnershipFiles: actor.bankOwnershipFiles.filter(
                            (file) => file !== fileURL,
                          ),
                        },
                      },
                    });
                  }}
                  disabled={loading || uploadingFile}
                >
                  <TrashIcon />
                </button>
              </div>
            ))}
            {actor.bankOwnershipFiles.length < 2 && (
              <div className="form-group-body pb-2">
                <FileUploader
                  multiple={true}
                  handleChange={handleBankFileUpload}
                  name="file"
                  types={ALLOWED_FILE_TYPES}
                  label="Seleccionar o arrastrar aquí"
                  hoverTitle="Soltar aquí"
                  children={
                    <div
                      className="Box p-6 width-full text-center border-gray-light rounded-1 d-flex flex-column flex-justify-center color-shadow-medium"
                      style={{
                        minWidth: 340,
                        minHeight: 100,
                        borderStyle: 'dashed',
                        borderColor: 'black',
                      }}
                    >
                      <label className="d-flex flex-align-center flex-justify-center">
                        <FileMediaIcon size={24} className="mr-2" />
                        <span className=""> Seleccionar o arrastrar aquí</span>.
                      </label>
                    </div>
                  }
                  disabled={uploadingFile}
                />
              </div>
            )}
            <div className="note">
              El certificado de titularidad bancaria es un documento que
              acredita que eres el titular de la cuenta bancaria. Deberás subir
              una foto de ambos lados del documento.
            </div>
          </div>
        </div>
        <form onSubmit={handleUpdateIban}>
          <div className="col-12 col-md-8">
            <div className="form-group">
              <div className="form-group-header">
                <label htmlFor="name">IBAN</label>
              </div>
              <div className="form-group-body pb-2">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="ES XX XXXX XXXX XX XXXXXXXXXX"
                  value={iban}
                  onChange={(event) => setIban(event.target.value)}
                  required
                  disabled={loading || uploadingFile}
                />
              </div>
              <div className="note">
                El IBAN es el número de cuenta bancaria donde se recibirán las
                honorarios. Deberás introducirlo tal y como aparece en tu cuenta
                bancaria, sin incluir los espacios.
              </div>
            </div>
          </div>
          <button className="btn btn-primary" type="submit">
            Guardar
          </button>
        </form>
      </div>
    </div>
  );
}
