import { NavLink, useParams } from 'react-router-dom';
import { PropertyCard } from 'components';
import { UserAvatar, AgentSearcher } from 'components';
import NotFoundRoute from 'routes/notFound.route';
import { useState } from 'react';
import { VISIT_QUERY } from 'apollo/queries';
import { Loading } from 'components';
import es from 'javascript-time-ago/locale/es.json';
import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from 'react-time-ago';
import { AlertIcon, CheckIcon } from '@primer/octicons-react';
import { useQuery } from '@apollo/client';
TimeAgo.addLocale(es);

export default function VisitView() {
  const params = useParams();
  const [agent, setAgent] = useState(undefined);
  const [property, setProperty] = useState(undefined);
  const [client, setClient] = useState(undefined);
  const date = new Date();

  const { data, loading } = useQuery(VISIT_QUERY, {
    variables: {
      filter: {
        _id: params.visit_id,
      },
    },
    onCompleted: (data) => {
      setProperty(data.propertyVisit.property);
      setClient(data.propertyVisit.buyer);
      if (data.propertyVisit.agent && !data.propertyVisit.isRefusedByAgent) {
        setAgent(data.propertyVisit.agent);
      }
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (!data?.propertyVisit) {
    return <NotFoundRoute />;
  }

  return (
    <>
      {date > new Date(data.propertyVisit.date) && (
        <span className="flash flash-success mb-3">
          <CheckIcon size={20} />
          Visita realizada{' '}
          <ReactTimeAgo date={data.propertyVisit.date} locale="es" />
        </span>
      )}
      {date < new Date(data.propertyVisit.date) && (
        <span className="flash mb-3">
          <AlertIcon size={20} />
          Visita programada para{' '}
          <ReactTimeAgo date={data.propertyVisit.date} locale="es" />
        </span>
      )}
      <div className="width-full">
        <div className="p-responsive py-3 color-bg-default rounded-bottom-2 border rounded-2 mt-0">
          <div className="py-3">
            <div className="p-responsive mx-auto">
              <div className="Subhead">
                <h3 className="Subhead-heading">
                  Visita el día{' '}
                  {new Date(data.propertyVisit.date).toLocaleDateString(
                    'es-ES',
                  )}{' '}
                  a las{' '}
                  {new Date(data.propertyVisit.date).toLocaleTimeString(
                    'es-ES',
                  )}
                </h3>
              </div>
              <div className="container-lg clearfix d-md-flex flex-justify-between">
                <div className="col-12 col-md-4 border rounded-2 m-2">
                  <h2 className="text-center py-2 border-bottom">Agente</h2>
                  <AgentSearcher
                    value={agent}
                    disabled={loading}
                    visit={data}
                  />
                </div>
                <div className="col-12 col-md-4 border rounded-2 m-2">
                  <h2 className="text-center py-2 border-bottom">Propiedad</h2>
                  {property && <PropertyCard element={property} individual />}
                </div>
                <div className="col-12  col-md-4 border rounded-2 m-2">
                  <h2 className="text-center py-2 border-bottom">Cliente</h2>
                  {client && (
                    <NavLink
                      className="d-inline-block col-12 color-fg-muted no-underline"
                      to={'/users/clients/' + client._id}
                    >
                      <nav className="text-center position-relative">
                        <div className="d-flex flex-justify-center py-3">
                          <UserAvatar
                            url={client.avatarURL}
                            username={client.firstName}
                            size="ultra"
                            hideOnlineIndicator
                          />
                        </div>
                        <h2 className="f4">
                          {client.firstName + ' ' + client.lastName}
                        </h2>
                        <h2 className="f5">{client.email}</h2>
                        <div className="">
                          <div className="border-top mt-3 pt-1 text-left px-2">
                            <label>Teléfono: </label> {client.phone}
                          </div>
                          <div className="text-left px-2">
                            <label>Dirección: </label>{' '}
                            {client.address ? client.address.name : '-'}
                          </div>
                          <div className="text-left px-2">
                            <label>Nacimiento: </label>{' '}
                            {client.birthDate
                              ? new Date(client.birthDate)
                                  .toISOString()
                                  .split('T')[0]
                              : '-'}
                          </div>
                          <div className="text-left px-2">
                            <label>NIF: </label> {client.nif ? client.nif : '-'}
                          </div>
                          <div className="text-left border-bottom pb-1 px-2">
                            <label>Ciudad: </label>{' '}
                            {client.country
                              ? client.country.flag + ' ' + client.country.name
                              : '-'}
                          </div>
                        </div>
                        <div>
                          {data.propertyVisit.isConfirmedByBuyer && (
                            <div className="flash flash-full rounded-2  flash-success">
                              <CheckIcon size={20} />
                              Aceptada
                            </div>
                          )}
                          {!data.propertyVisit.isConfirmedByBuyer && (
                            <div className="flash flash-full rounded-2 flash-warn">
                              <AlertIcon size={20} />
                              Pendiente
                            </div>
                          )}
                        </div>
                      </nav>
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
