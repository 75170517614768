import { useMemo } from 'react';
import { Avatar, Badge, styled } from '@mui/material';
import PropTypes from 'prop-types';

const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    background-color: #44b700;
    color: #44b700;
    box-shadow: 0 0 0 2px var(--color-canvas-default);
  }
`;

const SmallAvatar = styled(Avatar)`
  width: 26px;
  height: 26px;
  border: none;
  font-size: 16px;
  font-weight: 500;
`;

const MediumAvatar = styled(Avatar)`
  width: 36px;
  height: 36px;
  border: none;
  font-size: 16px;
  font-weight: 500;
`;

const LargeAvatar = styled(Avatar)`
  width: 46px;
  height: 46px;
  border: none;
  font-size: 16px;
  font-weight: 500;
`;

const UltraAvatar = styled(Avatar)`
  width: 100px;
  height: 100px;
  border: none;
  font-size: 40px;
  font-weight: 500;
`;

const SettingsAvatar = styled(Avatar)`
  width: 150px;
  height: 150px;
  border: none;
  font-size: 42px;
  font-weight: 500;
`;

export default function UserAvatar({
  url,
  username,
  size = 'small',
  hideOnlineIndicator = false,
}) {
  const AvatarComponent = useMemo(() => {
    switch (size) {
      case 'small':
        return SmallAvatar;
      case 'medium':
        return MediumAvatar;
      case 'large':
        return LargeAvatar;
      case 'settings':
        return SettingsAvatar;
      case 'ultra':
        return UltraAvatar;
      default:
        return Avatar;
    }
  }, [size]);

  return hideOnlineIndicator ? (
    <AvatarComponent src={url} alt={username}>
      {username.charAt(0)}
    </AvatarComponent>
  ) : (
    <StyledBadge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant="dot"
    >
      <AvatarComponent src={url} alt={username}>
        {username.charAt(0)}
      </AvatarComponent>
    </StyledBadge>
  );
}

UserAvatar.propTypes = {
  url: PropTypes.string,
  username: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'ultra', 'settings']),
  hideOnlineIndicator: PropTypes.bool,
};
