import { gql } from '@apollo/client';

export default gql`
  mutation createPrivateFile(
    $contentType: String!
    $expires: Int = 5
    $fileName: String
  ) {
    createPrivateFile(
      contentType: $contentType
      expires: $expires
      fileName: $fileName
    ) {
      url
      fields
    }
  }
`;
