import { gql } from '@apollo/client';

export default gql`
  mutation UpdateBuyerById($id: MongoID!, $record: UpdateByIdBuyerInput!) {
    updateBuyerById(_id: $id, record: $record) {
      record {
        email
        _id
      }
    }
  }
`;
