import {
  ShieldLockIcon,
  LockIcon,
  EyeIcon,
  PeopleIcon,
  ZapIcon,
  CreditCardIcon,
  TrophyIcon,
  PlusIcon,
  GraphIcon,
  SearchIcon,
  PaperclipIcon,
} from '@primer/octicons-react';

export const WhyNexun = [
  {
    title: 'Confidencial',
    subtitle:
      'Resguardamos tus datos e información personal con total apego a la ley de protección de datos',
    logo: <LockIcon size={50} />,
  },
  {
    title: 'Transparencia',
    subtitle:
      'Te mantendremos informado de cada potencial cliente de cada paso que demos',
    logo: <EyeIcon size={50} />,
  },
  {
    title: 'Reduce intermediarios',
    subtitle:
      'Somos tu referencia, y no necesitarás contactar ni coordinar con nadie más',
    logo: <PeopleIcon size={50} />,
  },
  {
    title: 'Velocidad de venta',
    subtitle:
      'Nuestra red de agentes y tecnología nos permite rápidamente encontrar a tu comprador',
    logo: <ZapIcon size={50} />,
  },
  {
    title: 'La honorarios más baja',
    subtitle:
      'La eficiencia de nuestro proceso permite una operación de bajo costo',
    logo: <CreditCardIcon size={50} />,
  },
  {
    title: 'Certificación',
    subtitle:
      'Usamos tecnología blockchain para respaldar documentos y contratos',
    logo: <TrophyIcon size={50} />,
  },
];

export const ownerHowItWorks = [
  {
    logo: <ShieldLockIcon size={50} />,
    title: 'Confidencial',
    description:
      'Resguardamos tus datos e información personal con total apego a la ley de protección de datos',
  },
  {
    logo: <EyeIcon size={50} />,
    title: 'Transparencia',
    description:
      'Te mantendremos informado de cada potencial cliente en cada paso que demos',
  },
  {
    logo: <PeopleIcon size={50} />,
    title: 'Reduce Intermediarios',
    description:
      'Somos tu referencia, y no necesitarás contactar ni coordinar con nadie más',
  },
  {
    logo: <ZapIcon size={50} />,
    title: 'Velocidad de Venta',
    description:
      'Nuestra red de agentes y tecnología nos permite rápidamente encontrar a tu comprador',
  },
  {
    logo: <CreditCardIcon size={50} />,
    title: 'Los honorarios más bajos',
    description:
      'La eficiencia de nuestro proceso permite una operación de bajo costo',
  },
  {
    logo: <TrophyIcon size={50} />,
    title: 'Certificación',
    description:
      'Usamos tecnología blockchain para respaldar documentos y contratos',
  },
];

export const serviceTypes = [
  {
    id: 1,
    title: 'Free',
    firstItem: 'publicación',
    secondItem: 'predicción de cierre',
    thirdItem: 'cita de compradores',
    fourthItem: 'contratos digitales',
    comision: '0% comision',
  },
  {
    id: 2,
    additional: 'Free',
    title: 'Full',
    firstItem: 'comision dinámica',
    secondItem: 'asignación de agentes',
    thirdItem: 'asesoría legal',
    fourthItem: 'plataforma de pagos',
    comision: '1% a 3% comisión',
  },
  {
    id: 3,
    additional: 'Full',
    title: 'Enterprise',
    firstItem: 'integración ERP',
    secondItem: 'comisión estática',
    thirdItem: 'blanqueo capitales',
    fourthItem: 'reportes',
    fifthItem: 'venta anticipada',
    comision: 'Fijo mensual + 1% a 3% comisión',
  },
];

export const ownerSteps = [
  {
    id: 1,
    title: 'Propiedad:',
    description: 'Crea una cuenta que carga información de la propiedad',
  },
  {
    id: 2,
    title: 'Precio:',
    description:
      'Te indicamos las posibilidades de venta según distintos precios',
  },
  {
    id: 3,
    title: 'Concertamos citas',
    description: 'con clientes cualificados',
  },
  {
    id: 4,
    title: 'Recibe ofertas:',
    description: 'Sin intermediarios, los interesados pondrán su oferta',
  },
  {
    id: 5,
    title: 'Documentos y arras digitales:',
    description:
      'Directamente en la plataforma podrás firmar y cargar documentos',
  },
  {
    id: 6,
    title: 'Notaría:',
    description:
      'Preparamos y enviamos toda la documentación, para que sólo tengas que firmar',
  },
];
