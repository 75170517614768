export const techData = [
  {
    id: 1,
    title: '700.000 datos',
    description: 'procesados por minuto',
  },
  {
    id: 2,
    title: '25 años',
    description: 'De estadísticas del mercado',
  },
  {
    id: 3,
    title: '98% efectividad',
    description: 'en conversión de visita a venta',
  },
  {
    id: 4,
    title: '15 años',
    description: 'De estadísticas del mercado',
  },
];

export const howItWorks = [
  {
    title: 'Plataforma unificada',
    description:
      'Nuestra plataforma digital "end-to-end" centraliza todas las operaciones inmobiliarias en un solo lugar. Desde la valoración de activos hasta la gestión de contratos digitales y pasarelas de pago, todo se realiza de manera eficiente y segura dentro de nuestra plataforma.',
  },
  {
    title: 'IA con modelo predictivo',
    description:
      'Nuestra tecnología no solo permite predecir los precios de las viviendas tanto en el presente como en el futuro, sin además nos permite entender a nuestros usuarios para predecir y recomendar la vivienda que mejor se adapte a sus necesidades de espacio, tiempos y presupuesto.',
  },
  {
    title: 'Blockchain para economía colaborativa',
    description:
      'Comienza a disfrutar lo que nexun puede hacer por ti. Nuestra estructura permite integrar a diversos prestadores de servicios, como reformas, notarios, agentes y suministros, dentro de nuestra plataforma. La tecnología blockchain garantiza la transparencia y seguridad en todas las transacciones, creando un entorno fiable y protegido para nuestros usuarios, al mismo tiempo que ofrece un marco de trabajo competitivo para terceros.',
  },
];
