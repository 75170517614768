import { gql } from '@apollo/client';

export default gql`
  query Buyer($filter: FilterFindOneBuyerInput) {
    buyer(filter: $filter) {
      _id
      email
    }
  }
`;
