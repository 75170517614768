import iconM2 from '../images/property/icons/m2.svg';
import iconShower from '../images/property/icons/bedroom.svg';
import iconBedroom from '../images/property/icons/shower.png';

export const newProperties = [
  {
    owner: {
      avatarURL: null,
      email: 'ferran.mtinez@gmail.com',
      isEmailVerificated: false,
      firstName: 'Ferrán',
      lastName: 'Martinez',
      _id: '642d731d4282495ba53ec026',
      createdAt: '2023-05-05T12:54:00.773Z',
      updatedAt: '2023-04-05T12:54:00.773Z',
      resourcePath: '/agent/642d731d4282495ba53ec026',
      isViewer: null,
    },
    title: 'Vivienda Cornella Bajo 2A',
    place: {
      placeId: 'ChIJE7XVlVmZpBIRbIGQtrDxLQQ',
      formattedAddress:
        'Carrer del Cinema Bel, 17, 08940 Cornellà de Llobregat, Barcelona',
      geometry: {
        location: {
          lat: 41.3541068,
          lng: 2.0700667,
        },
        viewport: {
          northeast: {
            lat: 41.3541068,
            lng: 2.0700667,
          },
          southwest: {
            lat: 41.3541068,
            lng: 2.0700667,
          },
        },
      },
      name: 'Carrer del Cinema 17',
    },
    commission: 3,
    archiveIntern: [
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/documents/calidad-cornella.pdf',
        typology: 'saleFlyer',
        _id: '65b2201341066712b386ebcd',
      },
    ],
    address: {
      visibility: 'full',
      streetName: 'Carrer del Cinema',
      streetNumber: '17',
      block: '',
      floor: 'bj',
      stair: '',
      door: '2A',
      urbanization: 'Cornellà Central',
      postalCode: '08940',
      town: 'Cornellà de Llobregat, Barcelona',
      nsiCode: null,
      country: 'Spain',
      coordinatesPrecision: 'exact',
      coordinatesLatitude: 41.3541068,
      coordinatesLongitude: 2.0700667,
    },
    contact: {
      name: 'Ferran Martinez',
      email: 'ferran.mtinez@gmail.com',
      primaryPhonePrefix: '',
      primaryPhoneNumber: '',
      secondaryPhonePrefix: '',
      secondaryPhoneNumber: '',
    },
    descriptions: [
      {
        language: 'spanish',
        text: 'Cornellá Central es una exclusiva promoción de 8 viviendas de alto standing situadas en el centro de Cornellá` de Llobregat, una de las ciudades mejor conectadas a la metrópolis de Barcelona.<br><br>Viviendas amplias, luminosas y exteriores. Con dormitorios de grandes dimensiones que cuentan con vestidor y salida directa a terraza.<br><br>Cada unidad está equipada de elementos domóticos con el fin de proveer a sus habitantes confort y seguridad. A su vez el aparcamiento cuenta con tomas eléctricas individuales y plazas de aparcamiento para motos.<br><br>El proyecto consta de ocho fantásticas unidades, con jardín, terraza y/o balcón, además de doce amplias plazas de aparcamiento, trastero y una piscina y jardín comunitarios, un oasis a pocos minutos de Barcelona.',
      },
    ],
    features: {
      access24h: false,
      accessControl: true,
      allowPets: true,
      areaBuildable: null,
      areaConstructed: 61,
      areaHeight: null,
      areaPlot: null,
      areaTradableMinimum: null,
      areaUsable: 61,
      bathroomAdapted: true,
      bathroomInside: true,
      bathroomNumber: 1,
      bathroomType: 'fullEquiped',
      bedroomNumber: 2,
      buildingAdapted: true,
      builtYear: 2024,
      chimney: false,
      classificationBlocks: true,
      classificationChalet: false,
      classificationCommercial: false,
      classificationHotel: false,
      classificationIndustrial: false,
      classificationOffice: false,
      classificationOther: false,
      classificationPublic: false,
      conditionedAir: true,
      conditionedAirType: 'cold_heat',
      conservation: 'new_development_finished',
      doorman: true,
      duplex: false,
      emergencyExit: false,
      emergencyLights: false,
      energyCertificateLaw: null,
      energyCertificatePerformance: 2.34,
      energyCertificateRating: 'B',
      energyCertificateType: 'completed',
      energyCertificateEmissionsRating: 'B',
      energyCertificateEmissionsValue: 2.34,
      equippedKitchen: true,
      extinguishers: false,
      facadeArea: null,
      fireDetectors: null,
      fireDoors: null,
      floorsBuildable: null,
      floorsBuilding: 3,
      floorsInTop: false,
      floorsProperty: 1,
      garden: true,
      handicapAdaptedAccess: true,
      handicapAdaptedUse: true,
      heating: true,
      heatingType: 'individualAirConditioningHeatPump',
      hotWater: true,
      lastActivity: null,
      liftAvailable: true,
      liftNumber: 1,
      loadingDock: null,
      bridgeCrane: null,
      locatedAtCorner: false,
      nearestLocationKm: null,
      officeBuilding: null,
      orientationEast: true,
      orientationNorth: true,
      orientationSouth: true,
      orientationWest: false,
      parkingAvailable: true,
      parkingAutomaticDoor: true,
      parkingPlaceCovered: true,
      parkingType: 'depot',
      garageCapacityType: null,
      parkingSpacesNumber: 1,
      penthouse: false,
      pool: true,
      builtProperties: 99,
      rooms: 2,
      roomsSplitted: 'withWalls',
      security24h: false,
      securityAlarm: false,
      securityDoor: false,
      securityPersonnel: false,
      securitySystem: false,
      smokeExtraction: true,
      sprinklers: null,
      storage: true,
      studio: false,
      suspendedCeiling: null,
      suspendedFloor: false,
      propertyTenants: false,
      terrace: true,
      ubication: 'unknown',
      utilitiesElectricity: true,
      utilitiesNaturalGas: false,
      utilitiesRoadAccess: true,
      utilitiesSewerage: true,
      utilitiesSidewalk: true,
      utilitiesStreetLighting: true,
      utilitiesWater: true,
      wardrobes: true,
      balcony: true,
      windowsDouble: true,
      windowsLocation: 'external',
      windowsShop: 1,
      newDevelopmentType: 'new_building',
      builtPhase: 'keydelivery',
      availabilityHour: 'business_hour',
      newDevelopmentName: 'Cornellà Central',
      finished: true,
      startDate: null,
      keyDeliveryYear: 2022,
      keyDeliveryMonth: 1,
      onSite: true,
      mortgageStateSubsidized: null,
      mortgageBankName: null,
      mortgagePercentage: null,
      mortgageEntryPercentage: null,
      mortgageLettersPercentage: null,
      mortgageInterestRate: null,
      mortgageYears: null,
      windowsNumber: 3,
      accessType: 'urban',
      tenantNumber: null,
      smokingAllowed: null,
      minTenantAge: null,
      maxTenantAge: null,
      couplesAllowed: null,
      bedType: 'none',
      minimalStay: null,
      windowView: 'street_view',
      ownerLiving: false,
      availableFrom: null,
      tenantGender: null,
      internetAvailable: null,
      houseKeeper: null,
      cupboard: true,
      privateBathroom: true,
      genderPreference: null,
      occupation: null,
      lgtbFriendly: null,
      childrenAllowed: null,
      occupiedNow: false,
      tenantWorkers: null,
      tenantStudents: null,
      minNewTenantAge: null,
      maxNewTenantAge: null,
      exteriorAccessibility: true,
      interiorAccessibility: true,
      lifeStyle: null,
      priceReferenceIndex: null,
      auction: null,
      minAuctionBidIncrement: null,
      auctionDeposit: null,
      auctionDate: null,
      auctionTribunal: null,
      currentOccupation: 'free',
      gardenType: 'private',
      parkingSpaceCapacity: null,
      parkingSpaceArea: null,
      outdoorParkingSpace: null,
      outdoorParkingSpaceType: null,
      outdoorParkingSpaceNumber: null,
      hiddenPrice: null,
      documents: [],
      recommendedForChildren: null,
      tenantNumberHousing: null,
    },
    images: [
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/images/c0.jpg',
        isMain: true,
        label: 'living',
        order: 1,
      },
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/images/c1.jpg',
        isMain: false,
        label: 'facade',
        order: 2,
      },
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/images/c2.jpg',
        isMain: false,
        label: 'facade',
        order: 3,
      },
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/images/c3.jpg',
        isMain: false,
        label: 'room',
        order: 4,
      },
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/plans/cc2.jpg',
        isMain: false,
        label: 'plan',
        order: 5,
      },
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/plans/cornella-parking.jpg',
        isMain: false,
        label: 'plan',
        order: 6,
      },
    ],
    titlePresentation:
      'Tu nuevo hogar a 15 minutos de Barcelona por menos de lo que tu crees',
    subtitle: 'Comodidad en el día a día sin renunciar a lo que más te gusta',
    url: 'cornella-bajo-2a',
    descriptionsPresentation: [
      {
        language: 'spanish',
        text: 'Cornellá Central ofrece 8 viviendas exclusivas en el corazón de Cornellá de Llobregat, perfectamente conectadas con Barcelona. Estas casas, ideales para tu primer hogar, mezclan estilo y funcionalidad para un estilo de vida activo y moderno.<br>Descubre espacios amplios y llenos de luz, con dormitorios que no solo son grandes sino también prácticos, incluyendo vestidores y acceso directo a terrazas privadas. La domótica se integra en cada vivienda, garantizando comodidad y seguridad avanzadas.<br>El proyecto se completa con jardín, terraza o balcón en cada unidad, más doce amplias plazas de aparcamiento con tomas eléctricas y trastero.<br>Lo más destacado: una piscina y jardín comunitarios, creando un pequeño paraíso urbano, a solo minutos de la vibrante Barcelona.',
      },
    ],
    details: [
      'Diseño contemporáneo y eficiente, perfecto para un ritmo de vida activo y urbano',
      'Excelente ubicación con varias opciones de transporte público a tu alcance',
      'Piscina privada exclusiva y zonas verdes',
    ],
    environment: [
      {
        title: 'Un barrio residencial con todas las comodidades urbanas',
        subtitle: 'Tu primer hogar, asequible y vibrante',
        key1: 'Conectividad total, accesible a Barcelona',
        key2: 'Espacios verdes para un estilo de vida activo',
        key3: 'Varias opciones de ocio y comercio a la puerta de tu casa',
        description:
          'Cornellá Central ofrece 8 viviendas exclusivas en el corazón de Cornellá de Llobregat, perfectamente conectadas con Barcelona. Estas casas, ideales para tu primer hogar, mezclan estilo y funcionalidad para un estilo de vida activo y moderno.<br>Descubre espacios amplios y llenos de luz, con dormitorios que no solo son grandes sino también prácticos, incluyendo vestidores y acceso directo a terrazas privadas. La domótica se integra en cada vivienda, garantizando comodidad y seguridad avanzadas.<br>El proyecto se completa con jardín, terraza o balcón en cada unidad, más doce amplias plazas de aparcamiento con tomas eléctricas y trastero.<br>Lo más destacado: una piscina y jardín comunitarios, creando un pequeño paraíso urbano, a solo minutos de la vibrante Barcelona.',
      },
    ],
    icons: [
      {
        src: iconM2,
        description: '61',
      },
      {
        src: iconBedroom,
        description: '1',
      },
      {
        src: iconShower,
        description: '2',
      },
    ],
    operation: {
      depositMonths: 0,
      price: 425000,
      formalExpenses: 2350,
      taxes: 48875,
      mortgage: 5700,
      priceCommunity: null,
      priceToOwn: 425000,
      priceTransfer: null,
      priceParking: null,
      type: 'sale',
    },
    videos: [],
    visibility: null,
    _id: '642d82d64282495ba53ec029',
    createdAt: '2023-04-05T14:00:00.000Z',
    updatedAt: '2023-04-05T15:52:52.251Z',
  },
  {
    owner: {
      avatarURL: null,
      email: 'ferran.mtinez@gmail.com',
      isEmailVerificated: false,
      firstName: 'Ferrán',
      lastName: 'Martinez',
      _id: '642d731d4282495ba53ec026',
      createdAt: '2023-05-05T12:54:00.773Z',
      updatedAt: '2023-04-05T12:54:00.773Z',
      resourcePath: '/agent/642d731d4282495ba53ec026',
      isViewer: null,
    },
    title: 'Vivienda Cornella Segundo 4A',
    place: {
      placeId: 'ChIJE7XVlVmZpBIRbIGQtrDxLQQ',
      formattedAddress:
        'Carrer del Cinema Bel, 17, 08940 Cornellà de Llobregat, Barcelona',
      geometry: {
        location: {
          lat: 41.3541068,
          lng: 2.0700667,
        },
        viewport: {
          northeast: {
            lat: 41.3541068,
            lng: 2.0700667,
          },
          southwest: {
            lat: 41.3541068,
            lng: 2.0700667,
          },
        },
      },
      name: 'Carrer del Cinema 17',
    },
    commission: 3,
    archiveIntern: [
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/documents/calidad-cornella.pdf',
        typology: 'saleFlyer',
        _id: '65b21d7941066712b386ebca',
      },
    ],
    address: {
      visibility: 'full',
      streetName: 'Carrer del Cinema',
      streetNumber: '17',
      block: '',
      floor: '2',
      stair: '',
      door: '4A',
      urbanization: 'Cornellà Central',
      postalCode: '08940',
      town: 'Cornellà de Llobregat, Barcelona',
      nsiCode: null,
      country: 'Spain',
      coordinatesPrecision: 'exact',
      coordinatesLatitude: 41.3541068,
      coordinatesLongitude: 2.0700667,
    },
    contact: {
      name: 'Ferran Martinez',
      email: 'ferran.mtinez@gmail.com',
      primaryPhonePrefix: '',
      primaryPhoneNumber: '',
      secondaryPhonePrefix: '',
      secondaryPhoneNumber: '',
    },
    descriptions: [
      {
        language: 'spanish',
        text: 'Cornellá Central es una exclusiva promoción de 8 viviendas de alto standing situadas en el centro de Cornellá` de Llobregat, una de las ciudades mejor conectadas a la metrópolis de Barcelona.<br><br>Viviendas amplias, luminosas y exteriores. Con dormitorios de grandes dimensiones que cuentan con vestidor y salida directa a terraza.<br><br>Cada unidad está equipada de elementos domóticos con el fin de proveer a sus habitantes confort y seguridad. A su vez el aparcamiento cuenta con tomas eléctricas individuales y plazas de aparcamiento para motos.<br><br>El proyecto consta de ocho fantásticas unidades, con jardín, terraza y/o balcón, además de doce amplias plazas de aparcamiento, trastero y una piscina y jardín comunitarios, un oasis a pocos minutos de Barcelona.',
      },
    ],
    features: {
      access24h: false,
      accessControl: true,
      allowPets: true,
      areaBuildable: null,
      areaConstructed: 91,
      areaHeight: null,
      areaPlot: null,
      areaTradableMinimum: null,
      areaUsable: 91,
      bathroomAdapted: true,
      bathroomInside: true,
      bathroomNumber: 2,
      bathroomType: 'fullEquiped',
      bedroomNumber: 3,
      buildingAdapted: true,
      builtYear: 2024,
      chimney: false,
      classificationBlocks: true,
      classificationChalet: false,
      classificationCommercial: false,
      classificationHotel: false,
      classificationIndustrial: false,
      classificationOffice: false,
      classificationOther: false,
      classificationPublic: false,
      conditionedAir: true,
      conditionedAirType: 'cold_heat',
      conservation: 'new_development_finished',
      doorman: true,
      duplex: true,
      emergencyExit: false,
      emergencyLights: false,
      energyCertificateLaw: null,
      energyCertificatePerformance: 2.34,
      energyCertificateRating: 'B',
      energyCertificateType: 'completed',
      energyCertificateEmissionsRating: 'B',
      energyCertificateEmissionsValue: 2.34,
      equippedKitchen: true,
      extinguishers: false,
      facadeArea: null,
      fireDetectors: null,
      fireDoors: null,
      floorsBuildable: null,
      floorsBuilding: 3,
      floorsInTop: false,
      floorsProperty: 1,
      garden: false,
      handicapAdaptedAccess: true,
      handicapAdaptedUse: true,
      heating: true,
      heatingType: 'individualAirConditioningHeatPump',
      hotWater: true,
      lastActivity: null,
      liftAvailable: true,
      liftNumber: 1,
      loadingDock: null,
      bridgeCrane: null,
      locatedAtCorner: false,
      nearestLocationKm: null,
      officeBuilding: null,
      orientationEast: true,
      orientationNorth: true,
      orientationSouth: true,
      orientationWest: false,
      parkingAvailable: true,
      parkingAutomaticDoor: true,
      parkingPlaceCovered: true,
      parkingType: 'depot',
      garageCapacityType: null,
      parkingSpacesNumber: 1,
      penthouse: true,
      pool: true,
      builtProperties: 99,
      rooms: 3,
      roomsSplitted: 'withWalls',
      security24h: false,
      securityAlarm: false,
      securityDoor: false,
      securityPersonnel: false,
      securitySystem: false,
      smokeExtraction: true,
      sprinklers: null,
      storage: true,
      studio: false,
      suspendedCeiling: null,
      suspendedFloor: false,
      propertyTenants: false,
      terrace: true,
      ubication: 'unknown',
      utilitiesElectricity: true,
      utilitiesNaturalGas: false,
      utilitiesRoadAccess: true,
      utilitiesSewerage: true,
      utilitiesSidewalk: true,
      utilitiesStreetLighting: true,
      utilitiesWater: true,
      wardrobes: true,
      balcony: true,
      windowsDouble: true,
      windowsLocation: 'external',
      windowsShop: 1,
      newDevelopmentType: 'new_building',
      builtPhase: 'keydelivery',
      availabilityHour: 'business_hour',
      newDevelopmentName: 'Cornellà Central',
      finished: true,
      startDate: null,
      keyDeliveryYear: 2022,
      keyDeliveryMonth: 1,
      onSite: true,
      mortgageStateSubsidized: null,
      mortgageBankName: null,
      mortgagePercentage: null,
      mortgageEntryPercentage: null,
      mortgageLettersPercentage: null,
      mortgageInterestRate: null,
      mortgageYears: null,
      windowsNumber: 3,
      accessType: 'urban',
      tenantNumber: null,
      smokingAllowed: null,
      minTenantAge: null,
      maxTenantAge: null,
      couplesAllowed: null,
      bedType: 'none',
      minimalStay: null,
      windowView: 'street_view',
      ownerLiving: false,
      availableFrom: null,
      tenantGender: null,
      internetAvailable: null,
      houseKeeper: null,
      cupboard: true,
      privateBathroom: true,
      genderPreference: null,
      occupation: null,
      lgtbFriendly: null,
      childrenAllowed: null,
      occupiedNow: false,
      tenantWorkers: null,
      tenantStudents: null,
      minNewTenantAge: null,
      maxNewTenantAge: null,
      exteriorAccessibility: true,
      interiorAccessibility: true,
      lifeStyle: null,
      priceReferenceIndex: null,
      auction: null,
      minAuctionBidIncrement: null,
      auctionDeposit: null,
      auctionDate: null,
      auctionTribunal: null,
      currentOccupation: 'free',
      gardenType: 'private',
      parkingSpaceCapacity: null,
      parkingSpaceArea: null,
      outdoorParkingSpace: null,
      outdoorParkingSpaceType: null,
      outdoorParkingSpaceNumber: null,
      hiddenPrice: null,
      documents: [],
      recommendedForChildren: null,
      tenantNumberHousing: null,
    },
    images: [
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/images/c0.jpg',
        isMain: true,
        label: 'living',
        order: 1,
      },
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/images/c1.jpg',
        isMain: false,
        label: 'facade',
        order: 2,
      },
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/images/c2.jpg',
        isMain: false,
        label: 'facade',
        order: 3,
      },
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/images/c3.jpg',
        isMain: false,
        label: 'room',
        order: 4,
      },
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/plans/cc8.jpg',
        isMain: false,
        label: 'plan',
        order: 5,
      },
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/plans/cornella-parking.jpg',
        isMain: false,
        label: 'plan',
        order: 13,
      },
    ],
    operation: {
      depositMonths: 0,
      price: 405000,
      formalExpenses: 2350,
      taxes: 46575,
      mortgage: 5460,
      priceCommunity: null,
      priceToOwn: 405000,
      priceTransfer: null,
      priceParking: null,
      type: 'sale',
    },
    videos: [],
    visibility: null,
    _id: '642d82d64282495ba53ec02c',
    titlePresentation: 'Es tiempo de disfrutar el día a día bien acompañado',
    subtitle: 'Gana espacio y comodidad sin dejar atrás a Barcelona',
    url: 'cornella-segundo-4a',
    descriptionsPresentation: [
      {
        language: 'spanish',
        text: 'Cornellá Central te presenta una oportunidad única: 8 viviendas de alto standing en Cornellá de Llobregat, una ubicación privilegiada cercana a Barcelona. Ideal para parejas y familias que buscan su primera casa, estas viviendas combinan lujo y comodidad en un entorno urbano.<br>Disfruta de espacios que brillan con luz natural y diseño moderno. Los dormitorios, amplios y bien distribuidos, incluyen vestidores y acceso directo a terrazas privadas, ofreciendo un refugio personal y tranquilo. La integración de tecnología domótica en cada vivienda asegura un hogar inteligente, cómodo y seguro.<br>El proyecto, limitado a ocho unidades excepcionales, cuenta con jardín, terraza o balcón en cada hogar, doce amplias plazas de aparcamiento con tomas eléctricas, trastero, y como punto culminante, una piscina y jardín comunitarios. Un oasis de tranquilidad y comodidad, a pocos minutos del vibrante corazón de Barcelona.',
      },
    ],
    details: [
      'Diseño moderno y funcional con los mejores acabados',
      'Piscina privada exclusiva y zonas verdes',
      'Conectividad y accesibilidad con transporte público a tu alcance',
    ],
    environment: [
      {
        title: 'La cara residencial y familiar del sur de Barcelona',
        subtitle: 'Con una alta oferta de servicios y ocio',
        key1: 'Excelentes y variadas opciones educativas cercanas',
        key2: 'Áreas verdes para actividades recreativas y deporte',
        key3: 'Transporte público o privado: acceso rápido a Barcelona y Aeropuerto',
        description:
          'Cornellà Central, en Cornellà de Llobregat cerca de Barcelona, es un barrio dinámico y bien equipado, perfecto para parejas de 35 a 40 años que buscan su primera casa. Este área combina el encanto residencial con la conveniencia de accesos a servicios urbanos, ideal para quienes buscan viviendas de calidad en un entorno agradable sin sobrepasar presupuesto habitacional.<br>El barrio ofrece varias opciones educativas, desde colegios públicos hasta privados, destacando por su compromiso con una educación integral. Su cercanía a Barcelona facilita el acceso a instituciones de educación superior.<br>En términos de salud, Cornellà Central cuenta con diversos centros médicos y clínicas, y el Hospital de Cornellà cercano asegura una atención médica de calidad. Para el ocio, el barrio posee parques y áreas de juego, perfectos para familias y entusiastas del deporte, ofreciendo un respiro de la vida urbana.<br>La infraestructura de transporte es excelente, con conexiones eficientes de metro y tren hacia Barcelona, y proximidad al Aeropuerto de Barcelona-El Prat, facilitando viajes nacionales e internacionales.<br>Cornellà Central también brinda una amplia gama de opciones para compras y entretenimiento, desde tiendas locales hasta grandes supermercados y una diversidad de restaurantes y cafés.',
      },
    ],
    icons: [
      {
        src: iconM2,
        description: '91',
      },
      {
        src: iconBedroom,
        description: '2',
      },
      {
        src: iconShower,
        description: '3',
      },
    ],
    createdAt: '2023-04-05T14:00:00.000Z',
    updatedAt: '2023-04-05T15:52:52.251Z',
  },
  {
    owner: {
      avatarURL: null,
      email: 'ferran.mtinez@gmail.com',
      isEmailVerificated: false,
      firstName: 'Ferrán',
      lastName: 'Martinez',
      _id: '642d731d4282495ba53ec026',
      createdAt: '2023-05-05T12:54:00.773Z',
      updatedAt: '2023-04-05T12:54:00.773Z',
      resourcePath: '/agent/642d731d4282495ba53ec026',
      isViewer: null,
    },
    title: 'Vivienda Cornella Bajo 1A',
    place: {
      placeId: 'ChIJE7XVlVmZpBIRbIGQtrDxLQQ',
      formattedAddress:
        'Carrer del Cinema Bel, 17, 08940 Cornellà de Llobregat, Barcelona',
      geometry: {
        location: {
          lat: 41.3541068,
          lng: 2.0700667,
        },
        viewport: {
          northeast: {
            lat: 41.3541068,
            lng: 2.0700667,
          },
          southwest: {
            lat: 41.3541068,
            lng: 2.0700667,
          },
        },
      },
      name: 'Carrer del Cinema 17',
    },
    commission: 3,
    archiveIntern: [
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/documents/calidad-cornella.pdf',
        typology: 'saleFlyer',
        _id: '65b2210a41066712b386ebd0',
      },
    ],
    address: {
      visibility: 'full',
      streetName: 'Carrer del Cinema',
      streetNumber: '17',
      block: '',
      floor: 'bj',
      stair: '',
      door: '1A',
      urbanization: 'Cornellà Central',
      postalCode: '08940',
      town: 'Cornellà de Llobregat, Barcelona',
      nsiCode: null,
      country: 'Spain',
      coordinatesPrecision: 'exact',
      coordinatesLatitude: 41.3541068,
      coordinatesLongitude: 2.0700667,
    },
    contact: {
      name: 'Ferran Martinez',
      email: 'ferran.mtinez@gmail.com',
      primaryPhonePrefix: '',
      primaryPhoneNumber: '',
      secondaryPhonePrefix: '',
      secondaryPhoneNumber: '',
    },
    descriptions: [
      {
        language: 'spanish',
        text: 'Cornellá Central es una exclusiva promoción de 8 viviendas de alto standing situadas en el centro de Cornellá` de Llobregat, una de las ciudades mejor conectadas a la metrópolis de Barcelona.<br><br>Viviendas amplias, luminosas y exteriores. Con dormitorios de grandes dimensiones que cuentan con vestidor y salida directa a terraza.<br><br>Cada unidad está equipada de elementos domóticos con el fin de proveer a sus habitantes confort y seguridad. A su vez el aparcamiento cuenta con tomas eléctricas individuales y plazas de aparcamiento para motos.<br><br>El proyecto consta de ocho fantásticas unidades, con jardín, terraza y/o balcón, además de doce amplias plazas de aparcamiento, trastero y una piscina y jardín comunitarios, un oasis a pocos minutos de Barcelona.',
      },
    ],
    features: {
      access24h: false,
      accessControl: true,
      allowPets: true,
      areaBuildable: null,
      areaConstructed: 103,
      areaHeight: null,
      areaPlot: null,
      areaTradableMinimum: null,
      areaUsable: 103,
      bathroomAdapted: true,
      bathroomInside: true,
      bathroomNumber: 2,
      bathroomType: 'fullEquiped',
      bedroomNumber: 2,
      buildingAdapted: true,
      builtYear: 2024,
      chimney: false,
      classificationBlocks: true,
      classificationChalet: false,
      classificationCommercial: false,
      classificationHotel: false,
      classificationIndustrial: false,
      classificationOffice: false,
      classificationOther: false,
      classificationPublic: false,
      conditionedAir: true,
      conditionedAirType: 'cold_heat',
      conservation: 'new_development_finished',
      doorman: true,
      duplex: false,
      emergencyExit: false,
      emergencyLights: false,
      energyCertificateLaw: null,
      energyCertificatePerformance: 2.34,
      energyCertificateRating: 'B',
      energyCertificateType: 'completed',
      energyCertificateEmissionsRating: 'B',
      energyCertificateEmissionsValue: 2.34,
      equippedKitchen: true,
      extinguishers: false,
      facadeArea: null,
      fireDetectors: null,
      fireDoors: null,
      floorsBuildable: null,
      floorsBuilding: 3,
      floorsInTop: false,
      floorsProperty: 1,
      garden: true,
      handicapAdaptedAccess: true,
      handicapAdaptedUse: true,
      heating: true,
      heatingType: 'individualAirConditioningHeatPump',
      hotWater: true,
      lastActivity: null,
      liftAvailable: true,
      liftNumber: 1,
      loadingDock: null,
      bridgeCrane: null,
      locatedAtCorner: false,
      nearestLocationKm: null,
      officeBuilding: null,
      orientationEast: true,
      orientationNorth: true,
      orientationSouth: true,
      orientationWest: false,
      parkingAvailable: true,
      parkingAutomaticDoor: true,
      parkingPlaceCovered: true,
      parkingType: 'depot',
      garageCapacityType: null,
      parkingSpacesNumber: 1,
      penthouse: false,
      pool: true,
      builtProperties: 99,
      rooms: 3,
      roomsSplitted: 'withWalls',
      security24h: false,
      securityAlarm: false,
      securityDoor: false,
      securityPersonnel: false,
      securitySystem: false,
      smokeExtraction: true,
      sprinklers: null,
      storage: true,
      studio: false,
      suspendedCeiling: null,
      suspendedFloor: false,
      propertyTenants: false,
      terrace: true,
      ubication: 'unknown',
      utilitiesElectricity: true,
      utilitiesNaturalGas: false,
      utilitiesRoadAccess: true,
      utilitiesSewerage: true,
      utilitiesSidewalk: true,
      utilitiesStreetLighting: true,
      utilitiesWater: true,
      wardrobes: true,
      balcony: true,
      windowsDouble: true,
      windowsLocation: 'external',
      windowsShop: 1,
      newDevelopmentType: 'new_building',
      builtPhase: 'keydelivery',
      availabilityHour: 'business_hour',
      newDevelopmentName: 'Cornellà Central',
      finished: true,
      startDate: null,
      keyDeliveryYear: 2022,
      keyDeliveryMonth: 1,
      onSite: true,
      mortgageStateSubsidized: null,
      mortgageBankName: null,
      mortgagePercentage: null,
      mortgageEntryPercentage: null,
      mortgageLettersPercentage: null,
      mortgageInterestRate: null,
      mortgageYears: null,
      windowsNumber: 3,
      accessType: 'urban',
      tenantNumber: null,
      smokingAllowed: null,
      minTenantAge: null,
      maxTenantAge: null,
      couplesAllowed: null,
      bedType: 'none',
      minimalStay: null,
      windowView: 'street_view',
      ownerLiving: false,
      availableFrom: null,
      tenantGender: null,
      internetAvailable: null,
      houseKeeper: null,
      cupboard: true,
      privateBathroom: true,
      genderPreference: null,
      occupation: null,
      lgtbFriendly: null,
      childrenAllowed: null,
      occupiedNow: false,
      tenantWorkers: null,
      tenantStudents: null,
      minNewTenantAge: null,
      maxNewTenantAge: null,
      exteriorAccessibility: true,
      interiorAccessibility: true,
      lifeStyle: null,
      priceReferenceIndex: null,
      auction: null,
      minAuctionBidIncrement: null,
      auctionDeposit: null,
      auctionDate: null,
      auctionTribunal: null,
      currentOccupation: 'free',
      gardenType: 'private',
      parkingSpaceCapacity: null,
      parkingSpaceArea: null,
      outdoorParkingSpace: null,
      outdoorParkingSpaceType: null,
      outdoorParkingSpaceNumber: null,
      hiddenPrice: null,
      documents: [],
      recommendedForChildren: null,
      tenantNumberHousing: null,
    },
    images: [
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/images/c0.jpg',
        isMain: true,
        label: 'living',
        order: 1,
      },
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/images/c1.jpg',
        isMain: false,
        label: 'facade',
        order: 2,
      },
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/plans/cornella-parking.jpg',
        isMain: false,
        label: 'plan',
        order: 13,
      },
    ],
    titlePresentation: 'El siguiente paso a un mayor confort',
    subtitle: 'Disfruta de la exclusividad y tranquilidad cerca de Barcelona',
    url: 'cornella-bajo-1a',
    descriptionsPresentation: [
      {
        language: 'spanish',
        text: 'Cornellá Central representa la oportunidad perfecta para familias como la tuya, que buscan dar un paso adelante en calidad de vida. Ubicadas en el centro de Cornellá de Llobregat, estas 8 viviendas de alto standing combinan lujo y comodidad, a pocos minutos de Barcelona.<br>Cada hogar se extiende en espacios amplios y con mucha luz natural, ideales para el disfrute familiar. Los dormitorios, de grandes dimensiones, incluyen vestidores y terrazas privadas, ofreciendo el espacio y la privacidad que tu familia merece.<br>Equipadas con tecnología domótica, estas viviendas garantizan un ambiente de confort y seguridad de vanguardia. Además, el amplio estacionamiento cuenta con tomas eléctricas y plazas para motos, pensando en todas tus necesidades.<br>El proyecto se distingue por su exclusividad gracias a una piscina y jardín comunitarios que convierten esta propiedad en un tranquilo refugio familiar, cerca del dinamismo de Barcelona.',
      },
    ],
    details: [
      'Amplias y confortables estancias con materiales de alta calidad',
      'Piscina privada exclusiva y zonas verdes',
      'Ubicación ideal con fácil acceso al transporte público y autopistas',
    ],
    environment: [
      {
        title: 'Eleva tu estilo de vida en Cornellà',
        subtitle: 'Un entorno con todo lo que tu familia necesita',
        key1: 'Excelentes y variadas opciones educativas cercanas',
        key2: 'Áreas verdes para actividades recreativas y deporte',
        key3: 'Transporte público o privado: acceso rápido a Barcelona y Aeropuerto',
        description:
          'Cornellà Central, situado en Cornellà de Llobregat y cercano a Barcelona, es un barrio que combina armoniosamente la vida residencial con la comodidad urbana, perfecto para familias que buscan mejorar su hogar. Este barrio dinámico no solo ofrece encanto comunitario sino también acceso fácil a una variedad de servicios y comodidades urbanas.<br>Importantes por su calidad y variedad, las opciones educativas en Cornellà Central incluyen colegios públicos y privados, cubriendo desde la educación infantil hasta la secundaria, además de estar cerca de instituciones de educación superior en Barcelona.<br>La atención médica en el barrio es de primer nivel, con centros de salud y clínicas que proporcionan servicios médicos generales y especializados, y el Hospital de Cornellà como referencia para necesidades más complejas.<br>Los espacios verdes y áreas recreativas son abundantes, proporcionando un ambiente sereno para actividades al aire libre, esenciales para el bienestar familiar. La infraestructura de transporte del barrio, incluyendo conexiones de metro y tren, y la proximidad al Aeropuerto de Barcelona-El Prat, facilitan tanto los desplazamientos diarios como los viajes nacionales e internacionales.<br>Para compras y entretenimiento, Cornellà Central ofrece desde tiendas locales hasta grandes supermercados, así como una variedad de restaurantes y cafés que satisfacen diversos gustos culinarios.',
      },
    ],
    icons: [
      {
        src: iconM2,
        description: '103',
      },
      {
        src: iconBedroom,
        description: '2',
      },
      {
        src: iconShower,
        description: '2',
      },
    ],
    operation: {
      depositMonths: 0,
      price: 425000,
      formalExpenses: 2350,
      taxes: 48875,
      mortgage: 5700,
      priceCommunity: null,
      priceToOwn: 425000,
      priceTransfer: null,
      priceParking: null,
      type: 'sale',
    },
    videos: [],
    visibility: null,
    _id: '642d82d64282495ba53ec028',
    createdAt: '2023-04-05T14:00:00.000Z',
    updatedAt: '2023-04-05T15:52:52.251Z',
  },
  {
    owner: {
      avatarURL: null,
      email: 'ferran.mtinez@gmail.com',
      isEmailVerificated: false,
      firstName: 'Ferrán',
      lastName: 'Martinez',
      _id: '642d731d4282495ba53ec026',
      createdAt: '2023-05-05T12:54:00.773Z',
      updatedAt: '2023-04-05T12:54:00.773Z',
      resourcePath: '/agent/642d731d4282495ba53ec026',
      isViewer: null,
    },
    title: 'Vivienda Cornella Bajo 2A',
    place: {
      placeId: 'ChIJE7XVlVmZpBIRbIGQtrDxLQQ',
      formattedAddress:
        'Carrer del Cinema Bel, 17, 08940 Cornellà de Llobregat, Barcelona',
      geometry: {
        location: {
          lat: 41.3541068,
          lng: 2.0700667,
        },
        viewport: {
          northeast: {
            lat: 41.3541068,
            lng: 2.0700667,
          },
          southwest: {
            lat: 41.3541068,
            lng: 2.0700667,
          },
        },
      },
      name: 'Carrer del Cinema 17',
    },
    commission: 3,
    archiveIntern: [
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/documents/calidad-cornella.pdf',
        typology: 'saleFlyer',
        _id: '65b2201341066712b386ebcd',
      },
    ],
    address: {
      visibility: 'full',
      streetName: 'Carrer del Cinema',
      streetNumber: '17',
      block: '',
      floor: 'bj',
      stair: '',
      door: '2A',
      urbanization: 'Cornellà Central',
      postalCode: '08940',
      town: 'Cornellà de Llobregat, Barcelona',
      nsiCode: null,
      country: 'Spain',
      coordinatesPrecision: 'exact',
      coordinatesLatitude: 41.3541068,
      coordinatesLongitude: 2.0700667,
    },
    contact: {
      name: 'Ferran Martinez',
      email: 'ferran.mtinez@gmail.com',
      primaryPhonePrefix: '',
      primaryPhoneNumber: '',
      secondaryPhonePrefix: '',
      secondaryPhoneNumber: '',
    },
    descriptions: [
      {
        language: 'spanish',
        text: 'Cornellá Central es una exclusiva promoción de 8 viviendas de alto standing situadas en el centro de Cornellá` de Llobregat, una de las ciudades mejor conectadas a la metrópolis de Barcelona.<br><br>Viviendas amplias, luminosas y exteriores. Con dormitorios de grandes dimensiones que cuentan con vestidor y salida directa a terraza.<br><br>Cada unidad está equipada de elementos domóticos con el fin de proveer a sus habitantes confort y seguridad. A su vez el aparcamiento cuenta con tomas eléctricas individuales y plazas de aparcamiento para motos.<br><br>El proyecto consta de ocho fantásticas unidades, con jardín, terraza y/o balcón, además de doce amplias plazas de aparcamiento, trastero y una piscina y jardín comunitarios, un oasis a pocos minutos de Barcelona.',
      },
    ],
    features: {
      access24h: false,
      accessControl: true,
      allowPets: true,
      areaBuildable: null,
      areaConstructed: 61,
      areaHeight: null,
      areaPlot: null,
      areaTradableMinimum: null,
      areaUsable: 61,
      bathroomAdapted: true,
      bathroomInside: true,
      bathroomNumber: 1,
      bathroomType: 'fullEquiped',
      bedroomNumber: 2,
      buildingAdapted: true,
      builtYear: 2024,
      chimney: false,
      classificationBlocks: true,
      classificationChalet: false,
      classificationCommercial: false,
      classificationHotel: false,
      classificationIndustrial: false,
      classificationOffice: false,
      classificationOther: false,
      classificationPublic: false,
      conditionedAir: true,
      conditionedAirType: 'cold_heat',
      conservation: 'new_development_finished',
      doorman: true,
      duplex: false,
      emergencyExit: false,
      emergencyLights: false,
      energyCertificateLaw: null,
      energyCertificatePerformance: 2.34,
      energyCertificateRating: 'B',
      energyCertificateType: 'completed',
      energyCertificateEmissionsRating: 'B',
      energyCertificateEmissionsValue: 2.34,
      equippedKitchen: true,
      extinguishers: false,
      facadeArea: null,
      fireDetectors: null,
      fireDoors: null,
      floorsBuildable: null,
      floorsBuilding: 3,
      floorsInTop: false,
      floorsProperty: 1,
      garden: true,
      handicapAdaptedAccess: true,
      handicapAdaptedUse: true,
      heating: true,
      heatingType: 'individualAirConditioningHeatPump',
      hotWater: true,
      lastActivity: null,
      liftAvailable: true,
      liftNumber: 1,
      loadingDock: null,
      bridgeCrane: null,
      locatedAtCorner: false,
      nearestLocationKm: null,
      officeBuilding: null,
      orientationEast: true,
      orientationNorth: true,
      orientationSouth: true,
      orientationWest: false,
      parkingAvailable: true,
      parkingAutomaticDoor: true,
      parkingPlaceCovered: true,
      parkingType: 'depot',
      garageCapacityType: null,
      parkingSpacesNumber: 1,
      penthouse: false,
      pool: true,
      builtProperties: 99,
      rooms: 2,
      roomsSplitted: 'withWalls',
      security24h: false,
      securityAlarm: false,
      securityDoor: false,
      securityPersonnel: false,
      securitySystem: false,
      smokeExtraction: true,
      sprinklers: null,
      storage: true,
      studio: false,
      suspendedCeiling: null,
      suspendedFloor: false,
      propertyTenants: false,
      terrace: true,
      ubication: 'unknown',
      utilitiesElectricity: true,
      utilitiesNaturalGas: false,
      utilitiesRoadAccess: true,
      utilitiesSewerage: true,
      utilitiesSidewalk: true,
      utilitiesStreetLighting: true,
      utilitiesWater: true,
      wardrobes: true,
      balcony: true,
      windowsDouble: true,
      windowsLocation: 'external',
      windowsShop: 1,
      newDevelopmentType: 'new_building',
      builtPhase: 'keydelivery',
      availabilityHour: 'business_hour',
      newDevelopmentName: 'Cornellà Central',
      finished: true,
      startDate: null,
      keyDeliveryYear: 2022,
      keyDeliveryMonth: 1,
      onSite: true,
      mortgageStateSubsidized: null,
      mortgageBankName: null,
      mortgagePercentage: null,
      mortgageEntryPercentage: null,
      mortgageLettersPercentage: null,
      mortgageInterestRate: null,
      mortgageYears: null,
      windowsNumber: 3,
      accessType: 'urban',
      tenantNumber: null,
      smokingAllowed: null,
      minTenantAge: null,
      maxTenantAge: null,
      couplesAllowed: null,
      bedType: 'none',
      minimalStay: null,
      windowView: 'street_view',
      ownerLiving: false,
      availableFrom: null,
      tenantGender: null,
      internetAvailable: null,
      houseKeeper: null,
      cupboard: true,
      privateBathroom: true,
      genderPreference: null,
      occupation: null,
      lgtbFriendly: null,
      childrenAllowed: null,
      occupiedNow: false,
      tenantWorkers: null,
      tenantStudents: null,
      minNewTenantAge: null,
      maxNewTenantAge: null,
      exteriorAccessibility: true,
      interiorAccessibility: true,
      lifeStyle: null,
      priceReferenceIndex: null,
      auction: null,
      minAuctionBidIncrement: null,
      auctionDeposit: null,
      auctionDate: null,
      auctionTribunal: null,
      currentOccupation: 'free',
      gardenType: 'private',
      parkingSpaceCapacity: null,
      parkingSpaceArea: null,
      outdoorParkingSpace: null,
      outdoorParkingSpaceType: null,
      outdoorParkingSpaceNumber: null,
      hiddenPrice: null,
      documents: [],
      recommendedForChildren: null,
      tenantNumberHousing: null,
    },
    images: [
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/images/c0.jpg',
        isMain: true,
        label: 'living',
        order: 1,
      },
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/images/c1.jpg',
        isMain: false,
        label: 'facade',
        order: 2,
      },
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/images/c2.jpg',
        isMain: false,
        label: 'facade',
        order: 3,
      },
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/images/c3.jpg',
        isMain: false,
        label: 'room',
        order: 4,
      },
      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/plans/cc2.jpg',
        isMain: false,
        label: 'plan',
        order: 6,
      },

      {
        url: 'https://nexun-public-data.s3.eu-west-1.amazonaws.com/properties/plans/cornella-parking.jpg',
        isMain: false,
        label: 'plan',
        order: 13,
      },
    ],
    titlePresentation: 'Maximiza tu decisión de inversión inmobiliaria',
    subtitle:
      'Diseño moderno y acogedor en un barrio que se consolida a pocos minutos de Barcelona',
    url: 'cornella-bajo-2a-inversion',
    descriptionsPresentation: [
      {
        language: 'spanish',
        text: 'Cornellá Central es la elección perfecta para aquellos con una visión de futuro y un gusto por lo excepcional. Situadas en el centro de Cornellá de Llobregat, estas 8 residencias de alto standing te ofrecen una vida de lujo y comodidad, a un paso de Barcelona.<br>Cada vivienda, amplia y con luz natural, cuenta con dormitorios espaciosos que incluyen vestidores y terrazas privadas, ideales para adaptarse a diferentes estilos de vida y necesidades futuras. La tecnología domótica avanzada asegura un ambiente de confort y seguridad insuperable.<br>Pensando en la sostenibilidad y conveniencia, el aparcamiento para doce vehículos incluye tomas eléctricas y plazas para motos y trastero.<br>Completa este idílico entorno una piscina y jardín comunitarios, ofreciéndote un retiro tranquilo y sereno, en una ubicación privilegiada cerca de Barcelona, perfecta para disfrutar hoy y una sabia inversión para el futuro.<br><br><br>Cornellá Central es la elección perfecta para aquellos con una visión de futuro y un gusto por lo excepcional. Situadas en el centro de Cornellá de Llobregat, estas 8 residencias de alto standing te ofrecen una vida de lujo y comodidad, a un paso de Barcelona.<br>Cada vivienda, amplia y con luz natural, cuenta con dormitorios espaciosos que incluyen vestidores y terrazas privadas, ideales para adaptarse a diferentes estilos de vida y necesidades futuras. La tecnología domótica avanzada asegura un ambiente de confort y seguridad insuperable.<br>Pensando en la sostenibilidad y conveniencia, el aparcamiento para doce vehículos incluye tomas eléctricas y plazas para motos y trastero.<br>Completa este idílico entorno una piscina y jardín comunitarios, ofreciéndote un retiro tranquilo y sereno, en una ubicación privilegiada cerca de Barcelona, perfecta para disfrutar hoy y una sabia inversión para el futuro.<br>',
      },
    ],
    details: [
      'Espacios amplios y luminosos, perfectos para diferentes estilos de vida',
      'Piscina privada que ofrece un elemento diferenciador en el barrio',
      'Ubicación estratégica con acceso directo a transporte y conexiones viales',
    ],
    environment: [
      {
        title: 'Cornellà: un barrio para inversión residencial',
        subtitle: 'Un enclave que se consolida por su cercanía a Barcelona',
        key1: 'Tranquilidad y amplia gama de servicios urbanos',
        key2: 'Transporte público o privado: acceso rápido a Barcelona y Aeropuerto',
        key3: 'Áreas verdes para actividades recreativas y deporte',
        description:
          'Cornellà Central, ubicado cerca de Barcelona, es la elección perfecta para quienes valoran una inversión segura y un futuro hogar confortable. Este barrio consolidado es un equilibrio ideal entre una tranquila vida residencial y la conveniencia urbana. Su infraestructura de transporte eficiente, con conexiones de metro y tren hacia Barcelona y cercanía al Aeropuerto de Barcelona-El Prat, facilita tanto los desplazamientos cotidianos como los viajes más lejanos.<br>En cuanto a la salud, la zona está bien equipada con centros médicos y clínicas, destacando el Hospital de Cornellà por sus amplios servicios. Aunque hay disponibles opciones educativas de calidad, el corazón del barrio radica en su fuerte sentido comunitario y sus comodidades.<br>Los espacios verdes y áreas recreativas de Cornellà Central enriquecen la vida de sus habitantes, ofreciendo lugares perfectos para el descanso, el ejercicio y la diversión al aire libre. La oferta de compras y ocio es variada, abarcando desde tiendas locales hasta grandes supermercados y una diversa gama gastronómica.',
      },
    ],
    icons: [
      {
        src: iconM2,
        description: '61',
      },
      {
        src: iconBedroom,
        description: '2',
      },
      {
        src: iconShower,
        description: '1',
      },
    ],
    operation: {
      depositMonths: 0,
      price: 425000,
      formalExpenses: 2350,
      taxes: 48875,
      mortgage: 5700,
      priceCommunity: null,
      priceToOwn: 425000,
      priceTransfer: null,
      priceParking: null,
      type: 'sale',
    },
    videos: [],
    visibility: null,
    _id: '642d82d64282495ba53ec029',
    createdAt: '2023-04-05T14:00:00.000Z',
    updatedAt: '2023-04-05T15:52:52.251Z',
  },
];
