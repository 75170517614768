import { Routes, Route, Outlet } from 'react-router-dom';
import { BuyerLayout, RequireAuth, RequireVerificatedEmail } from 'components';
import NotFoundRoute from 'routes/notFound.route';
import VerifyEmailRoute from 'routes/verifyEmail.route';
import HomeRoute from './home.route';
import VisitsRoutes from './visits';
import NotificationsRoute from './notifications';
import VisitBuyerView from 'routes/buyer/visits/visit';
import MyVisitsRoute from 'routes/buyer/visits/my-visits.route';

import LoginRoute from 'routes/login.route';
import RecoverPasswordRoute from 'routes/recover-password';
import PasswordResetRoute from 'routes/password-reset';
import SignupRoute from 'routes/buyer/signup.route';

export default function BuyerRoutes() {
  return (
    <Routes>
      <Route
        element={
          <RequireAuth isRequired={false}>
            <Outlet />
          </RequireAuth>
        }
      >
        <Route path="/login" element={<LoginRoute />} />
        <Route path="/recover-password" element={<RecoverPasswordRoute />} />
        <Route path="/password-reset" element={<PasswordResetRoute />} />
        <Route path="/signup/:visit" element={<SignupRoute />} />
      </Route>
      <Route
        element={
          <RequireAuth>
            <BuyerLayout />
          </RequireAuth>
        }
      >
        <Route
          path="/verify-email"
          element={
            <RequireVerificatedEmail isRequired={false}>
              <VerifyEmailRoute />
            </RequireVerificatedEmail>
          }
        />
        <Route
          element={
            <RequireVerificatedEmail>
              <Outlet />
            </RequireVerificatedEmail>
          }
        >
          <Route path="*" element={<NotFoundRoute />} />
          <Route path="/" element={<HomeRoute />} />

          <Route path="/visits" element={<VisitsRoutes />}>
            <Route
              path="*"
              element={
                <div className="container-md width-full">
                  <div className="p-responsive py-6 color-bg-default rounded-2 border">
                    <NotFoundRoute />
                  </div>
                </div>
              }
            />
            <Route path="/visits" element={<MyVisitsRoute />} />
            <Route path="/visits/:visitId" element={<VisitBuyerView />} />
          </Route>
          <Route path="/notifications" element={<NotificationsRoute />} />
        </Route>
      </Route>
    </Routes>
  );
}
