import { useEffect, useRef, useState } from 'react';
import { HomeFooter, NexunLogo } from 'components';
import {
  FormControl,
  TextInput,
  Box,
  Button,
  ThemeProvider,
} from '@primer/react';
import { Link, NavLink } from 'react-router-dom';
import { useAuth } from 'context/auth.context';

export default function LoginRoute() {
  const auth = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [actorType, setActorType] = useState(
    window.location.hostname.split('.')[0]
      ? window.location.hostname.split('.')[0].toUpperCase()
      : null,
  );
  const subdomain = useRef(window.location.hostname.split('.')[0]);

  useEffect(() => {
    switch (actorType) {
      case 'AGENT':
        // redirect to agent subdomain login
        if (subdomain.current !== 'agent') {
          window.location =
            process.env.NODE_ENV !== 'production'
              ? 'http://agent.localhost:3000/login'
              : 'https://agent.nexun.es/login';
        }
        break;
      case 'OWNER':
        // redirect to owner subdomain login
        if (subdomain.current !== 'owner') {
          window.location =
            process.env.NODE_ENV !== 'production'
              ? 'http://owner.localhost:3000/login'
              : 'https://owner.nexun.es/login';
        }
        break;
      case 'BUYER':
        // redirect to buyer subdomain login
        if (subdomain.current !== 'buy') {
          window.location =
            process.env.NODE_ENV !== 'production'
              ? 'http://buy.localhost:3000/login'
              : 'https://buy.nexun.es/login';
        }
        break;
      default:
        break;
    }
  }, [actorType]);

  const handleLogin = (event) => {
    event.preventDefault();
    auth.signin(email, password, actorType === 'BUY' ? 'BUYER' : actorType);
  };

  return (
    <ThemeProvider>
      <div className="d-flex flex-column flex-1 color-bg-subtle">
        <div className="container-md p-responsive width-full d-flex flex-column flex-items-center py-8">
          <div className="d-flex flex-column flex-items-center flex-justify-center text-center">
            <NavLink to="/" className="color-fg-default no-underline">
              <NexunLogo size="large" onlyIcon />
            </NavLink>
            <h1 className="h3 text-center mt-3">Iniciar sesión en nexun</h1>
          </div>
          <div className="color-bg-default rounded-2 color-shadow-medium mt-4">
            <div className="p-4">
              <form onSubmit={handleLogin}>
                <Box display="grid" gridGap={3}>
                  <div>
                    <p className="text-bold">Plataforma</p>
                    <div className="radio-group">
                      <input
                        type="radio"
                        id="actor-type-agent"
                        className="radio-input"
                        name="actorType"
                        value="AGENT"
                        checked={actorType === 'AGENT'}
                        onChange={(e) => setActorType(e.target.value)}
                        required
                      />
                      <label className="radio-label" htmlFor="actor-type-agent">
                        Agentes
                      </label>
                      <input
                        type="radio"
                        id="actor-type-owner"
                        className="radio-input"
                        name="actorType"
                        value="OWNER"
                        checked={actorType === 'OWNER'}
                        onChange={(e) => setActorType(e.target.value)}
                        disabled
                        required
                      />
                      <label className="radio-label" htmlFor="actor-type-owner">
                        Propietarios
                      </label>
                      <input
                        type="radio"
                        id="actor-type-buyer"
                        className="radio-input"
                        name="actorType"
                        value="BUYER"
                        checked={actorType === 'BUYER' || actorType === 'BUY'}
                        onChange={(e) => setActorType('BUYER')}
                        required
                      />
                      <label className="radio-label" htmlFor="actor-type-buyer">
                        Compradores
                      </label>
                    </div>
                  </div>
                  <FormControl required>
                    <FormControl.Label>Correo electrónico</FormControl.Label>
                    <TextInput
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      size="large"
                      autoFocus
                      contrast
                      block
                    />
                  </FormControl>
                  <FormControl required>
                    <FormControl.Label>Contraseña</FormControl.Label>
                    <TextInput
                      type="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      size="large"
                      contrast
                      block
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    variant="primary"
                    size="large"
                    fontSize={6}
                    block
                  >
                    Iniciar sesión
                  </Button>
                </Box>
              </form>
              <p className="text-center mt-4">
                ¿Has olvidado tu contraseña?{' '}
                <Link to="/recover-password" className="Link">
                  Solicita una nueva
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
        <HomeFooter />
      </div>
    </ThemeProvider>
  );
}
