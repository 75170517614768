import { NavLink, Outlet } from 'react-router-dom';
import AdminHeader from 'components/AdminHeader';
import { useQuery } from '@apollo/client';
import { VISITS_COUNT_QUERY } from 'apollo/queries';

import {
  HomeIcon,
  PeopleIcon,
  OrganizationIcon,
  BriefcaseIcon,
} from '@primer/octicons-react';
import {
  AGENTS_COUNT_QUERY,
  BUYERS_COUNT_QUERY,
  OWNERS_COUNT_QUERY,
  PROPERTIES_COUNT_QUERY,
} from 'apollo/queries';

export default function AdminLayout() {
  const properties = useQuery(PROPERTIES_COUNT_QUERY);

  var agents = useQuery(AGENTS_COUNT_QUERY).data?.agentsCount;
  var buyers = useQuery(BUYERS_COUNT_QUERY).data?.buyersCount;
  var owners = useQuery(OWNERS_COUNT_QUERY).data?.ownersCount;
  var visits = useQuery(VISITS_COUNT_QUERY).data?.propertyVisitsCount;
  var users = agents + buyers + owners;

  return (
    <div className="d-flex flex-column flex-1 color-bg-inset">
      <AdminHeader />
      <main>
        <div className="color-bg-default border-bottom">
          <div className="container-lg p-responsive width-full">
            <nav className="UnderlineNav" style={{ marginBottom: -1 }}>
              <div className="UnderlineNav-body flex-1 flex-justify-center py-md-3">
                <NavLink to="/" className="UnderlineNav-item" end>
                  <HomeIcon className="mr-2" /> Home{' '}
                </NavLink>
                <NavLink to="/users/agents" className="UnderlineNav-item">
                  <PeopleIcon className="mr-2" /> Usuarios
                  <span className="Counter">{users}</span>
                </NavLink>
                <NavLink to="/properties" className="UnderlineNav-item">
                  <OrganizationIcon className="mr-2" /> Propiedades
                  <span className="Counter">
                    {properties.data?.propertiesCount
                      ? properties.data?.propertiesCount
                      : 0}
                  </span>
                </NavLink>
                <NavLink to="/visits" className="UnderlineNav-item">
                  <BriefcaseIcon className="mr-2" /> Visitas
                  <span className="Counter">{visits}</span>
                </NavLink>
              </div>
            </nav>
          </div>
        </div>
        <div className="container-lg p-responsive width-full d-flex flex-column flex-1 py-4 color-bg-subtle">
          <div className="d-flex flex-column flex-1 flex-self-top color-bg-subtle">
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  );
}
