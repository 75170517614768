import {
  MegaphoneIcon,
  FileBadgeIcon,
  SearchIcon,
  PaperclipIcon,
  LockIcon,
  EyeIcon,
  RocketIcon,
  KeyIcon,
} from '@primer/octicons-react';

export const Steps = [
  {
    logo: <MegaphoneIcon size={50} />,
    description:
      'Un agente se pondrá en contacto contigo para agendar una visita a tu propiedad',
  },
  {
    logo: <SearchIcon size={50} />,
    description:
      'Si quieres poner una oferta de compra, entra nuevamente en Nexun',
  },
  {
    logo: <FileBadgeIcon size={50} />,
    description:
      'Si tu oferta es aceptada por el propietario, te emitiremos un certificado de reserva',
  },
  {
    logo: <PaperclipIcon size={50} />,
    description:
      'Te enviaremos los datos para formalizar la compra de tu propiedad en una notaría',
  },
];

export const WhyNexun2 = [
  {
    logo: <LockIcon size={50} />,
    title: 'Confidencial',
    subtitle:
      'Tu controlas tus datos. No recibirás futuras e inoportunas llamadas',
  },
  {
    logo: <EyeIcon size={50} />,
    title: 'Transparencia',
    subtitle:
      'Todas las propiedades tienen fichas certificadas de sus características',
  },
  {
    logo: <KeyIcon size={50} />,
    title: 'Seguridad',
    subtitle:
      'Seremos garantes del cumplimiento de tu reserva y el pago de arras',
  },
  {
    logo: <RocketIcon size={50} />,
    title: 'Velocidad de venta',
    subtitle: 'Nuestra eficiencia permite costos de transacción más bajos',
  },
];
