import { NexunLogo } from 'components';
import { Link } from 'react-router-dom';

export default function NotAccessRoute() {
  return (
    <div className="d-flex flex-column flex-1 flex-items-center flex-justify-center">
      <div className="d-flex flex-items-center mb-3">
        <span className="h1-mktg color-fg-subtle">4</span>
        <NexunLogo className="mx-1" size="large" onlyIcon muted />
        <span className="h1-mktg color-fg-subtle">3</span>
      </div>
      <h3 className="h4-mktg mb-2">No tienes permisos</h3>
      <p className="color-fg-subtle">
        No tienes permiso para poder ver la página,{' '}
        <Link to="/">volver a la página de inicio</Link>.
      </p>
    </div>
  );
}
