import { useQuery } from '@apollo/client';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { CopyIcon, SearchIcon } from '@primer/octicons-react';
import { AGENTS_QUERY } from 'apollo/queries';
import { useState, useRef, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';

export default function AllUsersRoute() {
  const values = useQuery(AGENTS_QUERY, {
    variables: {
      sort: '_ID_DESC',
    },
  });
  const [gridRef, setGridRef] = useState(undefined);
  const [searchText, setSearchText] = useState('');
  const [dataSource, setDataSource] = useState(
    values === undefined ||
      values.data === undefined ||
      values.data.agents === undefined
      ? []
      : values.data.agents,
  );
  const { addToast } = useToasts();
  const emptyText = (
    <div className="blankslate">
      <h3 className="blankslate-heading">No hay registros disponibles</h3>
      <p>
        Es posible que no pueda acceder temporalmente a los datos. Por favor,
        espere unos minutos y vuelva a recargar la página.
      </p>
    </div>
  );
  const loadingText = (
    <h2>
      <span>Cargando datos</span>
      <span className="AnimatedEllipsis"></span>
    </h2>
  );

  useEffect(() => {
    if (values.data !== undefined) {
      setDataSource(values.data.agents);
    }
  }, [values.data]);

  const defaultColumns = [
    {
      name: 'firstName',
      defaultFlex: 1,
      header: 'Nombre',
    },
    { name: 'email', defaultFlex: 1, header: 'Email' },
    {
      name: '_id',
      defaultFlex: 1,
      header: 'Ver',
      render: ({ value }) => (
        <a href={`/users/agents/${value}`} className="Link">
          Ver perfil
        </a>
      ),
    },
    {
      name: 'createdAt',
      defaultFlex: 1,
      header: 'Registro',
      render: ({ value }) => {
        const date = new Date(value);
        return (
          <span>
            {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
          </span>
        );
      },
    },
    {
      name: 'isProfileFulfilled',
      defaultFlex: 1,
      header: 'Perfil',
      render: ({ value }) => (
        <span style={{ color: value === true ? 'green' : 'red' }}>
          {value === true ? 'Completado' : 'Incompleto'}
        </span>
      ),
    },
    {
      name: 'isAvailableFulfilled',
      defaultFlex: 1,
      header: 'Disponibilidad',
      render: ({ value }) => (
        <span style={{ color: value === true ? 'green' : 'red' }}>
          {value === true ? 'Completado' : 'Incompleto'}
        </span>
      ),
    },
    {
      name: 'isDocumentFulfilled',
      defaultFlex: 1,
      header: 'Documentación',
      render: ({ value }) => (
        <span style={{ color: value === true ? 'green' : 'red' }}>
          {value === true ? 'Completado' : 'Incompleto'}
        </span>
      ),
    },
    {
      name: 'isEmailVerificated',
      defaultFlex: 1,
      header: 'Email',
      render: ({ value }) => (
        <span style={{ color: value === true ? 'green' : 'red' }}>
          {value === true ? 'Verificado' : 'No verificado'}
        </span>
      ),
    },
    {
      name: 'hasAcceptedContract',
      defaultFlex: 1,
      header: 'Contrato',
      render: ({ value }) => (
        <span style={{ color: value === true ? 'green' : 'red' }}>
          {value === true ? 'Firmado' : 'No firmado'}
        </span>
      ),
    },
  ];

  const searchTextRef = useRef(searchText);
  searchTextRef.current = searchText;

  const [columns] = useState(defaultColumns);

  const onSearchChange = ({ target: { value } }) => {
    const visibleColumns = gridRef.current.visibleColumns;

    setSearchText(value);
    const newDataSource = values.data.agents.filter((p) => {
      return visibleColumns.reduce((acc, col) => {
        const v = (p[col.id] + '').toLowerCase(); // get string value
        return acc || v.indexOf(value.toLowerCase()) !== -1; // make the search case insensitive
      }, false);
    });

    setDataSource(newDataSource);
  };

  const gridStyle = { minHeight: 1100 };

  const [cellSelection, setCellSelection] = useState([]);
  const [textCopyable, setTextCopyable] = useState('');

  useEffect(() => {
    for (var key in cellSelection) {
      const id = key.split(',')[0];
      const option = key.split(',')[1];

      const data = dataSource.find((item) => item._id === id);

      if (data === undefined) {
        return;
      } else if (data[option] === undefined) {
        return;
      }

      const value = data[option];
      setTextCopyable(value);
    }
  }, [cellSelection, dataSource]);

  return (
    <>
      <div className="full-width mb-4 d-flex flex-justify-between">
        <label className="f4 col-7 input-group">
          <span className="input-group-button">
            <div className="btn" type="button" aria-label="Search">
              <SearchIcon />
            </div>
          </span>
          <input
            value={searchText}
            onChange={onSearchChange}
            className="form-control col-8"
            placeholder="Busca por nombre, correo o teléfono de contacto"
          />
        </label>
        <div className="input-group">
          <input className="form-control" value={textCopyable} disabled />
          <span className="input-group-button">
            <button
              className="btn "
              type="button"
              aria-label="Copy to clipboard"
              onClick={() => {
                navigator.clipboard.writeText(textCopyable);
                addToast('Copiado al portapapeles', {
                  appearance: 'success',
                  autoDismiss: true,
                });
              }}
            >
              <CopyIcon size={16} />
            </button>
          </span>
        </div>
      </div>
      <ReactDataGrid
        idProperty="_id"
        onReady={setGridRef}
        dataSource={dataSource ? dataSource : []}
        columns={columns}
        style={gridStyle}
        cellSelection={cellSelection}
        onCellSelectionChange={setCellSelection}
        multiSelect={false}
        emptyText={emptyText}
        loadingText={loadingText}
      />
    </>
  );
}
