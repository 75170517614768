import { XIcon } from '@primer/octicons-react';
import NexunLogo from 'components/NexunLogo';

export default function BoostrapModal({ setModal, title, body }) {
  return (
    <div className="modal">
      <div className="modal-content Box">
        <div className="Box-header d-flex flex-justify-between">
          <h3 className="tittle f2-light">{title}</h3>
          <button
            onClick={() => setModal(false)}
            style={{
              border: 'none',
              background: 'transparent',
            }}
          >
            <XIcon size={30} className="btnClose" />
          </button>
        </div>
        <div className="Box-body">{body}</div>
        <div className="Box-footer d-flex flex-justify-between">
          <NexunLogo className="logoNexun" />
          <button className="btn btn-danger" onClick={() => setModal(false)}>
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
}
