import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root, [data-color-mode=light][data-light-theme=light],
  [data-color-mode=dark][data-dark-theme=light] {
    --color-header-bg: #fff;
    --color-canvas-primary: #1c465e;
    --color-fg-primary: #1c465e;
    --text-primary-white: #FFFAFA;
    --text-primary-dark: #3B3F44;
    --color-primer-border-active: #1c465e;
    --color-header-text: #24292f;
  }
  .format {
    white-space: pre-wrap;
  }
  body {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
  }
  .Details:not(.Details--on) .Details-content--hidden-not-important {
    display: none;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .color-fg-primary {
    color: var(--color-fg-primary);
  }

  .color-bg-primary {
    background-color: var(--color-canvas-primary);
  }

  .color-bg-muted {
    background-color: var(--color-fg-subtle) !important;
  }

  /* .color-bg-subtle {
    background-color: var(--color-fg-subtle) !important;
  } */

  .mr-n8 {
    margin-right: -64px !important;
  }

  .gutter>[class*=col-].pr-8 {
    padding-right: 64px !important;
  }

  .nexun-btn {
    background-color: #1c465e !important;
  }

  .img-fluid {
    width: 100%;
    height: auto;
  }

  .subnav-search-icon {
    top: 8px;
  }

  .UnderlineNav-item.selected::after,
  .UnderlineNav-item[role=tab][aria-selected=true]::after,
  .UnderlineNav-item[aria-current]:not([aria-current=false])::after {
    bottom: calc(50% - 32px);
  }

  .border-0,
  .form-control.border-0:focus,
  .form-control.border-0:focus-visible,
  .form-select.border-0:focus,
  .form-select.border-0:focus-visible {
    border: 0 !important;
  }

  .Header-link {
    color: var(--color-fg-default);
  }

  .nexun-divider {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 30px;
    background-color: var(--color-neutral-emphasis-plus);
    transform: translate(-50%, -50%);
  }

  .color-bg-primary > .nexun-divider {
    background-color: var(--color-canvas-primary) !important;
  }

  .color-bg-muted > .nexun-divider {
    background-color: var(--color-fg-subtle) !important;
  }

  .color-bg-default > .nexun-divider {
    background-color: var(--color-canvas-default) !important;
  }

 /* TEXT COLORS */

  .light-text {
    color: var(--text-primary-white);
  }

  .dark-text {
    color: var(--text-primary-dark);
  }

  /*   */

  .hero-blur {
    position: relative;
  }

  .hero-blur::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    backdrop-filter: blur(0);
    background-blend-mode: color-dodge;
    box-shadow: 0 7px 15px rgb(0 0 0 / 20%);
  }

  .blurry {
    color: #24292f;
    mix-blend-mode: luminosity;
    backdrop-filter: blur(3px);
    background-color: rgb(255 255 255 / 30%);
    background-blend-mode: luminosity;
    border-radius: 8px;
    box-shadow: 0 7px 15px rgb(0 0 0 / 20%);
  }

  .no-blurry {
    position: relative;
    box-shadow: 0 10px 15px rgb(0 0 0 / 20%);
    z-index: 1;
  }

  .hero-blur .btn {
    color: var(--color-btn-outline-hover-text);
    background-color: var(--color-btn-outline-hover-bg);
    border-color: var(--color-btn-outline-hover-border);
    box-shadow: var(--color-btn-outline-hover-shadow),var(--color-btn-outline-hover-inset-shadow);
  }

  .hero-blur .btn:active {
    color: var(--color-btn-outline-selected-text);
    background-color: var(--color-btn-outline-selected-bg);
    border-color: var(--color-btn-outline-selected-border);
    box-shadow: var(--color-btn-outline-selected-shadow);
  }

  .btn-brand {
    color: var(--color-btn-primary-text);
    background-color: var(--color-neutral-emphasis-plus);
    border-color: var(--color-btn-primary-hover-border);
  }

  .btn-brand:hover {
    background-color: #1e2227;
    border-color: var(--color-btn-primary-hover-border);
  }

  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    border-radius: 8px;
  }

  .home-divider {
    height: 2px;
    background-color: #efefef;
  }

  .double-quotes {
    position: relative;
    margin: 16px;
    padding: 16px 32px;
  }

  .double-quotes::before,
  .double-quotes::after {
    position: absolute;
    top: 8px;
    color: #dcdcdc;
    font-size: 48px;
  }

  .double-quotes::before {
    content: '“';
    left: 0;
  }

  .double-quotes::after {
    content: '”';
    right: 0;
  }

  .gradient-text {
    background: linear-gradient(to right, #6bff67 0%, #67ffff 14%, #78A6FF 28%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

  .custom-shape-divider-top {
    line-height: 0;
  }

  .custom-shape-divider-top svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 100px;
  }

  .custom-shape-divider-top .shape-fill {
    fill: #FFFFFF;
  }

  .custom-shape-divider-bottom {
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 100px;
  }

  .custom-shape-divider-bottom .shape-fill {
    fill: #FFFFFF;
  }

  .slick-prev {
    left: -47px;
  }

  .slick-next {
    right: -47px;
  }

  .slick-prev,
  .slick-next {
    width: 42px;
    height: 42px;
  }

  .slick-prev:before,
  .slick-next:before {
    content: none;
  }

  .slick-prev .btn-octicon,
  .slick-next .btn-octicon {
    margin-left: -5px;
  }

  .home-footer .col-12 {
    border-color: #efefef !important;
  }

  .login-actor-selector {
    background-color: var(--color-canvas-inset);
    cursor: pointer;
  }

  .login-actor-selector:hover,
  .login-actor-selector:focus,
  .login-actor-selector.checked {
    background-color: var(--color-canvas-default);
  }

  // Animations
  .slide-x-left {
    opacity: 0;
    transform: translateX(-15px);
    transition: opacity .6s cubic-bezier(0.16, 1, 0.3, 1),transform .6s cubic-bezier(0.16, 1, 0.3, 1);
  }

  .slide-x-left.animate {
    opacity: 1;
    transform: translateX(0);
  }

  @media (min-width: 768px) {
    .Layout {
      --Layout-sidebar-width: 220px;
    }
  }

  @media (min-width: 1012px) {
    .Layout {
      --Layout-sidebar-width: 220px;
    }
  }
  @media screen and (max-width: 390px) {
    .radio-input {
      margin-left: 5px;
      padding-right: 0px;
    }
    input + .radio-label {
      padding-left: 23px;
      padding-right: 11px;
      max-width: 33%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .modal {
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Header */
.modal-header {
  padding: 2px 16px;
  color: white;
}

/* Modal Body */
.modal-body {padding: 2px 16px;}

/* Modal Content */
.modal-content {
  display: relative;
  margin: 20%;
}
@media screen and (max-width: 390px) {
  .modal-content {
  display: relative;
  margin: 2%;
}
}

@media screen and (max-width: 768px) {
  .modal-content {
  display: relative;
  margin: 10%;
}
}



`;
