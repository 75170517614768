import PropTypes from 'prop-types';
import {
  InfoIcon,
  CheckIcon,
  AlertIcon,
  StopIcon,
  XIcon,
} from '@primer/octicons-react';

const transitionStates = {
  entering: { transform: 'translate3d(120%, 0, 0)' },
  entered: { transform: 'translate3d(0, 0, 0)' },
  exiting: { transform: 'translate3d(120%, 0, 0)' },
  exited: { transform: 'translate3d(120%, 0, 0)' },
};

const appearances = {
  success: {
    icon: CheckIcon,
    className: 'Toast--success',
  },
  error: {
    icon: StopIcon,
    className: 'Toast--error',
  },
  warning: {
    icon: AlertIcon,
    className: 'Toast--warning',
  },
  info: {
    icon: InfoIcon,
    className: 'Toast--info',
  },
};

export default function Toast({
  appearance,
  autoDismiss,
  autoDismissTimeout,
  children,
  isRunning,
  onDismiss,
  onMouseEnter,
  onMouseLeave,
  transitionDuration,
  transitionState,
  ...rest
}) {
  const Icon = appearances[appearance].icon;
  return (
    <div
      {...rest}
      className={`Toast ${appearances[appearance].className}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        transitionProperty: 'transform',
        transitionDuration: `${transitionDuration}ms`,
        transitionTimingFunction: 'cubic-bezier(0.2, 0, 0, 1)',
        ...transitionStates[transitionState],
      }}
    >
      <span className="Toast-icon">
        <Icon />
      </span>
      <span className="Toast-content">{children}</span>
      {onDismiss && (
        <button
          type="button"
          className="Toast-dismissButton"
          onClick={onDismiss}
        >
          <XIcon />
        </button>
      )}
    </div>
  );
}

Toast.propTypes = {
  appearance: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  autoDismiss: PropTypes.bool,
  autoDismissTimeout: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  isRunning: PropTypes.bool,
  onDismiss: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  transitionDuration: PropTypes.number,
  transitionState: PropTypes.string,
};
