import React, { useState } from 'react';
import { HomeHeader, HomeFooter, ShapeDivider } from 'components';
import { Link } from 'react-router-dom';

import {
  PeopleIcon,
  GitCompareIcon,
  MegaphoneIcon,
} from '@primer/octicons-react';

import FirstImage from 'images/home/iStock-1364590774-Optimizada.jpg';
import SecondImage from 'images/home/Fotoagradecimientos.webp';
import FourthImage from 'images/home/test.png';

import Image1 from 'images/home/personas.png';
import Image3 from 'images/home/tecnologia.png';
import confianza from 'images/home/confianza.webp';

import Logo from 'images/home/logo.png';
import Fondo from 'images/home/fondo.png';

import { Box, Collapse } from '@mui/material';
import { techGuide } from 'utils/home-data';
import { ourPartners, ourTeam, newsletter } from 'utils/aboutus-data';

export default function AboutUsRoute() {
  const [expandedMision, setExpandedMision] = useState(false);
  const [expandedDeclaration, setExpandedDeclaration] = useState(false);
  const [expandedTransparency, setExpandedTransparency] = useState(false);
  const [expandedOptimization, setExpandedOptimization] = useState(false);

  return (
    <div className="d-flex flex-column flex-1 color-bg-default">
      <div className="container-lg width-full">
        <HomeHeader />
      </div>
      <div className="px-md-0">
        <div className="container-lg color-bg-subtle">
          <ShapeDivider placement="bottom" />
          <div className="width-full color-bg-default d-flex flex-items-center pb-5 py-md-8">
            <Box
              className="container-lg p-responsive width-full d-flex flex-items-center dark-text"
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 3,
              }}
            >
              <div className="container-lg color-bg-subtle">
                <div
                  className="d-flex flex-column flex-md-row flex-justify-center flex-items-center width-full color-bg-default"
                  style={{ gap: 10 }}
                >
                  <h2
                    className="h2 text-center text-md-left"
                    style={{ flex: 1, maxWidth: '400px' }}
                  >
                    <span className="text-bold">Nuestra misión</span>: Empoderar
                    una humanidad más justa
                  </h2>
                  <p className="f4" style={{ flex: 1 }}>
                    Mejorar tu experiencia es lo que nos motiva. Nos centramos
                    en revisar cada paso y detalle que hay en el camino de la
                    compra y venta de una propiedad. Sabemos la importancia que
                    tiene una propiedad, y el cariño y esfuerzo que entregan los
                    agentes para ayudar a propietarios y clientes.
                  </p>
                </div>
              </div>
            </Box>
          </div>
        </div>
        <img
          src={confianza}
          alt="Niñas caminando"
          className="width-full object-fit-cover"
          style={{ height: '250px' }}
        />
      </div>

      <div className="d-flex flex-column flex-md-row flex-items-center flex-justify-center width-full px-3 py-6 px-md-6 py-md-8">
        <div className="d-flex flex-column dark-text">
          <h3 className="h1">
            “En Nexun hemos asumido la responsabilidad de generar un nuevo
            standard en la transacción Inmobiliaria”
          </h3>
          <div className="d-flex flex-column pt-4">
            <p className="f4 text-bold">Eduardo Clavijo, Director Operación</p>
            <Link
              role="button"
              onClick={() => setExpandedDeclaration((prev) => !prev)}
              className="f4"
            >
              Leer la declaración completa aquí
            </Link>
          </div>
          <div className="f3 cursor-pointer user-select-none ">
            <Collapse
              in={expandedDeclaration}
              className="color-bg-default col-10 p-4 rounded-3 f4"
            >
              <p className="text-bold">Abril 2023</p>
              <p>
                Entendíamos que el proceso de compra, venta y alquiler de
                inmuebles puede ser complejo y desafiante. Por ello veíamos en
                la tecnología la herramienta idonea para poder abordar los retos
                que permitiría a toda la industria transitar de la situación
                actual a este nuevo modelo que soñamos y que hoy se llama nexun.
              </p>
              <p>
                Así y como parte de nuestro compromiso con la excelencia, nos
                esforzamos constantemente por mejorar y perfeccionar nuestra
                tecnología y procesos. Nuestro equipo de expertos trabaja
                incansablemente para investigar y desarrollar soluciones que se
                adapten a las necesidades cambiantes de la industria. Estamos
                atentos a las últimas tendencias y desarrollos tecnológicos, y
                nos enorgullecemos de ser pioneros en la adopción de
                innovaciones disruptivas en el real estate.
              </p>
              <p>
                Creemos firmemente que nuestro enfoque colaborativo y centrado
                en las personas es fundamental para garantizar que nuestra
                plataforma sea un catalizador para el crecimiento y el éxito de
                nuestros usuarios. Al brindarles las herramientas y el apoyo
                necesarios, estamos convencidos de que nexun puede empoderar a
                cada uno de ustedes para alcanzar sus objetivos y prosperar en
                el competitivo mundo del real estate.
              </p>
              <p>
                En los próximos años la sociedad y tecnología nos obligará a
                asumir grandes cambios. Por nuestra parte, miramos con optimismo
                esos nuevos escenarios, como una invitación a sacar lo mejor de
                nosotros y comenzar a creer de verdad en el trabajo en equipo. Y
                es en esta visión de futuro en que nexun está orientado a crear
                un ecosistema que integre ya no sólo a promotores, clientes y
                agentes sino también entregar valor a servicios complementarios
                como arquitectos, notarías, instituciones de financiación y un
                largo etc.
              </p>
              <p>
                Nos motiva pensar en el viaje que juntos tenemos por delante y
                convencido que mientras nuestra brujula esté orientada a la
                excelencia y la innovación podremos llegar con éxito y
                recompensa hasta el próximo puerto.
              </p>
              <p className="pb-4">
                Les deseo éxito y espero que sigan prosperando junto a nexun.
              </p>
              <p>Atentamente,</p>
              <p>Eduardo Clavijo Pujol</p>
              <p>Director de Operaciones</p>
              <p>NEXUN</p>
            </Collapse>
          </div>
        </div>
        <img
          src={SecondImage}
          alt="about-us"
          className="object-fit-cover rounded-3"
          width={300}
          height={280}
        />
      </div>

      <div
        className="p-responsive width-full d-flex flex-items-center py-5 light-text"
        style={{ backgroundColor: '#23233C' }}
      >
        <Box
          className="container-lg p-responsive width-full d-flex flex-items-center flex-justify-between"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 3,
          }}
        >
          <div
            className="d-flex flex-column flex-justify-center width-full"
            style={{ gap: 12 }}
          >
            <h3 className="h4 text-center text-md-left pl-md-8">
              Guiados por la tecnología
            </h3>

            <div
              className="d-flex flex-column flex-md-row flex-justify-around flex-items-center flex-md-items-start"
              style={{ gap: 18 }}
            >
              {techGuide.map((item) => {
                return (
                  <div
                    className="d-flex flex-column"
                    style={{ gap: 8, width: '260px' }}
                  >
                    <hr />
                    <h4 className="h3 text-center">{item.title}</h4>
                    <p className="f4">{item.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </Box>
      </div>

      <div className="p-responsive color-bg-default d-flex flex-items-center py-8">
        <Box
          className="container-lg p-responsive width-full d-flex flex-items-center dark-text"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 3,
          }}
        >
          <div className="width-full d-flex flex-column">
            <h3 className="text-center text-md-left h2 pl-md-8">
              Nuestros Partners
            </h3>
            <div
              className="d-flex flex-column flex-md-row flex-justify-between flex-items-center flex-items-md-left pt-6 px-4"
              style={{ gap: 18 }}
            >
              {ourPartners.map((partner) => {
                return (
                  <>
                    <img
                      src={partner.img}
                      alt={partner.alt}
                      width={95}
                      className="object-fit-contain object-center"
                    />
                  </>
                );
              })}
            </div>
          </div>
        </Box>
      </div>

      <div
        className="p-responsive width-full d-flex flex-items-center py-8"
        style={{ backgroundColor: '#23233C' }}
      >
        <Box
          className="container-lg p-responsive width-full d-flex color-fg-muted"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 3,
          }}
        >
          <div className="d-flex flex-column light-text">
            <h3 className="h2 pl-md-8 text-center text-md-left">
              Nuestro Equipo
            </h3>

            <div className="d-flex flex-column flex-md-row flex-md-wrap flex-items-center flex-justify-center pt-6">
              {ourTeam.map((member) => {
                return (
                  <div
                    className="d-flex flex-items-center flex-justify-center p-4"
                    style={{
                      gap: 32,
                      flexBasis: '45%',
                    }}
                  >
                    <img src={member.img} alt={member.name} />
                    <div>
                      <h3 className="h3">{member.name}</h3>
                      <h4 className="f4 text-bold">{member.title}</h4>
                      <h5 className="f4">{member.role}</h5>
                      <p>{member.description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Box>
      </div>

      <div className="p-responsive width-full pt-8 dark-text">
        <h2 className="text-center h2">
          No te pierdas las últimas novedades de nuestro blog y noticias
        </h2>
        <div className="d-flex flex-column flex-md-row flex-justify-between pt-6">
          {newsletter.map((section) => {
            return (
              <div className="col-12 col-md-3 pb-10 pb-md-0 d-flex flex-column text-center flex-items-center">
                {section.icon}
                <h1 className="h3 mb-3">{section.title}</h1>
                <p className="f4 mb-3">{section.description}</p>
                <a href={'http://blog.nexun.es/ '} className="f4">
                  más información
                </a>
              </div>
            );
          })}
        </div>
      </div>
      <HomeFooter />
    </div>
  );
}

{
  /* <h1 className="col-12 col-md-8 pb-6 h1">
          Estamos reinventando la forma en que se vende y compra una propiedad
        </h1>
        <p className="col-12 col-md-8 f3 pb-4">
          Mejorar tu experiencia es lo que nos motiva. Nos centramos en revisar
          cada paso y detalle que hay en el camino de la compra y venta de una
          propiedad. Sabemos la importancia que tiene una propiedad, y el cariño
          y esfuerzo que entregan los agentes para ayudar a propietarios y
          clientes. Queremos ser la herramienta que aúne a todos!
        </p>
        <Link role="button" onClick={() => setExpandedMision((prev) => !prev)}>
          La misión de nexun
        </Link> */
}

{
  /* <div className="f3 cursor-pointer user-select-none">
          <Collapse in={expandedMision}>
            <p>
              Reinventando la Industria del Real Estate a través de la Economía
              Colaborativa
            </p>
            <p>
              En nexun nos hemos propuesto transformar la industria del real
              estate, revolucionando los procesos de compra, venta y alquiler de
              inmuebles a través de una plataforma de economía colaborativa.
              Utilizando tecnologías avanzadas como blockchain, pasarelas de
              pago, contratos digitales y algoritmos de optimización, nuestro
              objetivo es ofrecer una experiencia de usuario excepcional,
              brindando transparencia, eficiencia y valor real a propietarios,
              agentes inmobiliarios y compradores.
            </p>
            <p>
              En un mundo en constante evolución y con una creciente demanda de
              soluciones tecnológicas, nexun se posiciona como líder en la
              adopción de innovaciones disruptivas en la industria del real
              estate. Así, nuestra misión es convertirnos en el motor que
              impulse un cambio significativo en el sector, fomentando la
              colaboración y el intercambio de recursos entre los diferentes
              actores del mercado.
            </p>
            <p>Para lograrlo, Nexun se enfoca en tres pilares fundamentales:</p>
            <p>
              <strong>Transparencia: </strong>La plataforma de Nexun utiliza la
              tecnología blockchain para garantizar la veracidad, trazabilidad y
              seguridad de todas las transacciones inmobiliarias. Esta
              innovación permite a los usuarios tener acceso a información
              detallada y confiable sobre propiedades, procesos y agentes, lo
              que ayuda a tomar decisiones informadas y reducir riesgos.
            </p>
            <p>
              <strong>Optimización: </strong>Nexun emplea algoritmos de
              optimización y contratos digitales para agilizar y simplificar los
              procesos de compra, venta y alquiler de inmuebles. Estas
              herramientas permiten reducir tiempos de espera, minimizar errores
              humanos y eliminar intermediarios innecesarios, resultando en una
              experiencia de usuario más fluida y eficiente.
            </p>
            <p>
              <strong>Entrega de valor: </strong>La economía colaborativa es el
              núcleo de la propuesta de valor de Nexun. Nuestra plataforma
              permite a los actores del real estate compartir recursos,
              conocimientos y oportunidades, lo que genera un ecosistema de
              colaboración y crecimiento. Al conectar a propietarios, agentes y
              compradores en un único espacio, Nexun facilita el acceso a una
              amplia gama de servicios y soluciones personalizadas, adaptadas a
              las necesidades de cada usuario.
            </p>
            <p>
              Como startup comprometida con la excelencia, nexun se esfuerza por
              mantenerse a la vanguardia de las tendencias y desarrollos
              tecnológicos. Nuestro equipo de expertos trabaja incansablemente
              para investigar, desarrollar e implementar soluciones innovadoras
              que marquen la diferencia en la vida de nuestros usuarios.
            </p>
            <p>
              En resumen, la misión de nexun es reinventar la industria del real
              estate a través de la economía colaborativa, ofreciendo una
              plataforma tecnológica que brinde transparencia, eficiencia y
              valor real a todos los actores del sector. Nuestro compromiso es
              liderar el camino hacia un futuro más sostenible, próspero y de
              más oportunidades para el real estate, y nos esforzamos por
              aportar soluciones innovadoras que permitan a nuestros usuarios
              alcanzar sus objetivos de manera más rápida y eficaz.
            </p>
          </Collapse>
        </div> */
}

{
  /* <div className="mt-12 py-5 bg-white">
        <div className="d-flex flex-column flex-md-row  flex-justify-center flex-items-center pb-8">
          <div className="col-12 col-xl-4 col-md-12 d-flex flex-justify-center ">
            <img
              src={ThirdImage}
              alt="about-us"
              style={{
                objectFit: 'cover',
                width: '80%',
              }}
            />
          </div>
          <div className="col-12 col-xl-4 align-items-center d-flex flex-justify-center flex-column pl-4 pl-md-8">
            <h1 className="h1 pt-5 pt-md-0">Transparencia</h1>
            <p className="col-12 f3 pb-5 text-start">
              Nos hemos preocupado de crear un entorno donde prime la
              transparencia y claridad del proceso. Junto con prevenir la
              corrupción y fraude, el entregar información precisa y actualizada
              permitirá a todos tomar las decisiones correctas, al tiempo de
              promover la eficiencia y la equidad en el mercado inmobiliario.
            </p>
            <Link
              role="button"
              onClick={() => setExpandedTransparency((prev) => !prev)}
              className="f3"
            >
              Más información
            </Link>
            <Collapse
              in={expandedTransparency}
              className="color-bg-default col-10 p-4 rounded-3"
            >
              <p>
                La Optimización y Eficiencia en nexun: Impulsando la Excelencia
                en el Real Estate
              </p>
              <p>
                La optimización y eficiencia son aspectos clave en la industria
                del real estate, especialmente en un mercado cada vez más
                competitivo y regulado. Nexun, como plataforma de economía
                colaborativa, ha centrado sus esfuerzos en mejorar la calidad
                del servicio a todos los actores, implementando rutinas sólidas
                que permiten reducir costos, cumplir con las normativas y
                regulaciones del mercado, y brindar un valor real a
                propietarios, agentes inmobiliarios y compradores.
              </p>
              <p>
                A continuación, se describen algunos de los aspectos clave en
                los que nexun se enfoca para garantizar la optimización y
                eficiencia en su plataforma:
              </p>
              <ul>
                <li>
                  Algoritmos de optimización: nexun emplea algoritmos de
                  optimización para analizar y procesar grandes cantidades de
                  datos, lo que permite a los usuarios encontrar las mejores
                  oportunidades y soluciones de acuerdo con sus necesidades y
                  objetivos. Estos algoritmos también ayudan a predecir
                  tendencias del mercado, ajustar precios y realizar análisis
                  comparativos, lo que conduce a una toma de decisiones más
                  rápida y efectiva.
                </li>
                <li>
                  Automatización de procesos: La plataforma de nexun utiliza
                  tecnologías avanzadas para automatizar procesos clave en la
                  compra, venta y alquiler de inmuebles. Esto incluye la
                  generación de contratos digitales, la verificación de
                  identidad y la ejecución de transacciones financieras. Al
                  automatizar estos procesos, nexun reduce el tiempo de espera,
                  minimiza errores humanos y elimina intermediarios
                  innecesarios, lo que resulta en un servicio más eficiente y
                  rentable para sus usuarios.
                </li>
                <li>
                  Cumplimiento de normativas y regulaciones: nexun opera siempre
                  sujeto a cumplir con todas las normativas y regulaciones del
                  mercado inmobiliario, garantizando que sus usuarios puedan
                  operar de manera segura y legal. La plataforma se actualiza
                  constantemente para adaptarse a cambios en las leyes y
                  políticas, lo que permite a los usuarios mantenerse informados
                  y cumplir con sus responsabilidades legales.
                </li>
                <li>
                  Integración con otras tecnologías y servicios: nexun busca
                  continuamente integrarse con otras tecnologías y servicios
                  relevantes para la industria del real estate. Esto incluye la
                  conexión con pasarelas de pago, plataformas de publicidad y
                  servicios de tasación, entre otros. Al facilitar la
                  interoperabilidad y la colaboración, nexun mejora la
                  eficiencia y la calidad del servicio para sus usuarios.
                </li>
              </ul>
              <p>
                La búsqueda constante de optimización y eficiencia por parte de
                Nexun brinda beneficios a largo plazo a todos los actores
                involucrados en la industria del real estate. Al reducir costos,
                acelerar procesos y garantizar el cumplimiento de las
                regulaciones, la plataforma permite a propietarios, agentes y
                compradores operar de manera más efectiva y rentable.
              </p>
            </Collapse>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row flex-justify-center flex-items-center">
          <div className="col-12 col-xl-4 col-md-12 d-flex flex-justify-center ">
            <img
              src={FourthImage}
              alt="about-us"
              style={{
                width: '80%',
              }}
            />
          </div>
          <div className="col-12 col-xl-4 d-flex flex-justify-center flex-column pl-4 pl-md-8 pr-5">
            <h1 className="h1 pt-5 pt-md-0">Optimización</h1>
            <p className="col-12 f3 pb-5">
              Nuestro afán se centra en mejorar la calidad del servicio a todos
              los actores. Esta búsqueda de la excelencia la conseguimos a base
              de implementar sólidas rutinas que permite reducir costos, actuar
              dentro de las normativas y regulaciones del mercado.Los beneficios
              de nuestro foco en la optimización se irán expandiendo a largo
              plazo.
            </p>
            <Link
              role="button"
              onClick={() => setExpandedOptimization((prev) => !prev)}
              className="f3"
            >
              Más información
            </Link>
            <Collapse
              in={expandedOptimization}
              className="color-bg-default col-12 p-4 rounded-3"
            >
              <p>
                La Transparencia en nexun: Un Pilar Fundamental en la Revolución
                del Real Estate
              </p>
              <p>
                En la industria del real estate, la transparencia es un aspecto
                crucial que, en muchas ocasiones, se ve afectada por la falta de
                información precisa y actualizada, así como por prácticas poco
                éticas que pueden llevar a situaciones de corrupción y fraude.
                Nexun, como plataforma de economía colaborativa, ha hecho de la
                transparencia uno de sus pilares fundamentales, entendiendo su
                importancia para el éxito y la equidad en el mercado
                inmobiliario.
              </p>
              <ul>
                <li>
                  Tecnología blockchain: La adopción de la tecnología blockchain
                  permite a nexun asegurar la veracidad y trazabilidad de todas
                  las transacciones inmobiliarias. Gracias a la
                  descentralización y la inmutabilidad de la información en la
                  cadena de bloques, los usuarios pueden confiar en que los
                  datos y registros de propiedad son precisos, actualizados y a
                  prueba de manipulaciones. Esto facilita la toma de decisiones
                  informadas y reduce el riesgo de fraude y corrupción.
                </li>
                <li>
                  Acceso a información detallada: nexun proporciona a sus
                  usuarios acceso a información detallada y actualizada sobre
                  propiedades, procesos y agentes inmobiliarios. Esto incluye
                  datos sobre características de los inmuebles, historial de
                  transacciones, precios de mercado y opiniones de otros
                  usuarios. Al ofrecer información clara y accesible, Nexun
                  fomenta la transparencia y permite a los usuarios tomar
                  decisiones informadas en función de sus necesidades y
                  objetivos.
                </li>
                <li>
                  Contratos digitales: Los contratos digitales en nexun
                  proporcionan un marco legal claro y seguro para todas las
                  transacciones inmobiliarias. Al eliminar la necesidad de
                  intermediarios y reducir el riesgo de errores humanos, los
                  contratos digitales facilitan la transparencia y garantizan
                  que todas las partes involucradas entiendan sus derechos y
                  responsabilidades.
                </li>
                <li>
                  Comunicación abierta y colaboración: nexun promueve un entorno
                  colaborativo en el que propietarios, agentes inmobiliarios y
                  compradores pueden comunicarse abierta y honestamente. Al
                  fomentar la comunicación y el intercambio de información,
                  nexun ayuda a construir relaciones de confianza y a asegurar
                  que todos los actores del real estate sean conscientes de las
                  oportunidades y desafíos en el mercado.
                </li>
              </ul>
              <p>
                La transparencia en nexun se logra mediante la implementación de
                tecnologías innovadoras y la creación de un entorno colaborativo
                que fomente la confianza y la comunicación abierta entre los
                diferentes actores del real estate. A continuación, se describen
                algunos de los aspectos clave en los que Nexun se enfoca para
                garantizar la transparencia en su plataforma:
              </p>
              <p>
                La transparencia en nexun no solo es esencial para prevenir la
                corrupción y el fraude, sino que también es clave para promover
                la eficiencia y la equidad en la industria del real estate. Al
                garantizar que todos los actores tengan acceso a información
                precisa y actualizada, Nexun permite a sus usuarios tomar
                decisiones informadas y minimizar riesgos. Esto, a su vez,
                contribuye a un mercado inmobiliario más justo y sostenible, en
                el que todos los participantes pueden prosperar.
              </p>
            </Collapse>
          </div>
        </div>
        <div className="mx-6 py-10">
          <h1 className="f1-mktg pb-10 pl-md-8">Información de la empresa</h1>
          <div className="d-flex flex-column flex-md-row flex-justify-around">
            <div className="col-12 col-md-3 pb-10 pb-md-0">
              <img src={Image1} alt="about-us" className="col-10 pb-6" />
              <h1 className="h3 pb-4">Centrado en las personas</h1>
              <p className="f3">
                Creemos en las relaciones a largo plazo basadas en la confianza
                y la satisfacción. Estamos comprometido con brindar un servicio
                de calidad para hacer de la experiencia de transar una propiedad
                sea lo más fácil y cómoda posible.{' '}
              </p>
            </div>
            <div className="col-12 col-md-3 pb-10 pb-md-0">
              <img src={Image2} alt="about-us" className="col-10 pb-6" />
              <h1 className="h3 pb-4">Seguridad y confianza</h1>
              <p className="f3">
                Generamos un entorno de garantías donde los clientes puedan
                depositar en nosotros sus propiedades. Nos tomamos muy en serio
                la protección de datos y trabajamos diligentemente para
                garantizar la privacidad de nuestros clientes.
              </p>
            </div>
            <div className="col-12 col-md-3">
              <img src={Image3} alt="about-us" className="col-10 pb-6" />
              <h1 className="h3 pb-4">Tecnología</h1>
              <p className="f3">
                Está en nuestro ADN utilizar la tecnología para ofrecer
                eficiencia y productividad. Además de permitirnos ofrecer
                soluciones innovadoras y personalizadas que se adaptan a las
                distintas circunstancias en un mercado en evolución
              </p>
            </div>
          </div>
        </div>
      </div> */
}
