import { useQuery, useMutation } from '@apollo/client';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { SearchIcon } from '@primer/octicons-react';
import { PROPERTIES_QUERY } from 'apollo/queries';
import { useState, useRef, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';

import { UPDATE_PROPERTY_BY_ID_MUTATION } from 'apollo/mutations';

export default function AllPropertiesRoute() {
  // Number Currency Format
  const numberFormat = (value) =>
    new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
    }).format(value);

  const values = useQuery(PROPERTIES_QUERY, {
    variables: {
      sort: '_ID_DESC',
    },
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [gridRef, setGridRef] = useState(undefined);
  const [searchText, setSearchText] = useState('');
  const [dataSource, setDataSource] = useState(
    values === undefined ||
      values.data === undefined ||
      values.data.properties === undefined
      ? []
      : values.data.properties,
  );
  const [valueOfInput, setValueOfInput] = useState('');
  const { addToast } = useToasts();
  const emptyText = (
    <div className="blankslate">
      <h3 className="blankslate-heading">No hay registros disponibles</h3>
      <p>
        Es posible que no pueda acceder temporalmente a los datos. Por favor,
        espere unos minutos y vuelva a recargar la página.
      </p>
    </div>
  );
  const loadingText = (
    <h2>
      <span>Cargando datos</span>
      <span className="AnimatedEllipsis"></span>
    </h2>
  );

  useEffect(() => {
    if (values.data !== undefined) {
      setDataSource(values.data.properties);
    }
  }, [values.data]);

  const defaultColumns = [
    {
      name: 'title',
      defaultFlex: 1,
      header: 'Nombre',
    },
    {
      name: 'owner',
      defaultFlex: 1,
      header: 'Email',
      render: ({ value }) => <span>{value ? value.email : 'Desconocido'}</span>,
    },
    {
      name: 'place',
      defaultFlex: 2,
      header: 'Dirección',
      render: ({ value }) => (
        <span>
          {value.formattedAddress ? value.formattedAddress : 'Desconocido'}
        </span>
      ),
    },
    {
      name: 'createdAt',
      defaultFlex: 1,
      header: 'Registro',
      render: ({ value }) => {
        const date = new Date(value);
        return (
          <span>
            {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
          </span>
        );
      },
    },
    {
      name: 'operation',
      defaultFlex: 1,
      header: 'Precio',
      render: ({ value }) => {
        return <span>{value ? numberFormat(value.price) : 'Desconocido'}</span>;
      },
    },
    {
      name: 'commission',
      defaultFlex: 1,
      header: 'Honorario',
      render: ({ value }) => {
        return <span>{value ? value : 'Desconocido'}</span>;
      },
    },
    {
      name: '_id',
      defaultFlex: 1,
      header: 'Ver',
      sortable: false,
      render: ({ value }) => {
        return (
          <a href={'/properties/' + value} className="Link p-2">
            <span>Visualizar</span>
          </a>
        );
      },
    },
  ];

  const searchTextRef = useRef(searchText);
  searchTextRef.current = searchText;

  const [columns] = useState(defaultColumns);

  const onSearchChange = ({ target: { value } }) => {
    const visibleColumns = gridRef.current.visibleColumns;

    setSearchText(value);
    const newDataSource = values.data.agents.filter((p) => {
      return visibleColumns.reduce((acc, col) => {
        const v = (p[col.id] + '').toLowerCase(); // get string value
        return acc || v.indexOf(value.toLowerCase()) !== -1; // make the search case insensitive
      }, false);
    });

    setDataSource(newDataSource);
  };

  const gridStyle = { minHeight: 1100 };

  const [cellSelection, setCellSelection] = useState([]);
  const [textCopyable, setTextCopyable] = useState('');

  const onUpdateProperty = (data) => {
    console.log(data);
    addToast('Valor modificado correctamente.', {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onUpdatePropertyFail = (error) => {
    console.log(error);
    addToast('Ha ocurrido un error al modificar el valor.', {
      appearance: 'error',
      autoDismiss: true,
    });
  };

  function UpdateProperty(row, valu) {
    var id = '';
    var optional = '';

    Object.entries(row).forEach(([key, value]) => {
      const array = key.split(',');
      id = array[0];
      optional = array[1];
    });

    var variables = {
      id: id,
      record: {
        [optional]: parseFloat(valu),
      },
    };

    updatePropertyById({ variables: variables });
  }

  const [updatePropertyById] = useMutation(UPDATE_PROPERTY_BY_ID_MUTATION, {
    refetchQueries: ['Properties'],
    onError: onUpdatePropertyFail,
    onCompleted: onUpdateProperty,
  });

  useEffect(() => {
    if (textCopyable !== '') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [textCopyable, isDisabled]);

  useEffect(() => {
    for (var key in cellSelection) {
      const id = key.split(',')[0];
      const option = key.split(',')[1];

      const data = dataSource.find((item) => item._id === id);

      if (data === undefined) {
        return;
      } else if (data[option] === undefined) {
        return;
      }

      const value = data[option];
      setTextCopyable(value);
    }
  }, [cellSelection, dataSource]);

  return (
    <div className="width-full">
      <div className="p-responsive py-3 color-bg-default rounded-2 border">
        <div className="full-width mb-4 d-flex flex-justify-between">
          <label className="f4 col-7 input-group">
            <span className="input-group-button">
              <div className="btn" type="button" aria-label="Search">
                <SearchIcon />
              </div>
            </span>
            <input
              value={searchText}
              onChange={onSearchChange}
              className="form-control col-8"
              placeholder="Busca por nombre, correo o dirección"
            />
          </label>
          <div className="input-group col-3">
            <input
              className="form-control"
              type={'number'}
              max={100}
              min={3}
              placeholder={textCopyable}
              defaultValue={textCopyable}
              onChange={(e) => {
                setValueOfInput(e.target.value);
              }}
            />
            <span className="input-group-button">
              <button
                className={
                  isDisabled ? 'btn btn-primary disabled' : 'btn btn-primary'
                }
                onClick={() => {
                  // Put the data in the state
                  UpdateProperty(cellSelection, valueOfInput);
                }}
              >
                Guardar
              </button>
            </span>
          </div>
        </div>
        <ReactDataGrid
          idProperty="_id"
          onReady={setGridRef}
          dataSource={dataSource ? dataSource : []}
          columns={columns}
          style={gridStyle}
          cellSelection={cellSelection}
          onCellSelectionChange={setCellSelection}
          multiSelect={false}
          emptyText={emptyText}
          loadingText={loadingText}
        />
      </div>
    </div>
  );
}
