import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { ThemeProvider } from '@primer/react';
import { NavLink } from 'react-router-dom';
import { useRef } from 'react';
import { Loading, NexunLogo } from 'components';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import UPDATE_BUYER_BY_ID_MUTATION from 'apollo/mutations/updateBuyerById.mutation';

import { HomeFooter } from 'components';
import { useAuth } from 'context/auth.context';
import { VISIT_QUERY } from 'apollo/queries';
import { EyeIcon, EyeClosedIcon } from '@primer/octicons-react';

import { Box, Button } from '@primer/react';

export default function SignupRoute(visit) {
  const subdomain = useRef(window.location.hostname.split('.')[0]);
  const { actor } = useAuth();
  const auth = useAuth();
  const visitId = useParams();
  const [view, setView] = useState(false);
  const [password, setPassword] = useState('');
  const { addToast } = useToasts();

  const { data, loading } = useQuery(VISIT_QUERY, {
    variables: { filter: { _id: visitId.visit } },
  });

  const email = data?.propertyVisit?.buyer?.email;
  const id = data?.propertyVisit?.buyer?._id;

  const [resetPassword, { loadingPassword }] = useMutation(
    UPDATE_BUYER_BY_ID_MUTATION,
    {
      onCompleted: () => {
        // Avisamos que se ha cambiado correctamente
        addToast('Se ha creado su contraseña', {
          appearance: 'success',
          autoDismiss: true,
        });
        // Logueamos al usuario para que pueda acceder a su subdominio con sus tokens de sesión
        auth.signin(email, password, 'BUYER');

        // Redirigimos al usuario a su subdominio con la visita concreta que tenía que visualizar antes de cambiar contraseña
        window.location =
          process.env.NODE_ENV !== 'production'
            ? 'http://buy.localhost:3000/visits/' + visitId.visit
            : 'https://buy.nexun.es/visits/' + visitId.visit;
      },
      onError: (error) => {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      },
    },
  );

  const handleSignUp = (event) => {
    event.preventDefault();
    if (!password) {
      addToast('Debes introducir una contraseña', {
        appearance: 'error',
        autoDismiss: true,
      });
    }

    if (password) {
      resetPassword({
        variables: {
          id: id,
          record: {
            password: password,
          },
        },
      });
    }
  };

  // Cargando busqueda
  if (loading) {
    return <Loading />;
  }

  if (actor && actor._id === id) {
    // Redirigimos al usuario a su subdominio con la visita concreta que tenía que visualizar antes de cambiar contraseña
    window.location =
      process.env.NODE_ENV !== 'production'
        ? 'http://buy.localhost:3000/visits/' + visitId.visit
        : 'https://buy.nexun.es/visits/' + visitId.visit;
  }
  // En caso de que el usuario no esté logueado, mostrar el formulario de login especial (crear una contraseña para su cuenta) y enviarle a su subdominio correspondiente con la visita concreta.
  if (!actor) {
    return (
      <ThemeProvider>
        <div className="d-flex flex-column flex-1 color-bg-subtle">
          <div className="container-md p-responsive width-full d-flex flex-column flex-items-center py-8">
            <div className="d-flex flex-column flex-items-center flex-justify-center text-center">
              <NavLink to="/" className="color-fg-default no-underline">
                <NexunLogo size="large" onlyIcon />
              </NavLink>
              <h1 className="h3 text-center mt-3">
                Crea tu contraseña en nexun
              </h1>
            </div>
            <div className="color-bg-default rounded-2 color-shadow-medium mt-4">
              <div className="p-4">
                <form onSubmit={handleSignUp}>
                  <Box display="grid" gridGap={3}>
                    <div className="mt-2">
                      <input
                        type="email"
                        name="email"
                        value={email}
                        size="large"
                        className="width-full form-control input-block"
                        disabled
                      />
                    </div>
                    <div className="input-group">
                      <input
                        name="password"
                        className="form-control input-block"
                        type={view ? 'text' : 'password'}
                        placeholder="Nueva contraseña"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required
                      />
                      <span className="input-group-button">
                        <button
                          className="btn"
                          type="button"
                          aria-label="Copy to clipboard"
                          onClick={() => setView(!view)}
                        >
                          {view ? (
                            <EyeIcon></EyeIcon>
                          ) : (
                            <EyeClosedIcon></EyeClosedIcon>
                          )}
                        </button>
                      </span>
                    </div>
                    <Button
                      type="submit"
                      variant="primary"
                      size="large"
                      fontSize={6}
                      block
                    >
                      {loadingPassword ? 'Guardando...' : 'Guardar y seguir'}
                    </Button>
                    <p className="note">
                      * El correo no se puede modificar porque has sido invitado
                      por otro usuario de la plataforma.
                    </p>
                  </Box>
                </form>
                <p className="text-center mt-4">
                  ¿No quieres crear una cuenta?{' '}
                  <Link to="https://nexun.es" className="Link">
                    Cancelar proceso
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
          <HomeFooter />
        </div>
      </ThemeProvider>
    );
  }

  // En caso de que el usuario esté logueado, redirigirlo al subdominio correspondiente con su link concreto de visita
  if (actor.actorType === 'BUYER') {
    if (subdomain.current !== 'buy') {
      window.location =
        process.env.NODE_ENV !== 'production'
          ? 'http://buy.localhost:3000/visits/'
          : 'https://buy.nexun.es/visits/';
    }
  }
}
