export const days = [
  {
    code: 1,
    name: 'Lunes',
  },
  {
    code: 2,
    name: 'Martes',
  },
  {
    code: 3,
    name: 'Miercoles',
  },
  {
    code: 4,
    name: 'Jueves',
  },
  {
    code: 5,
    name: 'Viernes',
  },
  {
    code: 6,
    name: 'Sabado',
  },
  {
    code: 0,
    name: 'Domingo',
  },
];

export const hours = [
  {
    code: 8,
    name: '08:00',
  },
  {
    code: 9,
    name: '09:00',
  },
  {
    code: 10,
    name: '10:00',
  },
  {
    code: 11,
    name: '11:00',
  },
  {
    code: 12,
    name: '12:00',
  },
  {
    code: 13,
    name: '13:00',
  },
  {
    code: 14,
    name: '14:00',
  },
  {
    code: 15,
    name: '15:00',
  },
  {
    code: 16,
    name: '16:00',
  },
  {
    code: 17,
    name: '17:00',
  },
  {
    code: 18,
    name: '18:00',
  },
  {
    code: 19,
    name: '19:00',
  },
  {
    code: 20,
    name: '20:00',
  },
  {
    code: 21,
    name: '21:00',
  },
  {
    code: 22,
    name: '22:00',
  },
  {
    code: 23,
    name: '23:00',
  },
  {
    code: 0,
    name: '00:00',
  },
];
