import { gql } from '@apollo/client';

export default gql`
  mutation RefreshToken {
    refreshToken {
      access {
        token
        expires
      }
      refresh {
        token
        expires
      }
    }
  }
`;
