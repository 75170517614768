export default function FavoritesRoute() {
  return (
    <div className="container-md width-full">
      <div className="p-responsive py-3 color-bg-default rounded-2 border">
        <div className="Subhead">
          <h2 className="Subhead-heading">Favoritas</h2>
          <div className="Subhead-description">
            Gestiona tus favoritos en nexun Inmobiliaria
          </div>
        </div>
        <p className="color-fg-muted text-center">
          En el momento en el tengas propiedades en favoritos, los encontrarás
          en esta sección.
        </p>
      </div>
    </div>
  );
}
