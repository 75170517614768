import { gql } from '@apollo/client';

export default gql`
  query Owners {
    owners {
      email
      isEmailVerificated
      firstName
      lastName
      _id
      createdAt
    }
  }
`;
