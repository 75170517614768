import { gql } from '@apollo/client';

export default gql`
  mutation UpdateAgent(
    $record: UpdateOneAgentInput!
    $filter: FilterUpdateOneAgentInput
  ) {
    updateAgent(record: $record, filter: $filter) {
      recordId
    }
  }
`;
