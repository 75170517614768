import { gql } from '@apollo/client';

export default gql`
  query Countries(
    $filter: FilterFindManyCountryInput
    $sort: SortFindManyCountryInput
  ) {
    countries(filter: $filter, sort: $sort) {
      _id
      name
      code
      numberCode
      flag
    }
  }
`;
