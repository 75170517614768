import { HomeFooter, HomeHeader, ShapeDivider, NexunLogo } from 'components';
import { useParams } from 'react-router-dom';
import 'flatpickr/dist/themes/dark.css';
import Gallery from 'components/Gallery';
import { useCallback, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useMutation, useQuery } from '@apollo/client';
import ModalNexun from 'components/ModalNexun';
import LanguageSelector from 'components/LanguageSelector';
import { CREATE_PROPERTY_VISIT_MUTATION } from 'apollo/mutations';
import { PROPERTY_QUERY } from 'apollo/queries';
import { useDescriptionText } from 'hooks';
import Flatpickr from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';

import Baños from 'images/logos/Banos.png';
import Dormitorios from 'images/logos/Dormitorios.png';
import Garaje from 'images/logos/Garaje.png';
import Inmueble from 'images/logos/Inmueble.png';
import Metros from 'images/logos/Metros.png';
import Planta from 'images/logos/Planta.png';
import Portero from 'images/logos/Portero.png';
import Terraza from 'images/logos/Terraza.png';
import Ubicacion from 'images/logos/Ubicacion.png';
import { FileIcon, EyeClosedIcon, EyeIcon } from '@primer/octicons-react';

function getNameOfDocument(option) {
  switch (option) {
    case 'saleFlyer':
      return 'Dossier Venta';
    case 'energyCertificate':
      return 'Certificado Energético';
    case 'qualityDocumentation':
      return 'Documentación Técnica';
    default:
      return 'Documentos';
  }
}

export default function PropertyViewMainRoute() {
  const params = useParams();
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [view, setView] = useState(false);
  const [photoButton, setStatePhoto] = useState(false);
  const [modal, setModal] = useState(false);
  const [map, setMap] = useState(false);
  const [firstName, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [language, setLanguage] = useState(undefined);
  const [scheduledAt, setScheduleAt] = useState();
  const { addToast } = useToasts();

  useEffect(() => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setIsValidEmail(emailRegex.test(email));
  }, [email]);

  const { data, fetching } = useQuery(PROPERTY_QUERY, {
    variables: {
      filter: { _id: params.propertyId },
    },
    skip: !params.propertyId,
  });

  const handleModal = () => {
    setModal(!modal);
  };

  const handleMap = () => {
    setMap(!map);
  };

  const handleShowPhoto = () => {
    setStatePhoto(!photoButton);
  };

  // Number Currency Format
  const numberFormat = (value) =>
    new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
    }).format(value);

  const TakeDescription = (description, language) => {
    const descriptionText = useDescriptionText(description);
    return descriptionText;
  };

  const onVisitCreated = useCallback(
    (data) => {
      addToast(
        'Hemos enviado un correo a tu dirección a con los detalles de la cita',
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
      setName('');
      setEmail('');
      setPhone('');
      setLanguage(undefined);
      setLastName('');
      setPassword('');
      setScheduleAt();
    },
    [addToast],
  );

  const onError = useCallback(
    (error) => {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: false,
      });
    },
    [addToast],
  );

  const [createVisit, { loading }] = useMutation(
    CREATE_PROPERTY_VISIT_MUTATION,
    { onCompleted: onVisitCreated, onError },
  );

  function handleFormSubmit(event) {
    event.preventDefault();
    if (!isValidEmail) {
      addToast('El email no es válido', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    if (language === undefined) {
      addToast('El idioma no se ha seleccionado.', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    if (scheduledAt === undefined) {
      addToast('La fecha no se ha seleccionado.', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    createVisit({
      variables: {
        record: {
          property: data.property._id,
          date: scheduledAt,
          isConfirmedByBuyer: true,
        },
        buyerRecord: {
          firstName,
          lastName,
          email,
          phone,
          password,
          language: language._id,
          isEmailVerificated: true,
        },
      },
    });
  }

  // function checkDocument(archives, option) {
  //   const selected = archives.find((archive) => archives.typology === option);
  //   if (!selected) {
  //     return null;
  //   }
  //   return selected.url;
  // }

  if (fetching === 'loading')
    return (
      <div className="d-flex flex-column flex-1 color-bg-subtle">
        <div className="container-lg p-responsive width-full">
          <HomeHeader />
        </div>
        <div className="container-lg p-responsive width-full">
          <div className="container-lg">
            <div className="Layout-main col-12">
              <div className="Subhead">
                <h2 className="Subhead-heading"> Buscando...</h2>
              </div>
              <div className="flex items-center justify-center h-screen">
                <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12 mb-4 text-center">
                  <NexunLogo
                    className="d-flex flex-justify-center anim-rotate"
                    onlyIcon={true}
                  />
                  <label className="text-center">Cargando...</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  if (data)
    return (
      <div className="d-flex flex-column flex-1 color-bg-subtle dark-text">
        <div className="container-lg p-responsive width-full">
          <HomeHeader />
        </div>
        <ShapeDivider placement="bottom" />
        <div className="width-full color-bg-default anim-fade-in">
          <div className="container-lg">
            <div className="p-responsive pb-6 color-bg-default ">
              {/* TITLE OF PROPERTIES */}
              <div className="d-flex flex-row pl-1">
                <div className="Subhead col-12">
                  <div className="col-lg-7 col-12 ">
                    <h1 className="h2 text-center text-md-left">
                      {data.property.title}
                    </h1>
                    <p className="Subhead-description">
                      {data.property.place.formattedAddress}
                    </p>
                  </div>
                  <div className="col-lg-5 col-12 d-flex flex-items-end flex-justify-around mb-2">
                    <button className="btn" onClick={handleShowPhoto}>
                      Fotos
                    </button>
                    <button className="btn" onClick={handleMap}>
                      Mapa
                    </button>
                    <button className="btn" onClick={handleModal}>
                      Planos
                    </button>
                  </div>
                </div>
              </div>
              {/* IMAGES OF PROPERTIES */}
              <div className="d-lg-flex flex-row d-inline-block">
                <Gallery
                  videos={data.property.videos ? data.property.videos : []}
                  galleryImages={data.property.images}
                  name={data.property.title}
                  address={data.property.place.formattedAddress}
                  photoButton={photoButton}
                />
                {modal && (
                  <ModalNexun
                    array={data.property.images}
                    index={0}
                    name={data.property.title}
                    address={data.property.place.formattedAddress}
                    setModal={setModal}
                  />
                )}
                {map && (
                  <ModalNexun
                    lat={data.property.place.geometry.location.lat}
                    lng={data.property.place.geometry.location.lng}
                    name={data.property.title}
                    address={data.property.place.formattedAddress}
                    setMap={setMap}
                  />
                )}
              </div>
              {/* PRICE OF PROPERTY */}
              <div className="d-flex flex-justify-center flex-row mt-3 border-top color-border-muted">
                <p className="h1 p-2">
                  {numberFormat(data.property.operation.price)}
                </p>
              </div>
              {/* DATA ICONS OF PROPERTIES */}
              <div className="d-flex flex-row border-bottom border-top py-4 color-border-muted f4">
                <ul className="list-style-none col-12 text-center">
                  <li className="col-5 col-md-4 p-2 d-inline-block">
                    <p className="d-flex flex-row flex-justify-center flex-self-center">
                      <img
                        src={Metros}
                        alt="fallo"
                        width={32}
                        className="mt-n1 mr-1 mr-md-2"
                      />{' '}
                      {data.property.features.areaUsable} m2
                    </p>
                  </li>
                  <li className="d-inline-block col-5 col-md-4 p-2">
                    <p className="d-flex flex-row flex-justify-center flex-self-center">
                      <img
                        src={Terraza}
                        alt="fallo"
                        width={32}
                        className="mt-n1 mr-1  mr-md-2"
                      ></img>{' '}
                      {data.property.features.terrace ? 'Con' : 'Sin'} terraza
                    </p>
                  </li>
                  <li className="d-inline-block col-5 col-md-4  p-2">
                    <p className="d-flex flex-row flex-justify-center flex-self-center">
                      <img
                        src={Planta}
                        alt="fallo"
                        width={32}
                        className="mt-n1 mr-1  mr-md-2"
                      ></img>{' '}
                      {data.property.address.floor}ª planta
                    </p>
                  </li>
                  <li className="d-inline-block col-5 col-md-4  p-2">
                    <p className="d-flex flex-row flex-justify-center flex-self-center">
                      <img
                        src={Dormitorios}
                        alt="fallo"
                        width={32}
                        className="mt-n1 mr-1 mr-md-2"
                      ></img>{' '}
                      {data.property.features.bedroomNumber} dormitorios
                    </p>
                  </li>
                  <li className="d-inline-block col-5 col-md-4  p-2">
                    <p className="d-flex flex-row flex-justify-center flex-self-center">
                      <img
                        src={Garaje}
                        alt="fallo"
                        width={32}
                        className="mt-n1 mr-1 mr-md-2"
                      ></img>{' '}
                      {data.property.features.parkingAvailable ? 'Con' : 'Sin'}{' '}
                      garaje
                    </p>
                  </li>
                  <li className="d-inline-block col-5 col-md-4  p-2">
                    <p className="d-flex flex-row flex-justify-center flex-self-center">
                      <img
                        src={Portero}
                        alt="fallo"
                        width={32}
                        className="mt-n1 mr-1 mr-md-2"
                      ></img>{' '}
                      {data.property.features.doorman ? 'Con' : 'Sin'} portero
                    </p>
                  </li>
                  <li className="d-inline-block col-5 col-md-4 p-2 ">
                    <div className="d-flex flex-justify-center flex-content-center">
                      <p className="d-flex flex-row flex-justify-center flex-self-center">
                        <img
                          src={Baños}
                          alt="fallo"
                          width={32}
                          className="mt-n1 mr-1 mr-md-2"
                        ></img>{' '}
                        {data.property.features.bathroomNumber} baños
                      </p>
                    </div>
                  </li>
                  <li className="d-inline-block col-5 col-md-4  p-2">
                    <p className="d-flex flex-row flex-justify-center flex-self-center">
                      <img
                        src={Ubicacion}
                        alt="fallo"
                        width={32}
                        className="mt-n1 mr-1 mr-md-2"
                      ></img>{' '}
                      Orientación{' '}
                      {data.property.features.windowsLocation === 'external'
                        ? 'exterior'
                        : 'interior'}{' '}
                    </p>
                  </li>
                  <li className="d-inline-block col-5 col-md-4  p-2">
                    <p className="d-flex flex-row flex-justify-center flex-self-center">
                      <img
                        src={Inmueble}
                        alt="fallo"
                        width={32}
                        className="mt-n1 mr-1 mr-md-2"
                      ></img>
                      {data.property.features.conservation ===
                      'new_development_finished'
                        ? 'Nueva construcción finalizada'
                        : 'Buen estado'}
                    </p>
                  </li>
                </ul>
              </div>
              {/* PRESENTATION PROPERTY */}
              <div className="d-flex flex-row mt-3">
                <p className="format px-2">
                  {/* TODO: SEND USER DEFAULT LANGUAJE TO TAKE DESCRIPTION FUNCTION */}
                  {TakeDescription(data.property.descriptions)}
                </p>
              </div>
              {/* ARCHIVOS DE LA PROPIEDAD */}
              <div className="mt-3 border-top py-4">
                <h3 className="text-start h2 pl-2 pb-3">Descargables</h3>
                <div className="d-flex flex-row mt-3 flex-justify-around">
                  {data.property.archiveIntern.map((archive) => (
                    <div key={archive.url} className="text-center px-2 f4">
                      <FileIcon size={20} className="mr-2" />
                      <a
                        href={archive.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getNameOfDocument(archive.typology)}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ShapeDivider placement="top" />

        <div className="container py-4">
          <div className="d-flex flex-row flex-justify-center">
            <div className="col-12 col-lg-6">
              <div className="d-flex flex-column">
                <h3 className="text-start h1 pl-2 text-center pb-2">
                  ¿Quieres venir a visitarla?
                </h3>
                <h3 className="f4 text-bold color-fg-subtle text-center">
                  Rellena el formulario y nos pondremos en contacto contigo lo
                  antes posible para concertar una visita.
                </h3>
                <div className="Box Box--spacious col-10 mx-auto mt-4 pl-5 pr-5 pb-3">
                  <form onSubmit={handleFormSubmit}>
                    <div className="Box-body border-0">
                      <div className="form-group mb-4">
                        <div className="form-group-body">
                          <div className="form-group">
                            <div className="form-group-body">
                              <input
                                className="form-control width-full"
                                type="text"
                                name="firstName"
                                value={firstName}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Nombre"
                                required
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="form-group-body">
                              <input
                                className="form-control width-full"
                                type="text"
                                name="lastName"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Apellido"
                                required
                              />
                            </div>
                          </div>
                          <div className="form-group text-start">
                            <div className="form-group-body">
                              <input
                                className="form-control width-full"
                                type="number"
                                value={phone}
                                name="phone"
                                placeholder="Teléfono de contacto"
                                required
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form-group text-start">
                            <div className="form-group-body">
                              <input
                                className="form-control width-full"
                                type="email"
                                value={email}
                                name="email"
                                placeholder="Correo de contacto"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                              <input
                                className="form-control"
                                type="hidden"
                                name="propertyId"
                                value={data.property.id}
                                placeholder="propertyId"
                              />
                            </div>
                          </div>
                          <div className="form-group text-start">
                            <div className="form-group-body input-group">
                              <input
                                name="password"
                                className="form-control input-block"
                                type={view ? 'text' : 'password'}
                                placeholder="Contraseña"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                required
                              />
                              <span className="input-group-button">
                                <button
                                  className="btn"
                                  type="button"
                                  aria-label="Copy to clipboard"
                                  onClick={() => setView(!view)}
                                >
                                  {view ? (
                                    <EyeIcon></EyeIcon>
                                  ) : (
                                    <EyeClosedIcon></EyeClosedIcon>
                                  )}
                                </button>
                              </span>
                            </div>
                          </div>
                          <div className="form-group text-start">
                            <div className="form-group-body">
                              <Flatpickr
                                data-enable-time
                                options={{
                                  dateFormat: 'F j,Y H:i',
                                  minDate: 'today',
                                  enableTime: true,
                                  time_24hr: true,
                                  locale: Spanish,
                                  hourIncrement: 1,
                                  minuteIncrement: 15,
                                  defaultMinute: 0,
                                  altFormat: 'F j,Y H:i',
                                }}
                                placeholder="Fecha y hora de la visita"
                                name="scheduledAt"
                                className="form-control width-full"
                                value={scheduledAt}
                                onChange={(date) =>
                                  setScheduleAt(new Date(date))
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group text-start">
                            <div className="form-group-body">
                              <LanguageSelector
                                value={language}
                                onSelect={setLanguage}
                                disabled={loading}
                                className="form-control width-full"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={loading}
                      >
                        {loading
                          ? 'Enviando petición...'
                          : '¡Quiero visitarla!'}
                      </button>
                      <p className="note pt-2">
                        Nexun te informa que los datos de carácter personal que
                        nos proporciones rellenando el presente formulario serán
                        tratados por NEXUN PLATAFORMA INMOBILIARIA SL. Puedes
                        consultar la información adicional y detallada sobre la
                        Protección de Datos en nuestra{' '}
                        <a
                          href="/policy"
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        >
                          política de privacidad
                        </a>
                        .
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HomeFooter />
      </div>
    );
}
