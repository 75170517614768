import { gql } from '@apollo/client';

export default gql`
  query BuyerById($id: MongoID!) {
    buyerById(_id: $id) {
      avatarURL
      email
      isEmailVerificated
      firstName
      lastName
      phone
      mobilePhone
      birthDate
    }
  }
`;
