export default function PropertyTitles({ property, numberFormat }) {
  return (
    <>
      <div className="d-flex flex-column flex-justify-center">
        <h1
          className="p-3 px-4 text-center container-md lh-condensed"
          style={{ fontWeight: 'bolder', fontSize: '2rem' }}
        >
          {property.titlePresentation}
        </h1>
        <h3 className="h2 px-4 text-center container-sm text-normal lh-condensed">
          {property.subtitle}
        </h3>
        <div className="d-flex flex-justify-center flex-row mt-3">
          <h2
            className="py-2"
            style={{ fontWeight: 'bolder', fontSize: '3rem' }}
          >
            {numberFormat(property.operation.price)}
          </h2>
        </div>
      </div>
      <div
        className="d-flex flex-justify-center flex-row mt-2"
        style={{ gap: '3rem' }}
      >
        {property.icons.map((icon, i) => (
          <div
            key={i}
            className="d-flex flex-justify-center flex-column align-items-center"
          >
            <div
              className="d-flex flex-column flex-justify-center align-items-center"
              style={{ gap: '.6rem' }}
            >
              <img
                src={icon.src}
                className="img-fluid rounded"
                alt="icon"
                style={{ width: 'auto', height: '50px' }}
              />
              <p className="f1 text-center">{icon.description}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
