import { gql } from '@apollo/client';

export default gql`
  query Buyers {
    buyers {
      email
      isEmailVerificated
      firstName
      lastName
      phone
      mobilePhone
      _id
      createdAt
    }
  }
`;
