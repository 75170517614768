import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  Observable,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { REFRESH_TOKEN_MUTATION } from 'apollo/mutations';
import { GraphQLError } from 'graphql';

const isRefreshRequest = (operation) =>
  operation.operationName === 'RefreshToken';

const returnTokenDependingOnOperation = (operation) =>
  isRefreshRequest(operation)
    ? localStorage.getItem('refreshToken')
    : localStorage.getItem('token');

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

const authLink = setContext((operation, { headers }) => {
  const token = returnTokenDependingOnOperation(operation);
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        switch (error.extensions.code) {
          case 'UNAUTHENTICATED':
            // Ignore 401 errors for a refresh token request
            if (isRefreshRequest(operation)) return;
            const observable = new Observable((observer) => {
              refreshToken()
                .then((token) => {
                  if (!token) {
                    throw new GraphQLError('Empty AccessToken');
                  }
                  // Retry the request, returning the new observable
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };
                  forward(operation).subscribe(subscriber);
                })
                .catch((error) => {
                  observer.error(error);
                });
            });
            return observable;
          default:
            return;
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  },
);

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

const refreshToken = async () => {
  try {
    const response = await client.mutate({
      mutation: REFRESH_TOKEN_MUTATION,
    });
    const accessToken = response.data?.refreshToken?.access?.token;
    const refreshToken = response.data?.refreshToken?.refresh?.token;
    localStorage.setItem('token', accessToken || '');
    localStorage.setItem('refreshToken', refreshToken || '');
    return accessToken;
  } catch (error) {
    localStorage.clear();
    throw error;
  }
};
