import { HomeFooter, HomeHeader, ShapeDivider } from 'components';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Steps, WhyNexun2 } from 'utils/client-data';

export default function ClientRoute() {
  const [visitDate, setVisitDate] = useState(new Date());
  const [email, setEmail] = useState('');
  return (
    <div className="d-flex flex-column flex-1 color-bg-subtle dark-text">
      <div className="container-lg p-responsive width-full">
        <HomeHeader />
      </div>
      <ShapeDivider placement="bottom" />
      <div className="color-bg-default">
        <div className="container-lg p-responsive width-full">
          <div
            className="blankslate blankslate-large d-flex flex-column flex-items-center"
            style={{ gap: 14 }}
          >
            <h3 className="h1">¿Has encontrado tu nuevo hogar?</h3>
            <h2
              className="f4 text-bold color-fg-subtle"
              style={{ maxWidth: '500px' }}
            >
              Experimenta el servicio más transparente de comercialización de
              inmuebles en tu nueva plataforma colaborativa.
            </h2>
            <h4
              className="f4 text-bold color-fg-subtle"
              style={{ maxWidth: '500px' }}
            ></h4>
            <div className="pt-1 f4">
              <Link to="/properties" className="Link btn-large" role="button">
                ver propiedades
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ShapeDivider placement="top" />
      <div className="pt-7 pb-5">
        <h3 className="h1 text-center pb-6">¿Qué viene a continuación?</h3>
        <div className="container container-xl">
          <div className="container d-flex flex-wrap flex-justify-around p-responsive">
            {Steps.map((logo) => (
              <div
                key={logo.title}
                className="Box Box--spacious p-6 text-center mb-7 anim-fade-in col-12 col-md-5 color-shadow-large d-flex flex-column flex-items-center"
                style={{ gap: 10 }}
              >
                {logo.logo}
                <p className="f4">{logo.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ShapeDivider placement="bottom" />
      <div className="pb-3 color-bg-default">
        <div className="container-xl p-responsive width-full pb-6">
          <h2 className="h1 text-center pb-6">¿Por qué Nexun?</h2>
          <div className="container-fluid container-lg">
            <div className="container d-flex flex-wrap flex-justify-around">
              {WhyNexun2.map((logo) => (
                <div
                  key={logo.title}
                  className="Box Box--spacious p-6 text-center mb-7 anim-fade-in col-12 col-md-5 color-shadow-large d-flex flex-column flex-items-center"
                  style={{ gap: 10 }}
                >
                  {logo.logo}
                  <h4 className="h2">{logo.title}</h4>
                  <p className="f4">{logo.subtitle}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ShapeDivider placement="top" />
      <div className="pb-5">
        <div className="container-xl p-responsive width-full pb-6">
          <h2 className="h1 text-center">¿Buscas nuevos aires?</h2>
          <p className="f4 pt-2 color-fg-muted text-center">
            Consulta por código postal las propiedades actualmente disponibles
            en nexun
          </p>
          {/* TODO: MAKE FORM REQUEST */}
          <form className="p-4 d-flex flex-justify-center">
            <input
              className="form-control input-block mb-3 col-12 col-md-4 m-4"
              type="text"
              name="postalCode"
              placeholder="Código postal"
              required
            />
            <button type="submit" className="btn-mktg btn-small-mktg m-4">
              Buscar
            </button>
          </form>
          <div className="text-center">
            <Link
              className="f5 color-fg-default no-underline text-center mt-0"
              to="/properties"
            >
              Puedes encontrar todas las propiedades{' '}
              <span className="Link">aquí</span>
            </Link>
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
}
