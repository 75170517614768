import {
  PeopleIcon,
  GitCompareIcon,
  MegaphoneIcon,
} from '@primer/octicons-react';

import aws from '../images/logos/aws.svg';
import Alastria from '../images/logos/alastria.svg';
import Sitelicon from '../images/logos/sitelicon2.png';
import OpenAI from '../images/logos/openai.svg';
import PayPal from '../images/logos/paypal.svg';

import team1 from '../images/people/team1.png';
import team2 from '../images/people/team2.png';
import team3 from '../images/people/team3.png';
import team4 from '../images/people/team4.png';
import team5 from '../images/people/team5.png';
import team6 from '../images/people/team6.png';

export const ourPartners = [
  {
    id: 1,
    name: 'AWS',
    img: aws,
    alt: 'Logo de Amazon Web Services',
  },
  {
    id: 2,
    name: 'Alastria',
    img: Alastria,
    alt: 'Logo de Alastria',
  },
  {
    id: 3,
    name: 'Sitelicon',
    img: Sitelicon,
    alt: 'Logo de Sitelicon',
  },
  {
    id: 4,
    name: 'OpenAI',
    img: OpenAI,
    alt: 'Logo de OpenAI',
  },
  {
    id: 5,
    name: 'PayPal',
    img: PayPal,
    alt: 'Logo de PayPal',
  },
];

export const ourTeam = [
  {
    id: 1,
    img: team1,
    name: 'Eduardo Clavijo',
    role: 'Director Operaciones',
    description:
      'Empresario en administración de activos inmobiliarios. Licenciado y grado en dirección general.',
  },
  {
    id: 2,
    img: team2,
    name: 'Sebastián Betanzo',
    role: 'Dirección General',
    description:
      'Empresario en industria mobiliaria internacional. Ingeniero Civil Industrial y grado de Master en IOT.',
  },
  {
    id: 3,
    img: team3,
    name: 'Ana García Martínez',
    role: 'Estrategia Real Estate',
    description:
      'Real Estate Asset Management. Administración de Empresas y Económicas. MBA en Marketing.',
  },
  {
    id: 4,
    img: team4,
    name: 'Ignacio Castroverde',
    role: 'Director IA y GTM',
    description:
      'Estrategia y Operación en Tecnológicas Globales. Ingeniero de Teleco y grado en Dirección General.',
  },
  {
    id: 5,
    img: team5,
    name: 'Carlos Pena',
    role: 'Director Plataforma',
    description:
      'Socio de consultoría tecnológica. Filosofía y Matemáticas, y Grado en Transformación Digital.',
  },
  {
    id: 6,
    img: team6,
    name: 'Tomás Pettersen',
    role: 'Director Financiero',
    description:
      'Modelación financiera, análisis de mercado en Energías Renovables. Ingeniero Civil industrial.',
  },
];

export const newsletter = [
  {
    id: 1,
    title: 'Blog',
    description:
      'Explora nuevos contenidos generados por nuestro equipo sobre el mercado inmobiliario.',
    icon: <PeopleIcon className="mb-3" size={28} />,
  },
  {
    id: 2,
    title: 'Ferias',
    description:
      'Puedes estar al tanto de las ferias donde poder conocernos en persona.',
    icon: <GitCompareIcon className="mb-3" size={28} />,
  },
  {
    id: 3,
    title: 'Noticias',
    description:
      'La información más relevante de la industria para que estés siempre informado.',
    icon: <MegaphoneIcon className="mb-3" size={28} />,
  },
];
