import { gql } from '@apollo/client';

export default gql`
  query PropertyVisits {
    propertyVisits {
      property {
        _id
        title
      }
      date
      agent {
        _id
        fullName
      }

      buyer {
        _id
        firstName
        lastName
      }
      isConfirmedByBuyer
      confirmedByBuyerAt
      isRefusedByBuyer
      refusedByBuyerAt
      isConfirmedByAgent
      confirmedByAgentAt
      isRefusedByAgent
      refusedByAgentAt
      _id
    }
  }
`;
