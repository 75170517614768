import { useState } from 'react';
import { HomeFooter, NexunLogo } from 'components';
import {
  FormControl,
  TextInput,
  Box,
  Button,
  ThemeProvider,
} from '@primer/react';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'context/auth.context';

export default function AdminLoginRoute() {
  const auth = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (event) => {
    event.preventDefault();
    auth.signin(email, password, 'ADMIN');
  };

  return (
    <ThemeProvider>
      <div className="d-flex flex-column flex-1 color-bg-subtle">
        <div className="container-md p-responsive width-full d-flex flex-column flex-items-center py-8">
          <div className="d-flex flex-column flex-items-center flex-justify-center text-center">
            <NavLink to="/" className="color-fg-default no-underline">
              <NexunLogo size="large" onlyIcon />
            </NavLink>
            <h1 className="h3 text-center mt-3">Iniciar sesión en nexun</h1>
          </div>
          <div
            className="color-bg-default rounded-2 color-shadow-medium mt-4 width-full"
            style={{ maxWidth: 400 }}
          >
            <div className="p-4">
              <form onSubmit={handleLogin}>
                <Box display="grid" gridGap={3}>
                  <FormControl required>
                    <FormControl.Label>Correo electrónico</FormControl.Label>
                    <TextInput
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      size="large"
                      autoFocus
                      contrast
                      block
                    />
                  </FormControl>
                  <FormControl required>
                    <FormControl.Label>Contraseña</FormControl.Label>
                    <TextInput
                      type="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      size="large"
                      contrast
                      block
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    variant="primary"
                    size="large"
                    fontSize={6}
                    block
                  >
                    Iniciar sesión
                  </Button>
                </Box>
              </form>
            </div>
          </div>
        </div>
        <HomeFooter />
      </div>
    </ThemeProvider>
  );
}
