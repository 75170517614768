import { BriefcaseIcon, KeyIcon, PersonIcon } from '@primer/octicons-react';
import { NavLink, Outlet } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  AGENTS_COUNT_QUERY,
  BUYERS_COUNT_QUERY,
  OWNERS_COUNT_QUERY,
} from 'apollo/queries';

export default function UsersLayout() {
  const agents = useQuery(AGENTS_COUNT_QUERY).data?.agentsCount;
  const buyers = useQuery(BUYERS_COUNT_QUERY).data?.buyersCount;
  const owners = useQuery(OWNERS_COUNT_QUERY).data?.ownersCount;

  return (
    <>
      <div className="tabnav m-0">
        <div className="tabnav-extra float-right">
          Eliga entre los diferentes tipos de usuario
        </div>
        <nav className="tabnav-tabs" aria-label="Foo bar">
          <NavLink to="agents" className="tabnav-tab" aria-current="page">
            <BriefcaseIcon size={16} />
            Agentes
            <span className="Counter">{agents}</span>
          </NavLink>
          <NavLink to="clients" className="tabnav-tab">
            <PersonIcon size={16} />
            Clientes
            <span className="Counter">{buyers}</span>
          </NavLink>
          <NavLink to="owners" className="tabnav-tab">
            <KeyIcon size={16} />
            Propietarios
            <span className="Counter">{owners}</span>
          </NavLink>
        </nav>
      </div>
      <div className="width-full">
        <div className="p-responsive py-3 color-bg-default rounded-bottom-2 border-top-right-2 border-left border-right border-bottom mt-0">
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
