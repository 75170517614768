import { Route, Routes } from 'react-router-dom';
import { AdminLayout, RequireAuth } from 'components';

import AdminLoginRoute from 'routes/admin/login.route';
import AdminHomeRoute from 'routes/admin/home.route';
import NotFoundRoute from 'routes/notFound.route';
import AllAgents from 'routes/admin/users/all-agents';
import AllVisitsRoute from 'routes/admin/visits/all-visits.route';
import AllPropertiesRoute from 'routes/admin/properties/all-properties.route';
import PropertyView from 'routes/agent/properties/view.route';
import UsersLayout from 'routes/admin/users';
import AllClients from 'routes/admin/users/all-clients';
import AllOwners from 'routes/admin/users/all-owners';
import AgentView from 'routes/admin/users/agent-view';
import BuyerView from 'routes/admin/users/client-view';
import OwnerView from 'routes/admin/users/owner-view';
import VisitView from 'routes/admin/visits/visit.route';

export default function AdminRoutes() {
  return (
    <Routes>
      <Route
        element={
          <RequireAuth>
            <AdminLayout />
          </RequireAuth>
        }
      >
        <Route path="*" element={<NotFoundRoute />} />
        <Route path="/" element={<AdminHomeRoute />} />
        <Route path="/users" element={<UsersLayout />}>
          <Route path="*" element={<NotFoundRoute />} />
          <Route path="/users/agents" element={<AllAgents />} />
          <Route path="/users/agents/:id" element={<AgentView />} />
          <Route path="/users/clients" element={<AllClients />} />
          <Route path="/users/clients/:id" element={<BuyerView />} />
          <Route path="/users/owners" element={<AllOwners />} />
          <Route path="/users/owners/:id" element={<OwnerView />} />
        </Route>
        <Route path="/properties" element={<AllPropertiesRoute />} />
        <Route path="/properties/:propertyId" element={<PropertyView />} />
        <Route path="/visits" element={<AllVisitsRoute />} />
        <Route exact path="/visits/*" element={<NotFoundRoute />} />
        <Route path="/visits/:visit_id" element={<VisitView />} />
      </Route>
      <Route
        path="/login"
        element={
          <RequireAuth isRequired={false}>
            <AdminLoginRoute />
          </RequireAuth>
        }
      />
    </Routes>
  );
}
