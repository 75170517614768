import { gql } from '@apollo/client';

export default gql`
  mutation Mutation(
    $ubication: String!
    $baths: String!
    $priceRange: [Int!]!
    $delivery: String!
    $emailClient: String!
    $habs: String!
  ) {
    createPropertyPreferencesRequest(
      ubication: $ubication
      baths: $baths
      priceRange: $priceRange
      delivery: $delivery
      emailClient: $emailClient
      habs: $habs
    )
  }
`;
