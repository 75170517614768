import NexunLogo from 'components/NexunLogo';
import { useAuth } from 'context/auth.context';
import { NavLink } from 'react-router-dom';

export default function AdminHeader() {
  const auth = useAuth();
  return (
    <header className="Header border-bottom">
      <div className="container-lg p-responsive width-full">
        <div className="d-flex flex-justify-between flex-items-center">
          <div className="Header-item">
            <NavLink to="/" className="Header-link text-normal no-underline">
              <NexunLogo>
                <p className="note color-fg-subtle m-0">Administrador</p>
              </NexunLogo>
            </NavLink>
          </div>
          <div className="Header-item position-relative mr-0 mr-md-3 flex-order-2">
            <button
              type="button"
              className="btn-link color-fg-muted no-underline "
              role="menuitem"
              onClick={auth.signout}
            >
              Cerrar sesión
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
