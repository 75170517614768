import { useState, useEffect, useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';
import Flatpickr from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import { useMutation } from '@apollo/client';
import { LanguageSelector, PropertySelect } from 'components';

import {
  FormControl,
  TextInput,
  Box,
  Button,
  ThemeProvider,
} from '@primer/react';
import { useAuth } from 'context/auth.context';
import { CreditCardIcon } from '@primer/octicons-react';
import {
  CREATE_PROPERTY_VISIT_MUTATION,
  CREATE_AGENT_REFERRED_MUTATION,
} from 'apollo/mutations';

// TODO:
// When selecting a property, request also a Date and Time for the visit
// Create and call `createPropertyVisit` mutation
// Pass buyer info into `buyerRecord` argument

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const getActorLabel = (value) => {
  switch (value) {
    case 'AGENT':
      return 'agente';
    case 'BUYER':
      return 'comprador';
    case 'OWNER':
      return 'propietario';
    default:
      return 'usuario';
  }
};

export default function InviteRoute() {
  const auth = useAuth();
  const { addToast } = useToasts();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [actorType, setActorType] = useState(undefined);
  const [isManaged, setIsManaged] = useState(false);
  const [property, setProperty] = useState(undefined);

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [language, setLanguage] = useState(undefined);
  const [scheduledAt, setScheduleAt] = useState(undefined);

  useEffect(() => {
    setIsEmailValid(emailRegex.test(email));
  }, [email]);

  const onCompleted = useCallback(
    (data) => {
      addToast(
        `Hemos enviado una invitación al correo del ${getActorLabel(
          actorType,
        )}`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
      setFirstName('');
      setLastName('');
      setEmail('');
      setProperty(undefined);
      setLanguage(undefined);
      setScheduleAt(undefined);
    },
    [addToast, actorType],
  );

  const onCompletedAgent = useCallback(
    (data) => {
      addToast(
        `Hemos creado el nuevo agente. El nuevo usuario debe restablecer su contraseña para poder acceder al sistema con la contraseña que desee para el corrreo proporcionado: ${data.createAgent.record.email}`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
      setFirstName('');
      setLastName('');
      setEmail('');
    },
    [addToast],
  );

  const onError = useCallback(
    (error) => {
      addToast(error.message, { appearance: 'error', autoDismiss: false });
    },
    [addToast],
  );

  const [createPropertyVisit, { loading }] = useMutation(
    CREATE_PROPERTY_VISIT_MUTATION,
    { onCompleted, onError },
  );

  const [createAgent, { charging }] = useMutation(
    CREATE_AGENT_REFERRED_MUTATION,
    {
      onCompleted: onCompletedAgent,
      onError,
    },
  );

  const generatePassword = () => {
    const length = 8;
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!isEmailValid) {
      return addToast('Por favor, introduce un email válido', {
        appearance: 'error',
        autoDismiss: true,
      });
    }

    // CUANDO SELECCIONA CREAR UN COMPRADOR (Y SU VISITA)
    if (actorType === 'BUYER') {
      if (actorType === 'BUYER' && !property) {
        return addToast('Por favor, selecciona una propiedad', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
      if (actorType === 'BUYER' && !language) {
        return addToast('Por favor, selecciona el idioma del comprador', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
      if (actorType === 'BUYER' && !scheduledAt) {
        return addToast('Por favor, selecciona una fecha para la visita', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
      createPropertyVisit({
        variables: {
          record: {
            property: property._id,
            date: scheduledAt,
            isConfirmedByBuyer: true,
            agent: isManaged ? auth.actor._id : undefined,
          },
          buyerRecord: {
            firstName,
            lastName,
            email,
            language: language._id,
            referredBy: auth.actor._id,
            isEmailVerificated: true,
          },
        },
      });
    }

    // CUANDO SELECCIONA CREAR UN AGENTE REFERIDO
    if (actorType === 'AGENT') {
      if (!firstName) {
        return addToast('Por favor, introduce el nombre del agente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
      if (!lastName) {
        return addToast('Por favor, introduce el apellido del agente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
      if (!email) {
        return addToast('Por favor, introduce el email del agente', {
          appearance: 'error',
          autoDismiss: true,
        });
      }

      createAgent({
        variables: {
          record: {
            firstName: firstName,
            lastName: lastName,
            email: email,
            referredBy: auth.actor._id,
            password: generatePassword(),
          },
        },
      });
    }
  };

  return (
    <ThemeProvider>
      <div className="d-flex flex-column flex-1 color-bg-subtle">
        <div className="container-md p-responsive width-full d-flex flex-column flex-items-center py-4">
          <div className="d-flex flex-column flex-items-center flex-justify-center text-center">
            <h1 className="h3 text-center">Invitar usuario a nexun</h1>
          </div>
          <div className="width-full color-bg-default rounded-2 color-shadow-medium mt-4">
            <div className="p-4">
              <form onSubmit={handleFormSubmit}>
                <Box display="grid" gridGap={3}>
                  <div className="">
                    <p className="text-bold">Tipologia de usuario</p>
                    <div className="radio-group">
                      <input
                        type="radio"
                        id="actor-type-agent"
                        className="radio-input"
                        name="actorType"
                        value="AGENT"
                        onChange={({ target }) => setActorType(target.value)}
                        checked={actorType === 'AGENT'}
                        required
                      />
                      <label className="radio-label" htmlFor="actor-type-agent">
                        Agente
                      </label>
                      <input
                        type="radio"
                        id="actor-type-owner"
                        className="radio-input"
                        name="actorType"
                        value="OWNER"
                        onChange={({ target }) => setActorType(target.value)}
                        checked={actorType === 'OWNER'}
                        disabled
                        required
                      />
                      <label
                        className="radio-label tooltipped tooltipped-n"
                        htmlFor="actor-type-owner"
                        aria-label="Disponible próximamente"
                      >
                        Propietario
                      </label>
                      <input
                        type="radio"
                        id="actor-type-buyer"
                        className="radio-input"
                        name="actorType"
                        value="BUYER"
                        onChange={({ target }) => setActorType(target.value)}
                        checked={actorType === 'BUYER'}
                        required
                      />
                      <label className="radio-label" htmlFor="actor-type-buyer">
                        Comprador
                      </label>
                    </div>
                  </div>
                  <FormControl required>
                    <TextInput
                      type="text"
                      name="firstName"
                      value={firstName}
                      placeholder={`Nombre del ${getActorLabel(actorType)}`}
                      onChange={({ target }) => setFirstName(target.value)}
                      size="large"
                      autoFocus
                      contrast
                      block
                    />
                  </FormControl>
                  <FormControl required>
                    <TextInput
                      type="text"
                      name="lastname"
                      value={lastName}
                      placeholder={`Apellido del ${getActorLabel(actorType)}`}
                      onChange={({ target }) => setLastName(target.value)}
                      size="large"
                      contrast
                      block
                    />
                  </FormControl>
                  <FormControl required>
                    <TextInput
                      type="email"
                      name="email"
                      value={email}
                      placeholder={`Dirección de correo del ${getActorLabel(
                        actorType,
                      )}`}
                      onChange={({ target }) => setEmail(target.value)}
                      size="large"
                      contrast
                      block
                    />
                  </FormControl>
                  {actorType === 'AGENT' && (
                    <div className="text-center Box d-flex flex-column p-4 flex-justify-center mt-4">
                      <div>
                        <CreditCardIcon size={30} className="mb-2 " />{' '}
                      </div>
                      <p className="note">
                        Recibirás <strong>250€ </strong>cada vez que un usuario
                        que se inscriba a través de tu enlace, venda su primera
                        propiedad.
                      </p>
                    </div>
                  )}
                  {actorType === 'BUYER' && (
                    <>
                      <div className="">
                        <p className="text-bold">
                          ¿Te encargarás de cerrar esta venta?
                        </p>
                        <div className="radio-group">
                          <input
                            type="radio"
                            id="managed-by-agent"
                            className="radio-input"
                            name="isManaged"
                            onChange={() => setIsManaged(true)}
                            checked={isManaged}
                            required
                          />
                          <label
                            className="radio-label"
                            htmlFor="managed-by-agent"
                          >
                            Si
                          </label>
                          <input
                            type="radio"
                            id="managed-by-nexun"
                            className="radio-input"
                            name="isManaged"
                            onChange={() => setIsManaged(false)}
                            checked={!isManaged}
                            required
                          />
                          <label
                            className="radio-label"
                            htmlFor="managed-by-nexun"
                          >
                            No
                          </label>
                        </div>
                      </div>
                      <p className="note">
                        En caso de no ocuparse del cierre de la venta, nexun
                        asignará a otro agente disponible.
                      </p>
                      <div className="d-flex flex-items-center">
                        <PropertySelect
                          value={property}
                          onSelect={setProperty}
                          className="form-control width-full"
                          placeholder="Selecciona una propiedad"
                        />
                        <div className="ml-2">
                          <LanguageSelector
                            value={language}
                            onSelect={setLanguage}
                            disabled={loading}
                            className="form-control width-full"
                            placeholder="Idioma del comprador"
                          />
                        </div>
                      </div>
                      <Flatpickr
                        data-enable-time
                        options={{
                          dateFormat: 'F j,Y H:i',
                          minDate: 'today',
                          enableTime: true,
                          time_24hr: true,
                          locale: Spanish,
                          hourIncrement: 1,
                          minuteIncrement: 15,
                          defaultMinute: 0,
                          altFormat: 'F j,Y H:i',
                        }}
                        placeholder="Fecha y hora de la visita"
                        name="scheduledAt"
                        className="form-control width-full"
                        value={scheduledAt}
                        onChange={(date) => setScheduleAt(new Date(date))}
                      />
                      <div className="text-center Box d-flex flex-column p-4 flex-justify-center mt-4">
                        <div>
                          <CreditCardIcon size={30} className="mb-2 " />{' '}
                        </div>
                        <p className="note">
                          Obtendrás entre un <strong>20% y 50% </strong> de la
                          honorarios al cierre de la venta de esta propiedad.
                        </p>
                      </div>
                    </>
                  )}
                  {actorType === 'OWNER' && (
                    <div className="text-center Box d-flex flex-column p-4 flex-justify-center mt-4">
                      <div>
                        <CreditCardIcon size={30} className="mb-2 " />{' '}
                      </div>
                      <p className="note">
                        Obtendrás entre un <strong>25% y 40% </strong> de la
                        honorarios al cierre de la venta de esta propiedad.
                      </p>
                    </div>
                  )}
                  <Button
                    type="submit"
                    variant="primary"
                    size="large"
                    fontSize={6}
                    disabled={loading || !isEmailValid}
                  >
                    {loading || charging
                      ? 'Enviando invitación...'
                      : 'Crear invitación'}
                  </Button>
                  <div className="note">
                    Por seguridad, el usuario deberá restablecer su contraseña
                    por medio del enlace de Inicio de sesión - "Solicitar una
                    nueva". Recibirá un correo electrónico con instrucciones
                    para restablecer su contraseña y acceder a su nueva cuenta.
                  </div>
                </Box>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
