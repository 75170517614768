export default function PolicyComponent() {
  return (
    <div className="color-bg-default border rounded-2">
      <div className="container-lg p-responsive width-full">
        <div className="blankslate blankslate-large">
          <h2 className="h5-mktg lh-condensed mb-0 color-fg-subtle">Nexun</h2>
          <h3 className="blankslate-heading h3-mktg mb-0">
            Politicas de privacidad
          </h3>
          <h4 className="f4-mktg mt-4 color-fg-muted">
            Consulta nuestras politicas
          </h4>
        </div>
        <div className="markdown-body p-responsive py-2 pb-6">
          <p className="f5 mt-0 ">
            Declaración de Política de Privacidad para el sitio NEXUN, propiedad
            de REEDITOR S.L
          </p>
          <p className="f5  mt-0 ">
            En cumplimiento de lo establecido en la Ley Orgánica de Protección
            de Datos de Carácter Personal 15/1999, REEDITOR SL, con domicilio en
            Menendez Pidal 6, 28036, Madrid, y C.I.F. B86236403, como
            responsable del fichero, informa que los datos personales requeridos
            como consecuencia de la suscripción o alta en algunos de los
            servicios ofrecidos por el sitio web, serán objeto de tratamiento
            automatizado e incorporados a los correspondientes ficheros, con los
            niveles de seguridad necesarios.
          </p>
          <p className="f5  mt-0 ">
            NEXUN se compromete a cumplir el deber de secreto establecido en la
            ley. Asimismo, le informamos de la posibilidad de ejercer los
            derechos de acceso, rectificación, cancelación y oposición,
            dirigiéndose por a la casilla contact@reeditor.com.
          </p>
          <h3 className="blankslate-heading h4-mktgpt-5 text-center">
            Politicas de cookies
          </h3>
          <p className="f5  mt-0 ">
            Siguiendo las directrices de la Agencia Española de Protección de
            Datos procedemos a detallar el uso de cookies que hace esta web con
            el fin de informarle con la máxima exactitud posible.
          </p>
          <div>
            <strong className="f4-light ">Cookies técnicas</strong>
            <p className="f5  mt-0 ">
              Estas que permiten al usuario la navegación a través de nuestra
              plataforma y la utilización de las diferentes opciones o servicios
              que en ella existan como identificar la sesión y recordar los
              elementos durante la navegación o compartir contenidos a través de
              redes sociales.{' '}
            </p>
          </div>
          <div>
            <strong className="f4-light ">Cookies de personalización</strong>
            <p className="f5  mt-0 ">
              Estas permiten al usuario acceder al servicio con algunas
              características de carácter general predefinidas en función de una
              serie de criterios en el terminal del usuario como por ejemplo
              serian el idioma, el tipo de navegador a través del cual accede al
              servicio, la configuración regional desde donde accede al
              servicio, etc.
            </p>
          </div>
          <div>
            <strong className="f4-light ">Cookies de análisis</strong>
            <p className="f5  mt-0 ">
              Tratadas por nosotros o por terceros colaboradores (GOOGLE
              ANALYTICS, MASTERBASE), nos permiten cuantificar el número de
              usuarios y así realizar la medición y análisis estadístico de la
              utilización que hacen los usuarios del servicio ofertado. Para
              ello se analiza su navegación en nuestra página web con el fin de
              mejorar la oferta de productos o servicios que le ofrecemos.
            </p>
          </div>
          <div>
            <strong className="f4-light ">Cookies publicitarias</strong>
            <p className="f5  mt-0 ">
              A través de los hábitos de navegación nos permiten (por medio de
              terceros) gestionar de forma eficaz los espacios publicitarios que
              hay en la página web, adecuando el contenido del anuncio al uso
              que realice de nuestra página web.
            </p>
          </div>
          <div>
            <strong className="f4-light ">
              Cookies de publicidad comportamental
            </strong>
            <p className="f5  mt-0 ">
              Estas cookies almacenan información del comportamiento de los
              usuarios obtenida a través de la observación continuada de sus
              hábitos de navegación, lo que permite desarrollar un perfil
              específico para mostrar publicidad en función del mismo.
            </p>
          </div>
          <div className="blankslate blankslate-large">
            <h3 className="blankslate-heading h4-mktgmb-0">Importante</h3>
          </div>
          <p className="f5  mt-0 ">
            Al hacer uso de NEXUN, el usuario acepta expresamente el tratamiento
            de la información recabada en la forma y con los fines anteriormente
            mencionados. Y asimismo reconoce conocer la posibilidad de rechazar
            el tratamiento de tales datos o información rechazando el uso de
            Cookies mediante la selección de la configuración apropiada a tal
            fin en su navegador.
          </p>
          <p className="f5  mt-0 ">
            Puede usted permitir, bloquear o eliminar las cookies instaladas en
            su equipo mediante la configuración de las opciones del navegador
            instalado en su ordenador.
          </p>
          <p className="f5  mt-0 ">
            Si tiene dudas acerca de esta política de cookies, puede contactar
            con NEXUN en info@nexun.es
          </p>
        </div>
      </div>
    </div>
  );
}
