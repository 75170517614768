import { gql } from '@apollo/client';

export default gql`
  query Agents(
    $filter: FilterFindManyAgentInput
    $sort: SortFindManyAgentInput
  ) {
    agents(filter: $filter, sort: $sort) {
      avatarURL
      phone
      address {
        formattedAddress
      }
      birthDate
      nif
      country {
        name
        flag
      }
      _id
      phone
      firstName
      fullName
      isAvailableFulfilled
      isDocumentFulfilled
      isEmailVerificated
      isProfileFulfilled
      email
      hasAcceptedContract
      createdAt
    }
  }
`;
