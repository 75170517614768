import { useState } from 'react';
import { HomeFooter, NexunLogo } from 'components';
import {
  FormControl,
  TextInput,
  Box,
  Button,
  ThemeProvider,
} from '@primer/react';
import { Link, NavLink } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { RECOVER_PASSWORD_MUTATION } from 'apollo/mutations';
import { useToasts } from 'react-toast-notifications';

export default function RecoverPasswordRoute() {
  const { addToast } = useToasts();
  const [email, setEmail] = useState('');
  const [actorType, setActorType] = useState('AGENT');

  const [recoverPassword, { loading }] = useMutation(
    RECOVER_PASSWORD_MUTATION,
    {
      onCompleted: () => {
        addToast(
          'Se ha enviado un correo con instrucciones para cambiar su contraseña',
          {
            appearance: 'success',
            autoDismiss: true,
          },
        );
      },
      onError: (error) => {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      },
    },
  );

  const handleRecoverPasswordRequest = (event) => {
    event.preventDefault();
    recoverPassword({
      variables: {
        email,
        type: actorType,
      },
    });
  };

  return (
    <ThemeProvider>
      <div className="d-flex flex-column flex-1 color-bg-subtle">
        <div className="container-sm p-responsive width-full d-flex flex-column flex-items-center py-8">
          <div className="d-flex flex-column flex-items-center flex-justify-center text-center">
            <NavLink to="/" className="color-fg-default no-underline">
              <NexunLogo size="large" onlyIcon />
            </NavLink>
            <h1 className="h3 text-center mt-3">Restablecer contraseña</h1>
          </div>
          <div className="color-bg-default rounded-2 color-shadow-medium mt-4">
            <div className="p-4">
              <form onSubmit={handleRecoverPasswordRequest}>
                <Box display="grid" gridGap={3}>
                  <div>
                    <p className="text-bold">Plataforma</p>
                    <div className="radio-group">
                      <input
                        type="radio"
                        id="actor-type-agent"
                        className="radio-input"
                        name="actorType"
                        value="AGENT"
                        checked={actorType === 'AGENT'}
                        onChange={(e) => setActorType(e.target.value)}
                        required
                      />
                      <label className="radio-label" htmlFor="actor-type-agent">
                        Agentes
                      </label>
                      <input
                        type="radio"
                        id="actor-type-owner"
                        className="radio-input"
                        name="actorType"
                        value="OWNER"
                        checked={actorType === 'OWNER'}
                        onChange={(e) => setActorType(e.target.value)}
                        required
                        disabled
                      />
                      <label className="radio-label" htmlFor="actor-type-owner">
                        Propietarios
                      </label>
                      <input
                        type="radio"
                        id="actor-type-buyer"
                        className="radio-input"
                        name="actorType"
                        value="BUYER"
                        checked={actorType === 'BUYER'}
                        onChange={(e) => setActorType(e.target.value)}
                        required
                        disabled
                      />
                      <label className="radio-label" htmlFor="actor-type-buyer">
                        Compradores
                      </label>
                    </div>
                  </div>
                  <p className="mb-0 text-bold">
                    Introduzca la dirección de correo electrónico de su cuenta
                    de usuario y le enviaremos un enlace para restablecer la
                    contraseña.
                  </p>
                  <FormControl required>
                    <TextInput
                      type="email"
                      name="email"
                      placeholder="Correo electrónico"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      size="large"
                      autoFocus
                      contrast
                      block
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    variant="primary"
                    size="large"
                    fontSize={6}
                    block
                    disabled={email.trim().length === 0 || loading}
                  >
                    {loading
                      ? 'Enviando correo de restablecimiento…'
                      : 'Enviar correo de restablecimiento'}
                  </Button>
                </Box>
              </form>
              <p className="text-center mt-4">
                ¿No es lo que buscaba?{' '}
                <Link to="/login" className="Link">
                  Inicie sesión
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
        <HomeFooter />
      </div>
    </ThemeProvider>
  );
}
