import { HomeFooter, HomeHeader, ShapeDivider } from 'components';

export default function FeeRoute() {
  return (
    <div className="d-flex flex-column flex-1 color-bg-subtle">
      <div className="container-lg p-responsive width-full">
        <HomeHeader />
      </div>
      <ShapeDivider placement="bottom" />
      <div className="container p-responsive width-full text-center color-bg-default py-10">
        <h3 className="color-fg-subtle">Honorarios</h3>
        <h1>¿Cuánto dinero puedo recibir?</h1>
      </div>
      <ShapeDivider placement="top" />
      <div>
        <div className="color-fg-subtle text-center d-flex flex-column flex-justify-center flex-items-center pt-9 mb-5">
          <p className="col p-0 m-0">
            El proceso de compra venta está dividido en 3 acciones
          </p>
          <p className="col-6">
            Si participas en un proceso que finalice con un cierre exitoso,
            recibirás los honorarios según la acción que hayas realizado en ese
            proceso.
          </p>
        </div>
        <div className="d-flex flex-column flex-md-row col-12 flex-justify-center align-items-center pb-8">
          <div className="Box p-4 text-center col-12  col-md-3 col-xl-2 mx-md-2 mb-2">
            <h1 className="color-fg-subtle">Captador</h1>
            <p className="color-fg-subtle pb-5">
              Trae inmuebles a la plataforma y obtén honorarios una vez este
              haya sido vendido
            </p>
          </div>
          <div className="Box p-4 text-center col-12  col-md-3 col-xl-2 mx-md-2 mb-2">
            <h1 className="color-fg-subtle">Prescriptor</h1>
            <p className="color-fg-subtle pb-5">
              Si traes un comprador interesado, recibirás los honorarios si este
              acaba el proceso con éxito.
            </p>
          </div>
          <div className="Box p-4 text-center col-12  col-md-3 col-xl-2 mx-md-2 mb-2">
            <h1 className="color-fg-subtle">Vendedor</h1>
            <p className="color-fg-subtle pb-5">
              Recibe honorarios si la visita que has realizado acaba en una
              escritura de compra.
            </p>
          </div>
        </div>
      </div>
      <ShapeDivider placement="bottom" />
      <div className="container color-bg-default py-9 text-center d-flex flex-justify-center">
        <div className="col-10 color-fg-subtle d-flex flex-column flex-justify-center flex-items-center">
          <h1 className="pb-5">El 80% de la comisión de venta va para ti.</h1>
          <p className="col-8">
            {' '}
            Así es, el 80% de la comisión de venta conseguida será para el o los
            agentes que hayan participado en el exitoso proceso de venta.
          </p>
          <p className="col-8">
            Por su parte, nexun conservará el 20% restante de la comisión con
            objeto de ayudar a cubrir los costos de tecnología, desarrollo de
            las aplicaciones, marketing y procesamiento de pago paa los agentes.
          </p>
        </div>
      </div>
      <div className="container color-bg-default py-9 text-center d-flex flex-justify-center">
        <div className="col-10 color-fg-subtle d-flex flex-column flex-justify-center flex-items-center">
          <h1 className="pb-5">¿Cuánto es la comisión por cierre?</h1>
          <p className="col-8">
            Cuando una propiedad es cargada en nexun, su propietario ha aceptado
            pagar una comisión cuando el proceso de venta se ejecute con éxito.
            El importe de esta comisión corresponde a un procenaje del valor
            escriturado en la venta del inmueble, que está entre un 2% al 4%.
          </p>
        </div>
      </div>
      <ShapeDivider placement="top" />
      <div className="container color-bg-subtle py-9  d-flex flex-justify-center">
        <div className="col-10 color-fg-subtle d-flex flex-column flex-justify-center flex-items-center">
          <h1 className="pb-5">Los ejemplos siempre ayudan</h1>
          <p className="col-8 text-center">
            Consideramos la venta de una propiedad por valor de 450.000€ y que
            el porcentaje con el propietario fue del 3%. Con ello la comisión
            por cierre resulta ser de 13.500€.
          </p>
          <li className="pb-4">
            Nexun retiene 20% de la comisión, es decir 2.700€
          </li>
          <p className="col-8">
            Tres agentes han participado en este proceso, y entre ellos se
            reparte el 80% restante de la comisión.
          </p>

          <div className="d-flex flex-column flex-md-row col-12 flex-justify-center align-items-center py-8">
            <div className="Box p-4 text-center col-12  col-md-3 col-xl-2 mx-2 mb-2">
              <h1 className="color-fg-subtle">Captador</h1>
              <p className="color-fg-subtle pb-5 ">20% de la comisión</p>
              <h3>2.700€</h3>
            </div>
            <div className="Box p-4 text-center col-12  col-md-3 col-xl-2 mx-2 mb-2">
              <h1 className="color-fg-subtle">Prescriptor</h1>
              <p className="color-fg-subtle pb-5">40% de la comisión</p>
              <h3>5.400€</h3>
            </div>
            <div className="Box p-4 text-center col-12  col-md-3 col-xl-2 mx-2 mb-2">
              <h1 className="color-fg-subtle">Vendedor</h1>
              <p className="color-fg-subtle pb-5">20% de la comisión</p>
              <h3>2.700€</h3>
            </div>
          </div>
        </div>
      </div>
      <ShapeDivider placement="bottom" />
      <div className="container color-bg-default py-9 text-center d-flex flex-justify-center">
        <div className="col-10 color-fg-subtle d-flex flex-column flex-justify-center flex-items-center">
          <h1 className="pb-5">¿Cómo se calculan los porcentajes?</h1>
          <p className="col-8 pb-2">
            La tecnología que utiliza nexun, entre otras funciones, permite
            establecer las condiciones optimas para generar los incentivos que
            aceleren y optimicen el proceso de venta.
          </p>
          <p className="col-8 pb-2">
            Así, tanto el porcentaje de comisión por el servicio de venta que
            nexun hace a los propietarios como la distribución de la comisión
            entre “captador”, “prescriptor” y “vendedor” depende tanto de las
            condiciones de la propiedad como es su ubicación, estado,
            disponibilidad, etc, como también de factores del mercado
            inmobiliario como de la disponibilidad crediticia.
          </p>
          <p className="col-8">
            Al momento que una propiedad esté disponible para venta en nexun,
            los agentes registrado podrán saber de inmediato el porcentaje e
            importe a percibir al acabar con éxito el proceso de compra /venta.
          </p>
        </div>
      </div>
      <ShapeDivider placement="top" />
      <HomeFooter />
    </div>
  );
}
