import { useQuery } from '@apollo/client';
import { CheckIcon, XIcon } from '@primer/octicons-react';
import { COUNTRIES_QUERY } from 'apollo/queries';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import escapeRegex from 'utils/escapeRegex';
// import countries from 'utils/contries';

export default function CountrySelector({ value, onSelect, disabled }) {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 250);
  const [open, setOpen] = useState(false);
  const searchInput = useRef();

  const { data, loading } = useQuery(COUNTRIES_QUERY, {
    variables: {
      filter: {
        _operators:
          debouncedQuery.trim().length > 0
            ? {
                name: { regex: `/${escapeRegex(debouncedQuery)}/i` },
              }
            : undefined,
      },
    },
  });

  useEffect(() => {
    if (open && searchInput.current) {
      searchInput.current.focus();
    }
  }, [open]);

  return (
    <details open={open} className="details-reset details-overlay">
      <summary
        role="button"
        className="btn"
        aria-haspopup="menu"
        onClick={(event) => {
          event.preventDefault();
          setOpen((prevOpen) => !prevOpen);
        }}
      >
        <span className="mr-1">
          {value ? (
            <>
              <span className="mr-1">{value.flag}</span> {value.name}
            </>
          ) : (
            'Selecciona un país'
          )}
        </span>
        <span className="dropdown-caret" />
      </summary>
      <div className="SelectMenu SelectMenu--hasFilter" role="menu">
        <div className="SelectMenu-modal">
          <div className="SelectMenu-header">
            <span className="SelectMenu-title">Seleccionar país</span>
            <button
              className="SelectMenu-closeButton"
              type="button"
              aria-label="Close menu"
              onClick={() => setOpen(false)}
            >
              <XIcon />
            </button>
          </div>
          <div className="SelectMenu-filter">
            <input
              ref={searchInput}
              type="search"
              className="SelectMenu-input form-control"
              placeholder="Filtrar por nombre"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              disabled={disabled}
            />
          </div>
          <div className="SelectMenu-list">
            {loading && (
              <div className="SelectMenu-loading">
                <span>Loading</span>
                <span className="AnimatedEllipsis"></span>
              </div>
            )}
            {data?.countries.map((country) => (
              <button
                key={country._id}
                type="button"
                className="SelectMenu-item"
                role="menuitemradio"
                aria-checked={country._id === value?._id}
                onClick={() => {
                  onSelect(country);
                  setOpen(false);
                  setQuery('');
                }}
                disabled={disabled}
              >
                <CheckIcon className="SelectMenu-icon SelectMenu-icon--check mr-2" />
                <div className="lh-condensed css-truncate min-width-0">
                  <div className="css-truncate-overflow text-bold">
                    <span className="mr-1">{country.flag}</span> {country.name}
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
    </details>
  );
}

CountrySelector.propTypes = {
  value: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    flag: PropTypes.string.isRequired,
  }),
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
