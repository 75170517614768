import { gql } from '@apollo/client';

export default gql`
  mutation UpdatePropertyVisitById(
    $id: MongoID!
    $record: UpdateByIdPropertyVisitInput!
  ) {
    updatePropertyVisitById(_id: $id, record: $record) {
      record {
        property {
          owner {
            avatarURL
            email
            isEmailVerificated
            firstName
            lastName
            _id
            createdAt
            updatedAt
            resourcePath
            isViewer
          }
          title
          place {
            placeId
            formattedAddress
            geometry {
              location {
                lat
                lng
              }
              viewport {
                northeast {
                  lat
                  lng
                }
                southwest {
                  lat
                  lng
                }
              }
            }
            name
          }
          commission
          archiveIntern {
            url
            typology
            _id
          }
          address {
            visibility
            streetName
            streetNumber
            block
            floor
            stair
            door
            urbanization
            postalCode
            town
            nsiCode
            country
            coordinatesPrecision
            coordinatesLatitude
            coordinatesLongitude
          }
          contact {
            name
            email
            primaryPhonePrefix
            primaryPhoneNumber
            secondaryPhonePrefix
            secondaryPhoneNumber
          }
          descriptions {
            language
            text
          }
          features {
            access24h
            accessControl
            allowPets
            areaBuildable
            areaConstructed
            areaHeight
            areaPlot
            areaTradableMinimum
            areaUsable
            bathroomAdapted
            bathroomInside
            bathroomNumber
            bathroomType
            bedroomNumber
            buildingAdapted
            builtYear
            chimney
            classificationBlocks
            classificationChalet
            classificationCommercial
            classificationHotel
            classificationIndustrial
            classificationOffice
            classificationOther
            classificationPublic
            conditionedAir
            conditionedAirType
            conservation
            doorman
            duplex
            emergencyExit
            emergencyLights
            energyCertificateLaw
            energyCertificatePerformance
            energyCertificateRating
            energyCertificateType
            energyCertificateEmissionsRating
            energyCertificateEmissionsValue
            equippedKitchen
            extinguishers
            facadeArea
            fireDetectors
            fireDoors
            floorsBuildable
            floorsBuilding
            floorsInTop
            floorsProperty
            garden
            handicapAdaptedAccess
            handicapAdaptedUse
            heating
            heatingType
            hotWater
            lastActivity
            liftAvailable
            liftNumber
            loadingDock
            bridgeCrane
            locatedAtCorner
            nearestLocationKm
            officeBuilding
            orientationEast
            orientationNorth
            orientationSouth
            orientationWest
            parkingAvailable
            parkingAutomaticDoor
            parkingPlaceCovered
            parkingType
            garageCapacityType
            parkingSpacesNumber
            penthouse
            pool
            builtProperties
            rooms
            roomsSplitted
            security24h
            securityAlarm
            securityDoor
            securityPersonnel
            securitySystem
            smokeExtraction
            sprinklers
            storage
            studio
            suspendedCeiling
            suspendedFloor
            propertyTenants
            terrace
            ubication
            utilitiesElectricity
            utilitiesNaturalGas
            utilitiesRoadAccess
            utilitiesSewerage
            utilitiesSidewalk
            utilitiesStreetLighting
            utilitiesWater
            wardrobes
            balcony
            windowsDouble
            windowsLocation
            windowsShop
            newDevelopmentType
            builtPhase
            availabilityHour
            newDevelopmentName
            finished
            startDate
            keyDeliveryYear
            keyDeliveryMonth
            onSite
            mortgageStateSubsidized
            mortgageBankName
            mortgagePercentage
            mortgageEntryPercentage
            mortgageLettersPercentage
            mortgageInterestRate
            mortgageYears
            windowsNumber
            accessType
            tenantNumber
            smokingAllowed
            minTenantAge
            maxTenantAge
            couplesAllowed
            bedType
            minimalStay
            windowView
            ownerLiving
            availableFrom
            tenantGender
            internetAvailable
            houseKeeper
            cupboard
            privateBathroom
            genderPreference
            occupation
            lgtbFriendly
            childrenAllowed
            occupiedNow
            tenantWorkers
            tenantStudents
            minNewTenantAge
            maxNewTenantAge
            exteriorAccessibility
            interiorAccessibility
            lifeStyle
            priceReferenceIndex
            auction
            minAuctionBidIncrement
            auctionDeposit
            auctionDate
            auctionTribunal
            currentOccupation
            gardenType
            parkingSpaceCapacity
            parkingSpaceArea
            outdoorParkingSpace
            outdoorParkingSpaceType
            outdoorParkingSpaceNumber
            hiddenPrice
            documents {
              type
              url
            }
            recommendedForChildren
            tenantNumberHousing
          }
          images {
            url
            isMain
            label
            order
          }
          operation {
            depositMonths
            price
            priceCommunity
            priceToOwn
            priceTransfer
            priceParking
            type
          }
          videos {
            url
            order
          }
          visibility
          _id
          createdAt
          updatedAt
        }
        agent {
          avatarURL
          firstName
          lastName
          email
          isEmailVerificated
          languages {
            name
            code
            nativeName
            flag
            _id
            createdAt
            updatedAt
          }
          availableDateRanges {
            dayOfWeek
            hourRange {
              start
              end
            }
          }
          activityLocation {
            kmRange
          }
          experienceYears
          nif
          birthDate
          country {
            name
            code
            numberCode
            flag
            _id
            createdAt
            updatedAt
          }
          address {
            placeId
            formattedAddress
            name
          }
          activity
          companyNif
          phone
          hasAcceptedContract
          contractSignedAt
          nifFiles
          criminalRecordFiles
          bankOwnershipFiles
          iban
          referredBy {
            avatarURL
            firstName
            lastName
            email
            isEmailVerificated
            experienceYears
            nif
            birthDate
            activity
            companyNif
            phone
            hasAcceptedContract
            contractSignedAt
            nifFiles
            criminalRecordFiles
            bankOwnershipFiles
            iban
            _id
            createdAt
            updatedAt
            fullName
            resourcePath
            isProfileFulfilled
            isAvailableFulfilled
            isDocumentFulfilled
            isViewer
          }
          _id
          createdAt
          updatedAt
          fullName
          resourcePath
          isProfileFulfilled
          isAvailableFulfilled
          isDocumentFulfilled
          isViewer
        }
        buyer {
          avatarURL
          email
          isEmailVerificated
          firstName
          lastName
          phone
          mobilePhone
          dni
          birthDate
          address
          language {
            name
            code
            nativeName
            flag
            _id
            createdAt
            updatedAt
          }
          _id
          createdAt
          updatedAt
          isPasswordSet
          resourcePath
          isViewer
        }
        date
        isConfirmedByBuyer
        confirmedByBuyerAt
        isRefusedByBuyer
        refusedByBuyerAt
        isConfirmedByAgent
        confirmedByAgentAt
        isRefusedByAgent
        refusedByAgentAt
        _id
        createdAt
        updatedAt
      }
    }
  }
`;
